import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, Divider, Stack, TextField, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { Marketplace } from '../../@types/api';
import { CURRENCY_OPTIONS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import useMutateData from '../../hooks/useMutateData';
import { MarketplaceConfigFormData, marketplaceConfigSchema } from '../../schema';
import FormFieldStack from '../atoms/FormFieldStack';
import Switch from '../atoms/Switch';
import FormSelect from '../molecules/FormSelect';

const styles: StyleObj = {
  content: {
    px: 0,
  },
  actions: {
    '&.MuiDialogActions-root.MuiDialogActions-root': {
      px: 0,
      pb: 0,
    },
  },
};

const MODEL_OPTIONS = [
  { id: 'AGENT_SYSTEM', name: 'Agent System' },
  { id: 'USER_REGISTRATION', name: 'User Registration' },
];

const LANGUAGE_OPTIONS = [
  { id: 'en', name: 'English' },
  { id: 'sq', name: 'Albanian' },
  { id: 'sr', name: 'Serbian' },
];

const DEFAULT_FORM_DATA: MarketplaceConfigFormData = {
  model: 'AGENT_SYSTEM',
  products: {
    sportsbook: false,
    casino: false,
  },
  currency: 'EUR',
  language: 'en',
  licenseInformation: null,
};

const MarketplaceConfigTab = () => {
  const { item, closeModal } = useModal<Marketplace>();
  const { updateData } = useMutateData('bo/marketplace', [QUERY_KEYS.marketplace], 'crm');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MarketplaceConfigFormData>({
    resolver: zodResolver(marketplaceConfigSchema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    if (item?.marketplaceConfig) {
      reset(item.marketplaceConfig);
    }
  }, [item, reset]);

  const handleClose = () => {
    reset(item?.marketplaceConfig || DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: MarketplaceConfigFormData) => {
    if (item) {
      updateData(item.id, { marketplaceConfig: data }, handleClose);
    }
  };

  return (
    <Fragment>
      <DialogContent sx={styles.content}>
        <Stack spacing={2}>
          <FormSelect
            label='Model'
            name='model'
            control={control}
            error={errors.model}
            options={MODEL_OPTIONS}
            required
          />
          <FormSelect
            label='Currency'
            name='currency'
            control={control}
            error={errors.currency}
            options={CURRENCY_OPTIONS}
            required
          />
          <FormSelect
            label='Language'
            name='language'
            control={control}
            error={errors.language}
            options={LANGUAGE_OPTIONS}
            required
          />
          <TextField
            error={!!errors.licenseInformation}
            helperText={errors.licenseInformation?.message}
            label='License Information'
            {...register('licenseInformation')}
            fullWidth
          />
          <Divider />
          <Typography variant='subtitle1'>Products</Typography>
          <FormFieldStack label='Sportsbook'>
            <Controller
              name='products.sportsbook'
              control={control}
              render={({ field }) => <Switch {...field} ref={null} />}
            />
          </FormFieldStack>
          <FormFieldStack label='Casino'>
            <Controller
              name='products.casino'
              control={control}
              render={({ field }) => <Switch {...field} ref={null} />}
            />
          </FormFieldStack>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default MarketplaceConfigTab;
