import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { DialogType, StyleObj } from '../../../@types';
import { PublishStatus } from '../../../@types/api';
import {
  CHANGED_STATUS_OPTIONS,
  EVENT_SOURCE_OPTIONS,
  HAS_STARTED_OPTIONS,
  IS_LIVE_OPTIONS,
  PUBLISH_STATUS_BASE_OPTIONS,
  QUERY_KEYS,
  SWITCH_CONTROL_OPTIONS,
} from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  fromTimestamp: dayjs().valueOf(),
  toTimestamp: dayjs().add(14, 'day').valueOf(),
  sportIds: [],
  competitionIds: [],
  tournamentIds: [],
  isStarted: undefined,
  isLive: 0,
  changed: undefined,
  publishStatuses: [],
  source: null,
  manualControl: undefined,
};

export type FilterEventsData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  sportIds: string[];
  competitionIds: string[];
  tournamentIds: string[];
  isStarted?: boolean;
  isLive?: number;
  changed?: boolean;
  publishStatuses: PublishStatus[];
  source: 'manual' | 'odds_matrix_feed' | null;
  manualControl?: boolean;
};

const styles: StyleObj = {
  container: { display: 'flex', flexDirection: 'column', gap: 2 },
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterEvents = ({ changeQuery, closeModal }: Props) => {
  const { control, handleSubmit, handleResetFilter, watch, setValue } = useFilterForm<FilterEventsData>({
    defaultData: defaultValues,
    changeQuery,
    prepareQueryParams: (data) => prepareData(data),
  });

  const sportIds = watch('sportIds');
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');
  const fromTimestamp = watch('fromTimestamp');
  const toTimestamp = watch('toTimestamp');
  const eventsStatus = watch('isLive');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportIds') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
      if (name === 'competitionIds') {
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds, tournamentIds]);

  const prepareData = (data: Partial<FilterEventsData>) => {
    const preparedData = { ...data };

    if (!preparedData.isLive) {
      if (data.fromTimestamp) {
        preparedData.fromTimestamp = dayjs(data.fromTimestamp).unix() * 1000;
      }
      if (data.toTimestamp) {
        preparedData.toTimestamp = dayjs(data.toTimestamp).endOf('day').unix() * 1000;
      }
    } else {
      preparedData.fromTimestamp = undefined;
      preparedData.toTimestamp = undefined;
    }

    if (data.isStarted !== undefined) {
      preparedData.isStarted = Boolean(data.isStarted);
    }
    if (data.changed !== undefined) {
      preparedData.changed = Boolean(data.changed);
    }
    if (data.manualControl !== undefined) {
      preparedData.manualControl = Boolean(data.manualControl);
    }

    return preparedData;
  };

  return (
    <FilterDialogLayout label='events' onClose={() => closeModal?.()} onSave={handleSubmit} onReset={handleResetFilter}>
      <FormSelect control={control} name='isLive' label='Event status' options={IS_LIVE_OPTIONS} />
      {!eventsStatus && (
        <Box sx={styles.container}>
          <DateTimeSelect control={control} name='fromTimestamp' label='From' maxDateTime={dayjs(toTimestamp)} />
          <DateTimeSelect control={control} name='toTimestamp' label='To' minDateTime={dayjs(fromTimestamp)} />
        </Box>
      )}
      <SportSelect label='Sports' control={control} name='sportIds' multiple closeMenuOnSelect />
      <FormAutocomplete
        name='competitionIds'
        control={control}
        label='Competitions'
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportIds, competitionIds]}
        queryParams={{
          sportIds,
          isActive: true,
          competitionIds,
        }}
        disabled={isEmpty(sportIds)}
        hookEnabled={!!sportIds}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
        multiple
      />
      <TournamentSelect
        label='Tournaments'
        control={control}
        name='tournamentIds'
        competitionIds={competitionIds}
        multiple
        closeMenuOnSelect
      />
      <FormSelect control={control} name='isStarted' label='Time status' options={HAS_STARTED_OPTIONS} />
      <FormSelect
        control={control}
        name='publishStatuses'
        label='Publish status'
        options={PUBLISH_STATUS_BASE_OPTIONS}
        multiple
        closeOnSelect
      />
      <FormSelect control={control} name='changed' label='Changed' options={CHANGED_STATUS_OPTIONS} />
      <FormSelect control={control} name='source' label='Source' options={EVENT_SOURCE_OPTIONS} />
      <FormSelect control={control} name='manualControl' label='Mode' options={SWITCH_CONTROL_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterEvents;
