import Box from '@mui/material/Box';
import { PropsWithChildren, useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import usePlayTicketAuthSound from '../../hooks/usePlayTicketAuthSound';
import Breadcrumbs from '../molecules/Breadcrumbs';
import DrawerNavigation from '../molecules/DrawerNavigation';
import Header from '../organisms/Header';
import { BACKOFFICE_WS_ROOM_ID } from '../../constants';
import useWebsocket from '../../hooks/useWebsocket';
import { BettingSlipAcceptStatus } from '../../@types/api';
import usePermissions from '../../hooks/usePermissions';
import { ModalProvider } from '../../contexts/ModalContext';
import { USER_ROLES } from '../../constants/keycloak';
import { getItemFromSessionStorage } from '../../utils/storageHelper';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import theme from '../../styles/theme';

const styles: StyleObj = {
  container: { display: 'flex' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    height: '100vh',
    flexGrow: 1,
    overflowX: 'auto',
    px: 4,
    pt: 11,
    pb: 4,
    [theme.breakpoints.down('sm')]: {
      pt: 8,
      px: 0,
      pb: 0,
      gap: 1.5,
    },
  },
};

export type TicketAuthorizationMessage = {
  acceptStatus: BettingSlipAcceptStatus;
  reoffered: boolean;
};

const MainLayout = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);

  const { ticketAuthorizationCallback } = usePlayTicketAuthSound();
  const { userId, marketplaceId } = useAuth();
  const { hasPermission, role } = usePermissions();
  const isSuperAdmin = role === USER_ROLES.SUPER_ADMIN;
  const marketplaceFromStorage = getItemFromSessionStorage('marketplace');

  const wsRoomId = isSuperAdmin
    ? `${BACKOFFICE_WS_ROOM_ID}_${marketplaceFromStorage?.id}`
    : `${BACKOFFICE_WS_ROOM_ID}_${marketplaceId}`;

  const { joinRoom, isConnected } = useWebsocket<TicketAuthorizationMessage>({
    debug: true,
    skipCache: true,
    callback: ticketAuthorizationCallback,
  });

  useEffect(() => {
    if (isConnected && hasPermission('accessTicketAuthorization')) {
      joinRoom(wsRoomId);
    }
  }, [isConnected, hasPermission, joinRoom, userId, wsRoomId]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={styles.container}>
      <ModalProvider>
        <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      </ModalProvider>
      <DrawerNavigation open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
      <Box component='main' sx={styles.main}>
        <Breadcrumbs />
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
