import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import type { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import { StyleObj } from '../../../@types';
import { CRMUserData, Partner } from '../../../@types/api';
import { PARTNER_TAB_CONFIG } from '../../../config/crm';
import { QUERY_KEYS } from '../../../constants';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { useModal } from '../../../contexts/ModalContext';
import { defaultColumnsPartners } from '../../../helpers/table';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { createColumn } from '../../../utils';
import Switch from '../../atoms/Switch';
import WalletIcon from '../../icons/WalletIcon';
import FormModal from '../../modals/FormModal';
import CRMDetailsDrawer from '../../organisms/crm/CRMDetailsDrawer';
import TableTemplate from '../../templates/TableTemplate';
import { USER_ROLES } from '../../../constants/keycloak';

const styles: StyleObj = {
  table: {
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
};

dayjs.extend(relativeTime);

const PartnersPage = () => {
  const {
    data: partnersData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Partner>('agent/search', { page: 1, limit: 25 }, undefined, 'crm');
  const { updateData } = useMutateData('agent', [QUERY_KEYS.crmUsers], 'crm');
  const { handleSort } = useSort(changeQuery, 'crm');
  const invalidateData = useInvalidateQuery();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const openEditDialog = (row: Partner) => {
    openModal(<FormModal form='partners' />, row);
  };

  const updateAgentStatus = (e: React.ChangeEvent<HTMLInputElement>, agentId: string) => {
    updateData(
      agentId,
      { isActive: e.target.checked },
      () => {
        invalidateData(['agent/search']);
      },
      'Status updated successfully'
    );
  };

  const columns: GridColDef[] = [
    createColumn('fullName', 'Full Name', {
      alwaysVisible: true,
      minWidth: 150,
    }),
    createColumn('email', 'Email', {
      flex: 1.5,
      minWidth: 150,
    }),
    createColumn('createdAt', 'Date Created', {
      valueGetter: (params: GridValueGetterParams<Partner>) => dayjs(params.row.createdAt).format('DD.MM.YYYY'),
      flex: 0.75,
    }),
    createColumn('parent', 'Parent', {
      sortable: false,
      valueGetter: (params: GridValueGetterParams<Partner>) => params.row.teamLead?.fullName,
    }),
    createColumn('role', 'Role', {
      valueGetter: (params: GridValueGetterParams<Partner>) => params.row.role.name,
      flex: 0.75,
      sortable: false, // Remove once BE work is done
    }),
    createColumn('isActive', 'Status', {
      alwaysVisible: true,
      renderCell: (params: GridCellParams<Partner>) => (
        <Switch value={params.row.isActive} onChange={(e) => updateAgentStatus(e, params.row.id)} name='status' />
      ),
      flex: 0.5,
    }),
    createColumn('edit', 'Actions', {
      sortable: false,
      disableExport: true,
      renderCell: (params: GridCellParams<Partner>) => {
        const partnerRole = params.row.role.name;
        const hideWalletIcon =
          partnerRole === USER_ROLES.TECH_SUPPORT || partnerRole === USER_ROLES.TRADER || !params.row.userId;

        return (
          <>
            <IconButton onClick={() => openEditDialog(params.row)} color='primary'>
              <EditOutlined />
            </IconButton>
            {!hideWalletIcon && (
              <IconButton onClick={() => navigate(`../partners/${params.row.userId}/wallet`)}>
                <WalletIcon />
              </IconButton>
            )}
          </>
        );
      },
      flex: 0.75,
    }),
  ];

  const { setSelectedCRMUser } = useCRMUser();

  const handleCellClick = (params: GridCellParams<Partner>) => {
    if (params.field !== 'edit' && params.field !== 'isActive') {
      setSelectedCRMUser(params.row as CRMUserData);
    }
  };

  return (
    <>
      <TableTemplate
        rows={partnersData?.items || []}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={partnersData?.count || 0}
        createItemBtnLabel='Add new partner'
        defaultVisibleColumns={defaultColumnsPartners}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        onCellClick={handleCellClick}
        formType='partners'
        sx={styles.table}
        changeQuery={changeQuery}
        showExport
      />
      <CRMDetailsDrawer config={PARTNER_TAB_CONFIG} />
    </>
  );
};

export default PartnersPage;
