import type {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import { startCase, upperCase, upperFirst } from 'lodash-es';
import type { BettingSlipWithMonitoringInfo, WalletType } from '../../@types/api';

import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import FullCashOut from '../../assets/icons/cashout/fullCashOut.svg';
import PartialCashOut from '../../assets/icons/cashout/partialCashOut.svg';
import { QUERY_KEYS, TICKET_MONITORING_REFETCH_INTERVAL_OPTIONS, WALLET_TYPE_OPTIONS } from '../../constants';
import { MESSAGES } from '../../constants/messages';
import { COLUMN_FIELDS } from '../../constants/table';
import { useModal } from '../../contexts/ModalContext';
import { createNumberColumn, getConfirmMessage, getCurrentAction } from '../../helpers/columns';
import { defaultColumnsBettingSlips } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import usePermissions from '../../hooks/usePermissions';
import useSort from '../../hooks/useSort';
import { createColumn, getBettingSlipAcceptStatusColor, getBettingSlipStatusColor } from '../../utils';
import BettingSlipAnalyticsItem from '../atoms/BettingInfoDisplay';
import SelectRefreshInterval from '../atoms/SelectRefreshInterval';
import Switch from '../atoms/Switch';
import { BettingSlipFromIcon, DLetterIcon, MLetterIcon, ResetIcon } from '../icons';
import ConfirmationModal from '../modals/ConfirmationModal';
import TicketDetailsModal from '../modals/TicketDetailsModal';
import DateRangeSelect, { DayjsTimestamp } from '../molecules/DateRangeSelect';
import TableTemplate from '../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 150px)',
  },
  table: {
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
  ipSourceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 0.5,
  },
  ip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  refetchIntervalWrapper: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 2,
    mb: 1,
  },
  walletTypeSelect: (theme) => ({
    width: 100,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  analyticsWrapper: (theme) => ({
    width: '100%',
    mt: '20px',
    display: 'flex',
    position: 'inherit',
    bottom: 30,
    flexWrap: 'wrap',
    gap: 2,
    '& > *': {
      minWidth: 260,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      mt: '40px',
      pb: '16px',
      px: 2,
      '& > *': {
        minWidth: '100%',
      },
    },
  }),
  datePickersWrapper: {
    display: 'flex',
    gap: 2,
  },
};

const defaultDateValues = {
  fromTimestamp: dayjs().startOf('day'),
  toTimestamp: dayjs().endOf('day'),
};

const CASH_OUT_TYPE_MAP = {
  full: <img src={FullCashOut} alt='Full cash out' />,
  partial: <img src={PartialCashOut} alt='Partial cash out' />,
};

const TicketMonitoringPage = () => {
  const [date, setDate] = useState<{ fromTimestamp: Dayjs; toTimestamp: Dayjs }>({
    fromTimestamp: defaultDateValues.fromTimestamp,
    toTimestamp: defaultDateValues.toTimestamp,
  });
  const [walletType, setWalletType] = useState<'main' | 'bonus'>('main');
  const [refetchInterval, setRefetchInterval] = useState<number | false>(0);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const { hasPermission } = usePermissions();
  const hasAdminCredentials = hasPermission('defaultAdminRole');
  const hasCashoutPermission = hasPermission('manageCashOut');

  const cashoutColumns = hasCashoutPermission
    ? [
        createNumberColumn('cashoutAmount', 'Current Cash Out', { sortable: false, hideZeros: true }),
        createColumn('cashoutType', 'Cash Out', {
          renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
            const cashoutType = params.row.cashoutType;
            return CASH_OUT_TYPE_MAP[cashoutType] ?? '';
          },
          sortable: false,
          flex: 0.75,
        }),
      ]
    : [];

  const { openModal } = useModal();

  const {
    data: bettingSlipsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
    refetch,
  } = usePagination<BettingSlipWithMonitoringInfo>(
    'betting-slips',
    {
      page: 1,
      limit: 25,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
      walletType,
    },
    {
      refetchInterval,
    }
  );

  const bettingSlipsAnalytics = bettingSlipsData?.analytics;

  const { updateData } = useMutateData('/betting-slips', [QUERY_KEYS.bettingSlips]);

  const { handleSort } = useSort(changeQuery);

  const openConfirmationModal = (id: string) => {
    openModal(
      <ConfirmationModal
        title={MESSAGES.CONFIRM_CANCEL_TICKET}
        actionButtonLabel='Yes'
        handleConfirm={() => updateData(`${id}/accept-status`, { accept: false, admin: true })}
        actionButtonClose='No'
      />
    );
  };

  const handleWalletTypeChange = (e: SelectChangeEvent<WalletType>) => {
    setWalletType(e.target.value as WalletType);
    changeQuery({ walletType: e.target.value });
  };

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);

    changeQuery({
      fromTimestamp: newDate.fromTimestamp.valueOf(),
      toTimestamp: newDate.toTimestamp.valueOf(),
    });
  };

  const onSort = (model: GridSortModel) => {
    if (model[0]?.field === 'canceled') {
      // we need to sort by acceptStatus field instead of canceled
      // because canceled field is not present in the response
      return handleSort([{ field: 'acceptStatus', sort: model[0]?.sort }]);
    }
    return handleSort(model);
  };

  const columns: GridColDef[] = [
    createColumn('ticketId', 'ID', {
      sortable: false,
    }),
    createColumn('from', 'From', {
      sortable: false,
      alwaysVisible: true,
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return <BettingSlipFromIcon from={params.row.from} />;
      },
    }),
    createColumn('user', 'Player', {
      sortable: false,
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.user?.fullName,
    }),
    createColumn('username', 'User Name', {
      sortable: false,
      alwaysVisible: true,
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.user?.username,
    }),
    createColumn('operator', 'Betting Operator', {
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.operator?.fullName,
    }),
    createColumn('preMatchRiskFactor', 'RF Pre-Match', {
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.user.preMatchRiskFactor,
    }),
    createColumn('inPlayRiskFactor', 'RF In-Play', {
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.user.inPlayRiskFactor,
    }),
    createColumn('createdAt', 'Created At', {
      alwaysVisible: true,
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
        return dayjs(params.row.createdAt).format('DD-MM-YYYY HH:mm');
      },
    }),
    createColumn('resolvedAt', 'Paid Out At', {
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
        if (!params.row.resolvedAt || params.row.status !== 'won') {
          return '';
        }
        return dayjs(params.row.resolvedAt).format('DD-MM-YYYY HH:mm');
      },
    }),
    createNumberColumn('totalStakeAmount', 'Stake', { alwaysVisible: true }),
    createNumberColumn('totalOdds', 'Total Odds', { hideZeros: true, sortable: false }),
    createColumn('possibleWinnings', 'Possible Winning'),

    createNumberColumn('winnings', 'Winning', { alwaysVisible: true, hideZeros: true }),
    createColumn('combinationsCount', 'Number of Combinations', { minWidth: 125 }),
    createColumn('canceled', 'Cancelled', {
      valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
        return params.row.acceptStatus === 'admin_cancelled' ? 'Canceled' : '';
      },
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return params.row.acceptStatus === 'admin_cancelled' ? <CheckCircle color={'error'} fontSize='small' /> : '';
      },
    }),
    createColumn('reoffered', 'Reoffered', {
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return params.row.reoffered ? <CheckCircle color={'success'} fontSize='small' /> : '';
      },
    }),
    createColumn('acceptStatus', 'Status', {
      alwaysVisible: true,
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return (
          <Typography variant='body3' color={getBettingSlipAcceptStatusColor(params.row.acceptStatus)}>
            {params.value?.includes('_') ? startCase(params.value) : upperFirst(params.value)}
          </Typography>
        );
      },
    }),
    createColumn('status', 'Resolution Status', {
      alwaysVisible: true,
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return (
          <Typography variant='h5' color={getBettingSlipStatusColor(params.row.status)}>
            {upperCase(params.value)}
          </Typography>
        );
      },
    }),

    createColumn('ipAddress', 'Source/IP', {
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        return (
          <Box title={params.row.ipAddress} sx={styles.ipSourceWrapper}>
            <Stack sx={{ minWidth: 16 }}>{params.row.device === 'desktop' ? <DLetterIcon /> : <MLetterIcon />}</Stack>
            <Typography variant='body3' sx={styles.ip}>
              {params.row.ipAddress}
            </Typography>
          </Box>
        );
      },
    }),
    ...cashoutColumns,
    createColumn('actions', 'Actions', {
      sortable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
        const { status, acceptStatus, from, createdAt, partialCashoutSlipId } = params.row;
        const canCancelTicket =
          hasAdminCredentials &&
          status === 'open' &&
          acceptStatus === 'accepted' &&
          from === 'Pre-match' &&
          !partialCashoutSlipId &&
          dayjs().diff(createdAt, 'minutes') <= 10;

        const canCashout = hasCashoutPermission && !partialCashoutSlipId && status !== 'cashout';

        return (
          <>
            <Switch
              value={params.row.eligibleForCashout}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateData(params.row.id, { [COLUMN_FIELDS.CASHOUT]: !e.target.checked });
              }}
              name={COLUMN_FIELDS.CASHOUT}
              title='Cash Out'
              handleConfirmationMessage={() =>
                getConfirmMessage(getCurrentAction(!params.row.eligibleForCashout), 'ticket', params.row.ticketId)
              }
              disabled={!canCashout}
            />
            <Tooltip title='Cancel Ticket'>
              <IconButton
                disabled={!canCancelTicket}
                color='error'
                onClick={() => openConfirmationModal(params.row.id)}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    }),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleRefetchIntervalChange = (e: SelectChangeEvent<number | false>) => {
    setRefetchInterval(e.target.value as number | false);
  };

  const handleRefetch = () => {
    refetch();
  };

  const onCellClick = (params: GridCellParams) => {
    if (params.field === 'actions') return;

    setSelectedRow(params.row.id);
  };

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={bettingSlipsData?.items || []}
        columns={columns}
        rowCount={bettingSlipsData?.count ?? 0}
        loading={isFetching || isLoading}
        defaultVisibleColumns={defaultColumnsBettingSlips}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={onSort}
        changeQuery={changeQuery}
        onCellClick={onCellClick}
        sx={styles.table}
        customTableHeaderContent={
          <>
            <FormControl sx={styles.walletTypeSelect}>
              <InputLabel id='wallet-type-select-label'>Wallet Type</InputLabel>
              <Select
                label='Wallet Type'
                value={walletType}
                onChange={handleWalletTypeChange}
                sx={styles.walletTypeSelect}
              >
                {WALLET_TYPE_OPTIONS(hasAdminCredentials).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DateRangeSelect
              date={{ fromTimestamp: date.fromTimestamp, toTimestamp: date.toTimestamp }}
              onDateChange={handleDateChange}
            />
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'no-wrap',
                alignItems: 'center',
                columnGap: 1,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              })}
            >
              <SelectRefreshInterval
                handleRefetchIntervalChange={handleRefetchIntervalChange}
                options={TICKET_MONITORING_REFETCH_INTERVAL_OPTIONS}
                value={refetchInterval}
              />
              <Tooltip title='Refresh'>
                <IconButton onClick={handleRefetch}>
                  <ResetIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        }
        showExport
      />
      {bettingSlipsAnalytics && (
        <Box sx={styles.analyticsWrapper}>
          <BettingSlipAnalyticsItem
            label='Stake'
            value={bettingSlipsAnalytics.totalStakeAmount}
            backgroundColor='background.lightBlue'
            borderColor='selected.blue'
          />
          <BettingSlipAnalyticsItem
            label='Winnings'
            value={bettingSlipsAnalytics.winnings}
            backgroundColor='#FFF7EB'
            borderColor='#F7B64E'
          />
          <BettingSlipAnalyticsItem
            label='Profit'
            value={bettingSlipsAnalytics.profit}
            backgroundColor='#EFFFFA'
            borderColor='#55CDA9'
          />
        </Box>
      )}
      {selectedRow && <TicketDetailsModal id={selectedRow} onClose={() => setSelectedRow(null)} />}
    </Box>
  );
};

export default TicketMonitoringPage;
