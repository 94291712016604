import { CircularProgress, Stack, StackProps, CircularProgressProps } from '@mui/material';

type CenteredSpinnerProps = Omit<StackProps, 'children'> & {
  size?: number;
  color?: CircularProgressProps['color'];
};

const CenteredSpinner = ({ size, color, ...stackProps }: CenteredSpinnerProps) => {
  return (
    <Stack sx={{ height: '100%' }} justifyContent='center' alignItems='center' {...stackProps}>
      <CircularProgress size={size} color={color} />
    </Stack>
  );
};

export default CenteredSpinner;
