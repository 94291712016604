import { Box, IconButton, TextField, Theme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TMessage } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { getData, postData } from '../../utils/api';
import dayjs from 'dayjs';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import Message from '../molecules/Message';
import MessagesProfileBadge from '../atoms/MessagesProfileBadge';
import theme from '../../styles/theme';

const styles = {
  container: {
    width: '40%',
  },
  header: {
    backgroundColor: 'primary.main',
    padding: '16px 24px',
    marginTop: '10px',
    fontWeight: 700,
    borderTop: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    gap: '0 40px',
    alignItems: 'center',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
  },
  back: {
    display: 'flex',
    gap: '0 8px',
    cursor: 'pointer',
    padding: 0,
    color: '#fff',
  },
  backText: {
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  topic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  messageInfo: {
    padding: '16px 28px',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'neutral.800',
  },
  topicText: {
    color: 'grey.800',
    fontWeight: 600,
  },
  messages: {
    backgroundColor: 'neutral.800',
    padding: '16px 28px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px 0',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  input: {
    border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: 'neutral.800',
    marginTop: '8px',
    '& .MuiInputBase-root': {
      padding: '10px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  },
  send: {
    color: 'info.600',
    cursor: 'pointer',
  },
  headerText: {
    fontWeight: 600,
  },
};

type Props = {
  parentId: string;
  sentTab?: boolean;
  closeThread: () => void;
};

type ReplyMessage = {
  parentId: string;
  text: string;
};

const MessagesThread = ({ parentId, sentTab, closeThread }: Props) => {
  const { data: messages } = useQuery({
    queryKey: [QUERY_KEYS.messages, parentId],
    queryFn: (): Promise<TMessage> => getData(`messages/${parentId}`),
    enabled: !!parentId,
  });
  const invalidateData = useInvalidateQuery();
  const { mutate: replyMessage } = useMutation({
    mutationFn: (data: ReplyMessage) => postData('messages', data),
    onSuccess: () => {
      invalidateData([QUERY_KEYS.messages]);
    },
  });

  const [message, setMessage] = useState('');

  const handleReplyMessage = () => {
    if (messages) {
      replyMessage({
        parentId: messages?.id,
        text: message,
      });
      setMessage('');
    }
  };

  const conversationWith = (sentTab ? messages?.receiver : messages?.author) ?? '';

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <IconButton sx={styles.back} onClick={closeThread}>
          <ArrowBackIcon />
          <Typography sx={styles.backText}>Back</Typography>
        </IconButton>
        <Typography sx={styles.headerText}>Message History</Typography>
      </Box>
      <Box sx={styles.messageInfo}>
        <Box sx={styles.topic}>
          <Typography sx={styles.topicText}>{messages?.topic}</Typography>
          <Typography>{dayjs(messages?.createdAt).format('DD.MM.YYYY')}</Typography>
        </Box>
        {conversationWith && <MessagesProfileBadge author={conversationWith} />}
      </Box>
      <Box sx={styles.messages}>
        {messages && (
          <Message
            author={conversationWith}
            date={messages?.createdAt}
            text={messages?.text}
            messageCreator={messages.author}
          />
        )}
        {messages?.replies.map((message) => (
          <Message
            key={message.id}
            author={conversationWith}
            date={message.createdAt}
            text={message.text}
            messageCreator={message.author}
          />
        ))}
      </Box>
      <TextField
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Reply'
        multiline
        sx={styles.input}
        value={message}
        fullWidth
        InputProps={{
          endAdornment: <SendIcon sx={styles.send} onClick={handleReplyMessage} />,
        }}
      />
    </Box>
  );
};

export default MessagesThread;
