import { Box } from '@mui/material';
import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../../@types';
import { ManagerReport } from '../../../@types/api';
import { getTotalsRowClass } from '../../../helpers';
import { createNumberColumn } from '../../../helpers/columns';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { createColumn } from '../../../utils';
import DateRangeSelect, { DayjsTimestamp } from '../../molecules/DateRangeSelect';
import TableTemplate from '../../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid rgba(0, 83, 55, 0.20)',
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
    '& .MuiDataGrid-columnHeader--emptyGroup': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Total',
    description: 'Total',
    headerClassName: 'customGroupHeader',
    children: [
      { field: 'totalStake' },
      { field: 'totalWinnings' },
      { field: 'totalProfit' },
      { field: 'totalCommission' },
      { field: 'totalProfitAfterCommission' },
    ],
  },
  {
    groupId: 'Tickets',
    description: 'Tickets',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'ticketsTotal' }, { field: 'ticketsLost' }, { field: 'ticketsWon' }, { field: 'ticketsOpen' }],
  },
];

const columns: GridColDef[] = [
  createColumn('fullName', 'Name', { minWidth: 150 }),
  createColumn('partnersCount', 'Partners'),
  createColumn('playersCount', 'Players'),
  createNumberColumn('balance', 'Balance'),
  createNumberColumn('totalStake', 'Stake'),
  createNumberColumn('totalWinnings', 'Winnings'),
  createNumberColumn('totalProfit', 'Profit'),
  createNumberColumn('totalCommission', 'Commision'),
  createNumberColumn('totalProfitAfterCommission', 'Profit After Comm.'),
  createColumn('ticketsTotal', 'Total'),
  createColumn('ticketsLost', 'Lost'),
  createColumn('ticketsWon', 'Won'),
  createColumn('ticketsOpen', 'Open'),
];

const generateTotalsRow = (data?: ManagerReport) => {
  if (data) {
    return {
      ...data,
      userId: 'totals',
      fullName: 'Totals',
      id: 'totals',
    };
  }
  return { id: 'totals' };
};

const ManagersReportPage = () => {
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<ManagerReport>(
    'reports/partners/managers',
    {
      page: 1,
      limit: 25,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    }
  );

  const totalsRow = generateTotalsRow(data?.totals);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <TableTemplate
        getRowId={(params) => params.id}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        rows={aggregatedData || []}
        columns={columns}
        sx={styles.table}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        customTableHeaderContent={<DateRangeSelect date={date} onDateChange={handleDateChange} />}
        showExport
      />
    </Box>
  );
};

export default ManagersReportPage;
