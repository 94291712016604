import { AccessTime } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { StyleObj } from '../../@types';
import { OPERATOR_COUNTDOWN_DURATION } from '../../constants';
import useCountdownTimer from '../../hooks/useCountdownTimer';

const styles: StyleObj = {
  timerIcon: {
    color: {
      xs: 'inherit',
      md: 'primary.main',
    },
    typography: {
      xs: 'h2',
      md: 'h1',
    },
  },
  timerText: {
    typography: {
      xs: 'h5',
      md: 'subtitle1',
    },
    fontWeight: {
      xs: 700,
      md: 700,
    },
    minWidth: 24,
  },
};

type Props = {
  ticketId: string;
  createdAt: string;
};

const calculateRemainingSeconds = (createdAt: string, duration: number) => {
  const createdAtTimestamp = dayjs(createdAt);
  const currentTimestamp = dayjs();
  return Math.max(createdAtTimestamp.add(duration, 'second').diff(currentTimestamp, 'second'), 0);
};

const TicketAuthCountdown = ({ ticketId, createdAt }: Props) => {
  const remainingSeconds = useMemo(
    () => calculateRemainingSeconds(createdAt, OPERATOR_COUNTDOWN_DURATION),
    [createdAt]
  );

  const { seconds: operatorTimeRemaining, resetTimer } = useCountdownTimer(remainingSeconds);

  useEffect(() => {
    resetTimer();
  }, [resetTimer, ticketId]);

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <AccessTime sx={styles.timerIcon} />
      <Typography sx={styles.timerText}>{operatorTimeRemaining}</Typography>
    </Stack>
  );
};

export default TicketAuthCountdown;
