import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { DialogType } from '../../../@types';
import { EventResultsFilterOption, TransferStatus } from '../../../@types/api';
import {
  CHANGED_STATUS_OPTIONS,
  EVENT_RESULTS_FILTER_OPTIONS,
  QUERY_KEYS,
  TRANSFER_STATUS_OPTIONS,
} from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  fromTimestamp: dayjs().startOf('day').valueOf(),
  toTimestamp: dayjs().endOf('day').valueOf(),
  sportId: undefined,
  competitionIds: [],
  tournamentIds: [],
  changedTransferStatus: undefined,
  transferStatuses: [],
  resultsStatus: undefined,
  updateType: undefined,
};

export type FilterResultsData = {
  fromTimestamp: number | Dayjs;
  toTimestamp: number | Dayjs;
  sportId?: string;
  competitionIds: string[];
  tournamentIds: string[];
  changedTransferStatus?: boolean;
  transferStatuses: TransferStatus[];
  resultsStatus?: EventResultsFilterOption;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterResults = ({ changeQuery, closeModal }: Props) => {
  const { control, handleSubmit, watch, handleResetFilter, setValue } = useFilterForm<FilterResultsData>({
    defaultData: defaultValues,
    changeQuery,
    prepareQueryParams: (data) => prepareData(data),
  });

  const sportId = watch('sportId');
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');
  const fromTimestamp = watch('fromTimestamp');
  const toTimestamp = watch('toTimestamp');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
      if (name === 'competitionIds') {
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds, tournamentIds]);

  const prepareData = (data: Partial<FilterResultsData>) => {
    const preparedData = { ...data };

    if (data.fromTimestamp) {
      preparedData.fromTimestamp = dayjs(data.fromTimestamp).valueOf();
    }

    if (data.toTimestamp) {
      preparedData.toTimestamp = dayjs(data.toTimestamp).valueOf();
    }

    if (data.changedTransferStatus !== undefined) {
      preparedData.changedTransferStatus = Boolean(data.changedTransferStatus);
    }

    return preparedData;
  };

  return (
    <FilterDialogLayout
      label='results'
      onClose={() => closeModal?.()}
      onSave={handleSubmit}
      onReset={handleResetFilter}
    >
      <DateTimeSelect control={control} name='fromTimestamp' label='From' maxDateTime={dayjs(toTimestamp)} />
      <DateTimeSelect control={control} name='toTimestamp' label='To' minDateTime={dayjs(fromTimestamp)} />
      <SportSelect label='Sport' control={control} name='sportId' closeMenuOnSelect />
      <FormAutocomplete
        name='competitionIds'
        control={control}
        label='Competitions'
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportId, competitionIds]}
        queryParams={{
          sportId,
          isActive: true,
          competitionIds,
        }}
        disabled={isEmpty([sportId])}
        hookEnabled={!!sportId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
        multiple
      />
      <TournamentSelect
        label='Tournaments'
        control={control}
        name='tournamentIds'
        competitionIds={competitionIds}
        multiple
        closeMenuOnSelect
      />
      <FormSelect
        control={control}
        name='transferStatuses'
        label='Transfer status'
        options={TRANSFER_STATUS_OPTIONS}
        multiple
        closeOnSelect
      />
      <FormSelect
        control={control}
        name='changedTransferStatus'
        label='Changed'
        options={CHANGED_STATUS_OPTIONS}
        closeOnSelect
      />
      <FormSelect control={control} name='resultsStatus' label='Result status' options={EVENT_RESULTS_FILTER_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterResults;
