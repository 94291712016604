import { AddCircle, Cancel, CheckCircle, Info } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ColumnFields } from '../@types';
import IconRenderer from '../components/atoms/IconRenderer';
import Switch from '../components/atoms/Switch';
import TableIconContainer from '../components/atoms/TableIconContainer';
import { AdditionalDataGridColumnProps } from '../components/molecules/CustomizeTable';
import EditTableItem from '../components/organisms/EditTableItem';
import { createColumn, formatNumberToStandard } from '../utils';

type HasSportIcon = {
  sport: {
    icon: string;
  };
};

export type HasIsActive = {
  isActive: boolean;
};

type HasId = {
  id: string;
};

type EditColumnOptions = {
  deleteData?: (id: string) => void;
  addOutcome?: (marketId: string, sportId: string) => void;
  columnName?: string;
  icon?: React.ReactNode;
  minWidth?: number;
};

type ToggleColumnOptions<T> = {
  updateData: (id: string, data: Partial<T>) => void;
  alwaysVisible?: boolean;
  modalEntity?: string;
};
export const getConfirmMessage = (action: string, entity?: string, item?: string) => {
  return `Are you sure you want to ${action} Cash Out for ${entity} ${item}?`;
};
export const getCurrentAction = (checked: boolean) => {
  return checked ? 'enable' : 'disable';
};
export const createToggleColumn = <T,>(options: GridColDef & ToggleColumnOptions<T>): GridColDef => {
  const { field, headerName = 'Status', updateData, alwaysVisible, modalEntity, sortable = true } = options;

  return createColumn(field, headerName, {
    alwaysVisible,
    sortable,
    renderCell: (params) => (
      <Switch
        value={params.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          updateData(params.row.id, { [field]: !params.value } as Partial<T>);
        }}
        name={field}
        handleConfirmationMessage={
          field !== 'isActive'
            ? () => getConfirmMessage(getCurrentAction(!params.value), modalEntity, params.row.name)
            : undefined
        }
      />
    ),
  });
};

export const createSportIconColumn = <T extends HasSportIcon>(): GridColDef => {
  return createColumn('sportIcon', 'Icon', {
    sortable: false,
    valueGetter: (params: GridValueGetterParams<T>) => params.row.sport.icon,
    renderCell: (params) => {
      return (
        <TableIconContainer>
          <IconRenderer name={params.value} type='sport' />
        </TableIconContainer>
      );
    },
  });
};

export const createIsActiveColumn = <T,>(
  options: Omit<GridColDef, 'field' | 'headerName'> & ToggleColumnOptions<T>
): GridColDef => {
  return createToggleColumn<T>({
    field: 'isActive',
    headerName: 'Status',
    ...options,
  });
};

export const createCashOutColumn = <T,>(
  options: Omit<GridColDef, 'field' | 'headerName'> & ToggleColumnOptions<T>
): GridColDef => {
  return createToggleColumn<T>({
    field: 'eligibleForCashout',
    headerName: 'Cash Out',
    sortable: false,
    ...options,
  });
};

export const createEditColumn = <T extends HasId>(options: EditColumnOptions = {}): GridColDef => {
  const { deleteData, addOutcome, columnName = 'Edit', icon, minWidth = 100 } = options;
  return createColumn('edit', columnName, {
    sortable: false,
    disableExport: true,
    minWidth,
    renderCell: (params) => (
      <>
        {addOutcome && (
          <IconButton onClick={() => addOutcome(params.row.id, params.row.sport.id)} color='primary'>
            <Tooltip title='Add outcome'>
              <AddCircle />
            </Tooltip>
          </IconButton>
        )}
        <EditTableItem<T>
          handleDelete={deleteData ? () => deleteData(params.row.id) : undefined}
          item={params.row}
          icon={icon}
        />
      </>
    ),
  });
};

export const createDescriptionColumn = () =>
  createColumn('description', 'Description', {
    sortable: false,
    renderCell: (params) =>
      params.row.description && (
        <TableIconContainer>
          <Tooltip title={params.row.description}>
            <Info color='primary' />
          </Tooltip>
        </TableIconContainer>
      ),
  });

export const createStatusDisplayColumn = () =>
  createColumn('isActive', 'Status', {
    renderCell: (params) =>
      params.row.isActive ? (
        <CheckCircle color='primary' fontSize='medium' />
      ) : (
        <Cancel color='error' fontSize='medium' />
      ),
  });

export const createNumberColumn = (
  field: ColumnFields | string,
  headerName: string,
  specificProps?: AdditionalDataGridColumnProps & Omit<GridColDef, 'field' | 'headerName'>
) =>
  createColumn(field, headerName, {
    ...specificProps,
    type: 'number',
    valueGetter: (params: GridValueGetterParams) => {
      if (specificProps?.hideZeros && !params.value) return null;
      return params.value;
    },
    valueFormatter: (params) => {
      if (specificProps?.hideZeros && !params.value) return null;
      if (specificProps?.skipFormatting?.(params)) {
        return params.value;
      }
      return formatNumberToStandard(params.value);
    },
  });

const formatPercentageValue = (params: { value: unknown }) =>
  typeof params.value === 'number' ? `${params.value.toFixed(2)}%` : params.value;

export const createPercentageColumn = (field: ColumnFields | string, headerName: string) => {
  return createColumn(field, headerName, {
    valueFormatter: formatPercentageValue,
  });
};
