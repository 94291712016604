import { Info, Search } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { GridColDef, GridColumnGroupingModel, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { StyleObj } from '../../../../@types';
import { Transaction } from '../../../../@types/api';
import { usePagination } from '../../../../hooks/usePagination';
import useSort from '../../../../hooks/useSort';
import { createColumn } from '../../../../utils';
import TableIconContainer from '../../../atoms/TableIconContainer';
import TicketDetailsModal from '../../../modals/TicketDetailsModal';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';
import { WALLET_TRANSACTION_SOURCE, WALLET_TRANSACTION_TYPE } from '../../../../constants';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid rgba(0, 83, 55, 0.20)',
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
    '& .MuiDataGrid-columnHeader--emptyGroup': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Main',
    description: 'Main',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainIn' }, { field: 'mainOut' }, { field: 'mainBalance' }],
  },
  {
    groupId: 'Bonus',
    description: 'Bonus',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'bonusIn' }, { field: 'bonusOut' }, { field: 'bonusBalance' }],
  },
];

type Props = {
  userId?: string;
  columns: GridColDef[];
  endpoint: string;
};

const WalletTransactionPage = <T,>({ userId, columns, endpoint }: Props) => {
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const [modalBetslipId, setModalBetslipId] = useState<string | null>(null);

  const { data, changeQuery, updateQueryParams, isLoading, isFetching } = usePagination<T>(
    endpoint,
    {
      page: 1,
      limit: 25,
      userId,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    },
    undefined,
    'wallet'
  );

  const { handleSort } = useSort(changeQuery);

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  const openBetslipModal = (betslipId: string) => {
    setModalBetslipId(betslipId);
  };

  const columnsWithDescription = useMemo(
    () => [
      ...columns,
      createColumn('description', 'Description', {
        sortable: false,
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          if (params.row.source === WALLET_TRANSACTION_SOURCE.CASINO) {
            return null;
          }

          if (params.row.type === WALLET_TRANSACTION_TYPE.PLACE_BET) {
            return (
              <TableIconContainer onClick={() => openBetslipModal(params.row.orderId)} sx={{ cursor: 'pointer' }}>
                <Search color='primary' />
              </TableIconContainer>
            );
          }

          if (!!params.row.description?.trim()) {
            return (
              <TableIconContainer>
                <Tooltip title={params.row.description}>
                  <Info color='primary' />
                </Tooltip>
              </TableIconContainer>
            );
          }

          return null;
        },
      }),
    ],
    [columns]
  );

  return (
    <Box sx={styles.container}>
      <TableTemplate
        columns={columnsWithDescription}
        rows={data?.items || []}
        rowCount={data?.count || 0}
        handleSort={handleSort}
        sx={styles.table}
        changeQuery={changeQuery}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        handlePaginationModelChange={updateQueryParams}
        loading={isFetching || isLoading}
        customTableHeaderContent={<DateRangeSelect date={date} onDateChange={handleDateChange} />}
      />
      {modalBetslipId && (
        <TicketDetailsModal
          id={modalBetslipId}
          onClose={() => {
            setModalBetslipId(null);
          }}
        />
      )}
    </Box>
  );
};

export default WalletTransactionPage;
