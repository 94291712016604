import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { DateRangeOption, StyleObj } from '../../@types';
import { DATE_TIME_FORMAT, PREDEFINED_DATE_RANGE_OPTIONS } from '../../constants';
import { generateDateRangeTimestamps } from '../../helpers';

const DEFAULT_DATE_RANGE_OPTION: DateRangeOption = 'today';

const makeStyles = (): StyleObj => ({
  dateRangeWrapper: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-end',
    },
  }),
  datePickersWrapper: (theme) => ({
    display: 'flex',
    gap: 2,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  }),
  select: (theme) => ({
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  datePicker: (theme) => ({
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
});

export type DayjsTimestamp = { fromTimestamp: Dayjs; toTimestamp: Dayjs };

type Props = {
  date: DayjsTimestamp;
  onDateChange: (date: DayjsTimestamp) => void;
};

const DateRangeSelect = ({ date, onDateChange }: Props) => {
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState<DateRangeOption>(DEFAULT_DATE_RANGE_OPTION);

  const handleDateChange = (key: 'fromTimestamp' | 'toTimestamp', value: Dayjs | null) => {
    if (value) {
      onDateChange({ ...date, [key]: value });
    }
  };

  const handleDateRangeChange = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value as DateRangeOption;
    setSelectedDateRangeOption(selectedOption);

    if (selectedOption !== 'custom') {
      onDateChange(generateDateRangeTimestamps(selectedOption) as DayjsTimestamp);
    }
  };

  const styles = makeStyles();
  const isCustomDateRange = selectedDateRangeOption === 'custom';

  return (
    <Box sx={styles.dateRangeWrapper}>
      <Box sx={styles.datePickersWrapper}>
        <DateTimePicker
          label='From'
          value={date.fromTimestamp}
          onChange={(value) => handleDateChange('fromTimestamp', value)}
          format={DATE_TIME_FORMAT}
          ampm={false}
          sx={styles.datePicker}
          disabled={!isCustomDateRange}
        />
        <DateTimePicker
          label='To'
          value={date.toTimestamp}
          onChange={(value) => handleDateChange('toTimestamp', value)}
          ampm={false}
          format={DATE_TIME_FORMAT}
          minDate={date.fromTimestamp}
          sx={styles.datePicker}
          disabled={!isCustomDateRange}
        />
      </Box>
      <FormControl sx={styles.select}>
        <InputLabel>Predefined</InputLabel>
        <Select value={selectedDateRangeOption} label='Predefined' onChange={handleDateRangeChange} sx={styles.select}>
          {PREDEFINED_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DateRangeSelect;
