import { Box } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { StyleObj } from '../../../@types';
import { ConditionGroup } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { createDescriptionColumn } from '../../../helpers/columns';
import { defaultColumnsTicketConditions } from '../../../helpers/table';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { ConditionTemplateFormData } from '../../../schema';
import { createColumn } from '../../../utils';
import ConditionsValuesForm from '../../organisms/forms/conditions/ConditionsValuesForm';
import TableTemplate from '../../templates/TableTemplate';
import MobileDialogWrapper from '../../organisms/MobileDialogWrapper';

const makeStyles = (selectedRow: boolean): StyleObj => ({
  container: {
    display: 'grid',
    gridTemplateColumns: selectedRow ? '2fr 1fr' : '1fr',
    height: '100%',
    maxHeight: '100%',
    minHeight: 0,
    gap: 3,
  },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
  conditionsValuesFormWrapper: (theme) => ({
    mt: 11,
    backgroundColor: 'background.light',
    p: 3,
    borderRadius: 2,
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  }),
});

const TicketsConditionsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<ConditionGroup | null>(null);

  const styles = makeStyles(!!selectedGroup);

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<ConditionGroup>('conditions', {
    page: 1,
    limit: 25,
    type: 'ticket',
  });

  const { createData } = useMutateData('conditions', [
    QUERY_KEYS.condtitionTemplates,
    { conditionGroupId: selectedGroup?.id },
  ]);

  const { handleSort } = useSort(changeQuery);

  const conditionGroupColumns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
      alwaysVisible: true,
    }),
    createColumn('name', 'Betting offer', { alwaysVisible: true, sortable: false }),
    createDescriptionColumn(),
  ];

  const onSave = (values: ConditionTemplateFormData) => {
    createData({
      ...values,
      conditionGroupId: null,
      modelType: 'ticket',
    });
  };

  const onFormCancel = () => {
    setSelectedGroup(null);
  };

  const onCellClick = (params: GridCellParams<ConditionGroup>) => {
    setSelectedGroup(params.row);
  };

  const slotProps = {
    row: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={data?.items || []}
        rowCount={data?.count || 0}
        columns={conditionGroupColumns}
        defaultVisibleColumns={defaultColumnsTicketConditions}
        loading={isFetching || isLoading}
        handlePaginationModelChange={updateQueryParams}
        handleSort={handleSort}
        changeQuery={changeQuery}
        onCellClick={onCellClick}
        rowSelectionModel={selectedGroup ? [selectedGroup.id] : []}
        slotProps={slotProps}
        showFilter={false}
      />
      {selectedGroup && (
        <MobileDialogWrapper>
          <Box sx={styles.conditionsValuesFormWrapper}>
            <ConditionsValuesForm
              key={selectedGroup.product}
              onCancel={onFormCancel}
              onSave={onSave}
              conditionGroupId={null}
              modelId={selectedGroup.id}
              conditionModelType={'ticket'}
              product={selectedGroup.product}
            />
          </Box>
        </MobileDialogWrapper>
      )}
    </Box>
  );
};

export default TicketsConditionsPage;
