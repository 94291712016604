import { Box } from '@mui/material';
import { ManualIcon } from '../icons';
import Circle from '../icons/Circle';
import { StyleObj } from '../../@types';
import { getLatestValue } from '../../helpers';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import { EventList } from '../../@types/api';

const styles: StyleObj = {
  nameColumnWrapper: { display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center', gap: 0.25 },
  nameColumnIconsWrapper: { display: 'flex', alignItems: 'center' },
  nameWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textAlign: 'left',
  },
};

type Props = {
  row: EventList;
};

const EventNameCell = ({ row }: Props) => {
  return (
    <Box sx={styles.nameColumnWrapper}>
      <Box sx={styles.nameColumnIconsWrapper}>
        {getLatestValue(row, 'manualControl') ? (
          <ManualIcon width='16' height='16' viewBox='4 4 16 16' />
        ) : (
          row.marketManualControlCount > 0 && <Circle size={16}>{row.marketManualControlCount}</Circle>
        )}
        <PublishStatusIcon
          status={row.publishStatus}
          viewBox='2 2 20 20'
          width='20'
          height='20'
          changed={row.changed}
        />
      </Box>
      <Box title={row.name} sx={styles.nameWrapper}>
        {getLatestValue(row, 'name')}
      </Box>
    </Box>
  );
};

export default EventNameCell;
