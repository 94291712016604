import { Box, capitalize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';
import { generateDateRangeTimestamps, getTotalsRowClass } from '../../../../helpers';
import dayjs from 'dayjs';
import { usePagination } from '../../../../hooks/usePagination';
import { createColumn } from '../../../../utils';
import { GridColDef } from '@mui/x-data-grid';
import { StyleObj } from '../../../../@types';
import useSort from '../../../../hooks/useSort';
import { createDescriptionColumn } from '../../../../helpers/columns';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

const columns: GridColDef[] = [
  createColumn('id', 'ID', {
    sortable: false,
  }),
  createColumn('playerFullName', 'Player', {
    sortable: false,
    minWidth: 150,
  }),
  createColumn('agentFullName', 'Updated by', {
    sortable: false,
    minWidth: 150,
  }),
  createColumn('executionTime', 'Date & Time', {
    valueGetter: (params) => dayjs(params.value).format('DD-MM-YYYY HH:mm:ss'),
  }),
  createColumn('type', 'Type', {
    valueFormatter: (params) => capitalize(params.value),
  }),
  createColumn('paymentMethod', 'Pay Method'),
  createColumn('amount', 'Amount'),
  createColumn('status', 'Status', {
    valueFormatter: (params) => capitalize(params.value),
  }),
  createDescriptionColumn(),
];

const PaymentsListReportsPage = () => {
  const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps('today');
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination('reports/payments/list', {
    page: 1,
    limit: 25,
    fromTimestamp: fromTimestamp.valueOf(),
    toTimestamp: toTimestamp.valueOf(),
  });
  const { handleSort } = useSort(changeQuery);

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <TableTemplate
        getRowId={(params) => params.id}
        experimentalFeatures={{ columnGrouping: true }}
        rows={data?.items || []}
        columns={columns}
        sx={styles.table}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        customTableHeaderContent={<DateRangeSelect date={date} onDateChange={handleDateChange} />}
        showExport
      />
    </Box>
  );
};

export default PaymentsListReportsPage;
