import {
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ProductType, RiskFactorDefaults, TicketAuthMode } from '../../@types/api';
import { BETSLIP_CONTROL_DELAY_OPTIONS, QUERY_KEYS, TICKET_AUTH_MODE } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { getData } from '../../utils/api';
import { StyleObj } from '../../@types';
import theme from '../../styles/theme';

const styles: StyleObj = {
  settingLabel: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.lightGreen',
    flex: 1,
  },
  select: {
    flex: 1,
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  selectInput: {
    textAlign: 'center',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  toggleButtonGroup: {
    flex: 1,
    '&.MuiToggleButton-root': {
      borderRadius: 0,
    },
  },
};

const RiskDefaultSettings = ({ productType }: { productType: ProductType }) => {
  const { data: globalTimeDelay } = useQuery([QUERY_KEYS.riskFactorDefaults, productType, 'timeDelay'], {
    queryFn: (): Promise<RiskFactorDefaults> => getData('risk-management/entity-risk-factors/defaults'),
    select: (data) => data?.[productType]?.global?.timeDelaySeconds,
  });

  const { data: ticketAuthMode } = useQuery([QUERY_KEYS.riskFactorDefaults, productType, 'ticketAuthMode'], {
    queryFn: (): Promise<{ item: { product: ProductType; mode: TicketAuthMode } }> =>
      getData('authorization-settings', {
        product: productType,
      }),
    select: (data) => data?.item.mode,
  });

  const { updateData: updateGlobalTimeDelay, isLoading: updateGlobalTimeDelayLoading } = useMutateData(
    `risk-management/entity-risk-factors/${productType}`,
    [QUERY_KEYS.riskFactorDefaults, productType, 'timeDelay']
  );

  const { updateData: updateTicketAuthMode } = useMutateData('authorization-settings', [
    QUERY_KEYS.riskFactorDefaults,
    productType,
    'ticketAuthMode',
  ]);

  const handleBetslipDelayChange = (event: SelectChangeEvent<number>) => {
    updateGlobalTimeDelay('global', {
      timeDelaySeconds: event.target.value,
    });
  };

  const handleTicketAuthModeChange = (_event: React.MouseEvent<HTMLElement>, value: TicketAuthMode) =>
    updateTicketAuthMode(productType, {
      mode: value,
    });

  return (
    <>
      <Stack alignItems='center' direction='row' minHeight={58}>
        <Stack sx={styles.settingLabel}>
          <Typography variant='h5'>Bet placement time delay</Typography>
        </Stack>
        {updateGlobalTimeDelayLoading ? (
          <Stack alignItems='center' flex={1}>
            <CircularProgress size={24} />
          </Stack>
        ) : (
          <Select
            sx={styles.select}
            value={globalTimeDelay || 0}
            onChange={handleBetslipDelayChange}
            inputProps={{ sx: styles.selectInput }}
          >
            {BETSLIP_CONTROL_DELAY_OPTIONS.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Stack sx={styles.settingLabel}>
          <Typography variant='h5'>Ticket authorization mode</Typography>
        </Stack>
        <ToggleButtonGroup
          value={ticketAuthMode}
          onChange={handleTicketAuthModeChange}
          exclusive
          fullWidth
          sx={styles.toggleButtonGroup}
          color='primary'
        >
          <ToggleButton value={TICKET_AUTH_MODE.MANUAL}>Manual</ToggleButton>
          <ToggleButton value={TICKET_AUTH_MODE.AUTOMATIC}>Automatic</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  );
};

export default RiskDefaultSettings;
