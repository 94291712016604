import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GridApi, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector } from '@mui/x-data-grid';
import { Theme } from '@mui/material';

export const exportToExcel = (data: unknown[]) => {
  const workbook = XLSX.utils.book_new(); // Create a new workbook
  const worksheet = XLSX.utils.json_to_sheet(data); // Convert the data to a worksheet
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // Append the worksheet to the workbook
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel file
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' }); // Create a Blob and save it using file-saver
  saveAs(blob, 'sports_book_bo.xlsx');
};

const camelCaseToTitleCase = (str: string): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add spaces between camelCase words
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word

export const getExportData = (apiRef: React.MutableRefObject<GridApi>) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  const filteredColumnFields = visibleColumnsField.filter(
    (columnField) => columnField !== 'edit' && columnField !== 'actions'
  );

  // Format the data. Here we only keep the value
  const excelExportData = filteredSortedRowIds.map((rowId) => {
    const rowData: Record<string, unknown> = {};
    filteredColumnFields.forEach((columnField: string) => {
      const columnFieldTextCase = camelCaseToTitleCase(columnField);
      rowData[columnFieldTextCase] = apiRef.current.getCellParams(rowId, columnField).value;
    });
    return rowData;
  });

  return excelExportData;
};

export const exportStyles = (theme: Theme, showSearch: boolean, showCustomizeTable?: boolean) => {
  const defaultLeftPosition = showSearch || showCustomizeTable ? '278px' : '75px';
  const leftPosition = showCustomizeTable && showSearch ? '330px' : defaultLeftPosition;

  return {
    paddingLeft: 0,
    position: 'absolute',
    top: '-80px',
    left: leftPosition,
    background: 'transparent',
    color: 'transparent',
    ':hover': {
      background: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      left: showCustomizeTable && showSearch ? '122px' : '66px',
      top: '-60px',
    },
  };
};
