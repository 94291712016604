import { IconButton, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { StyleObj } from '../../@types';
import { drawerWidth } from '../molecules/DrawerNavigation';
import MessageIcon from '../molecules/MessageIcon';
import SelectTenant from '../molecules/SelectTenant';
import AccountIcon from '../molecules/AccountIcon';
import usePermissions from '../../hooks/usePermissions';
import { USER_ROLES } from '../../constants/keycloak';
import SelectedTenantName from '../atoms/SelectedTenantName';
import MenuIcon from '@mui/icons-material/Menu';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type HeaderProps = {
  open: boolean;
  handleDrawerOpen: () => void;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: theme.palette.primary.container,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  }),
}));

const styles: StyleObj = {
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 900,
  },
};

const Header = ({ open, handleDrawerOpen }: HeaderProps) => {
  const { role } = usePermissions();
  const isSuperAdmin = role === USER_ROLES.SUPER_ADMIN;
  return (
    <AppBar position='fixed' open={open}>
      <Toolbar>
        <IconButton aria-label='menu' onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <Typography color='primary' variant='h2' component='div' sx={styles.title}>
          NTS 360 {<SelectedTenantName />}
        </Typography>
        <MessageIcon />
        {isSuperAdmin && <SelectTenant />}
        <AccountIcon />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
