import { Save } from '@mui/icons-material';
import {
  Button,
  capitalize,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Control, Controller, Path, useForm } from 'react-hook-form';
import { SelectOption, StyleObj } from '../../@types';
import { PaginatedData, ProductType } from '../../@types/api';
import { PRODUCT_TYPES, QUERY_KEYS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { useCashoutMarketMargins } from '../../queries';
import { getData } from '../../utils/api';
import Switch from '../atoms/Switch';
import FormNumberInput from './FormNumberInput';
import FormSelect from './FormSelect';
import theme from '../../styles/theme';

const DEFAULT_VALUES = {
  minHitTotalOdds: 0,
  minNumWonSelections: 0,
  minCashoutAmount: 0,
  maxCashoutAmount: 0,
  uniqueMarketMargin: 1,
  multipleTickets: false,
  systemTickets: false,
  waysTickets: false,
  partialCashout: false,
  timeDelay: 0,
};

const styles: StyleObj = {
  container: { flex: 1 },
  tableBody: {
    '& .MuiTableCell-root': {
      p: 1,
      textAlign: 'center',
    },
  },
  submitBtn: { mt: 1, width: 'fit-content' },
  selectInput: {
    fontSize: 14,
    pl: 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
};

type InputType = 'switch' | 'number' | 'select';

type RowDefinition = {
  label: string;
  fieldName: Path<CashoutSettingsFormData>;
  type: InputType;
  options?: SelectOption[];
  condition?: boolean;
  disabled?: boolean;
};

type CashoutSettings = {
  product: ProductType;
  minHitTotalOdds: number;
  minNumWonSelections: number;
  minCashoutAmount: number;
  maxCashoutAmount: number;
  uniqueMarketMargin: number;
  multipleTickets: boolean;
  systemTickets: boolean;
  waysTickets: boolean;
  partialCashout: boolean;
  timeDelay: number;
};

type CashoutSettingsFormData = Omit<CashoutSettings, 'product'>;

type Props = {
  product: ProductType;
};

const renderTableRow = (label: string, content: JSX.Element) => (
  <TableRow key={label}>
    <TableCell align='center'>{label}</TableCell>
    <TableCell align='center'>{content}</TableCell>
  </TableRow>
);

const renderSwitchRow = (
  label: string,
  fieldName: Path<CashoutSettingsFormData>,
  control: Control<CashoutSettingsFormData>,
  disabled?: boolean
) =>
  renderTableRow(
    label,
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => <Switch {...field} value={Boolean(field.value)} ref={null} disabled={disabled} />}
    />
  );

const renderNumberRow = (
  label: string,
  fieldName: Path<CashoutSettingsFormData>,
  control: Control<CashoutSettingsFormData>
) =>
  renderTableRow(
    label,
    <FormNumberInput
      control={control}
      name={fieldName}
      variant='standard'
      InputProps={{
        disableUnderline: true,
        inputProps: {
          sx: {
            textAlign: 'center',
            fontSize: 14,
            [theme.breakpoints.down('sm')]: {
              fontSize: 12,
            },
          },
        },
      }}
      allowDecimals
      valueAsString
    />
  );

const renderSelectRow = (
  label: string,
  fieldName: Path<CashoutSettingsFormData>,
  options: SelectOption[],
  control: Control<CashoutSettingsFormData>
) =>
  renderTableRow(
    label,
    <FormSelect
      control={control}
      name={fieldName}
      options={options}
      sx={styles.selectInput}
      variant='standard'
      MenuProps={{
        MenuListProps: { dense: true },
      }}
      disableUnderline
    />
  );

const CashoutSettingsTable = ({ product }: Props) => {
  const { data } = useQuery([QUERY_KEYS.cashout, product], {
    queryFn: (): Promise<PaginatedData<CashoutSettings>> =>
      getData('cashout-settings', {
        product,
      }),
  });

  const { updateData, isLoading: isMutating } = useMutateData('cashout-settings', [QUERY_KEYS.cashout, product]);

  const { data: marketMargins } = useCashoutMarketMargins();

  const marketMarginOptions = useMemo(
    () =>
      marketMargins?.map((margin) => ({
        name: (margin / 100).toFixed(2),
        id: margin,
      })),
    [marketMargins]
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    values: data?.items.find((item) => item.product === product),
  });

  const onSubmit = (data: CashoutSettingsFormData) => {
    updateData(product, {
      ...data,
      minHitTotalOdds: Number(data.minHitTotalOdds),
      minNumWonSelections: Number(data.minNumWonSelections),
      minCashoutAmount: Number(data.minCashoutAmount),
      maxCashoutAmount: Number(data.maxCashoutAmount),
      timeDelay: Number(data.timeDelay),
    });
  };

  const rowDefinitions: RowDefinition[] = useMemo(
    () => [
      { label: 'Min Hit Total Odds', fieldName: 'minHitTotalOdds', type: 'number' },
      { label: 'Min Num Won Selections', fieldName: 'minNumWonSelections', type: 'number' },
      { label: 'Min Cashout Amount', fieldName: 'minCashoutAmount', type: 'number' },
      { label: 'Max Cashout Amount', fieldName: 'maxCashoutAmount', type: 'number' },
      {
        label: 'Unique Market Margin',
        fieldName: 'uniqueMarketMargin',
        type: 'select',
        options: marketMarginOptions || [],
      },
      { label: 'Multiple Tickets', fieldName: 'multipleTickets', type: 'switch' },
      { label: 'System Tickets', fieldName: 'systemTickets', type: 'switch', disabled: true },
      { label: 'Ways Tickets', fieldName: 'waysTickets', type: 'switch', disabled: true },
      { label: 'Partial Cashout', fieldName: 'partialCashout', type: 'switch' },
      { label: 'Time Delay', fieldName: 'timeDelay', type: 'number', condition: product === PRODUCT_TYPES.IN_PLAY },
    ],
    [marketMarginOptions, product]
  );

  const renderRow = useCallback(
    ({ label, fieldName, type, options, condition = true, disabled = false }: RowDefinition) => {
      if (!condition) return null;

      switch (type) {
        case 'switch':
          return renderSwitchRow(label, fieldName, control, disabled);
        case 'number':
          return renderNumberRow(label, fieldName, control);
        case 'select':
          return renderSelectRow(label, fieldName, options || [], control);
        default:
          return null;
      }
    },
    [control]
  );

  return (
    <Stack spacing={2} flex={1} alignItems='center'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{capitalize(product)}</TableCell>
              <TableCell align='center'>Cash Out Settings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>{rowDefinitions.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
      <Divider orientation='horizontal' flexItem />
      <Button
        startIcon={isMutating ? <CircularProgress size={16} /> : <Save />}
        sx={styles.submitBtn}
        onClick={handleSubmit(onSubmit)}
        disabled={isMutating || !isDirty}
      >
        Save
      </Button>
    </Stack>
  );
};

export default CashoutSettingsTable;
