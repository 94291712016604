import { useFeature } from '@growthbook/growthbook-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType, SelectOption } from '../../../@types';
import { Marketplace } from '../../../@types/api';
import { QUERY_KEYS, STATUS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { MarketplaceFormData, marketplaceFormSchema } from '../../../schema';
import FormModalLayout from '../../layouts/FormModalLayout';
import CountrySelect from '../../molecules/CountrySelect';
import FormSelect from '../../molecules/FormSelect';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';

const DEFAULT_FORM_DATA: MarketplaceFormData = {
  name: undefined,
  domain: undefined,
  status: 'ACTIVE',
  country: undefined,
  branding: undefined,
  contactInformation: {
    contactName: undefined,
    contactEmail: '',
    contactPhoneNumber: undefined,
  },
  sanityConfig: {
    projectId: '',
    dataset: '',
  },
};

type Props = DialogType;

const MarketplaceForm = ({ closeModal }: Props) => {
  const { item } = useModal<Marketplace>();
  const { createData, updateData } = useMutateData('bo/marketplace', [QUERY_KEYS.marketplace], 'crm');

  const { value: themeOptions } = useFeature<SelectOption[]>('theme-options');

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<MarketplaceFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(marketplaceFormSchema),
  });

  useEffect(() => {
    if (item) {
      reset(item);
    }
  }, [item, reset]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: MarketplaceFormData) => {
    if (item) {
      updateData(item.id, data, handleClose);
    } else {
      createData(data, handleClose);
    }
  };

  const isEdit = !!item;

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEdit ? 'Configure marketplace' : 'Add new marketplace'}
      onClose={handleClose}
      isEdit={isEdit}
    >
      <TextField
        error={!!errors.name}
        helperText={errors.name?.message}
        label='Name'
        {...register('name')}
        required
        autoFocus
      />
      <TextField
        error={!!errors.domain}
        helperText={errors.domain?.message}
        label='API Domain/Subdomain'
        {...register('domain')}
        required
      />
      <CountrySelect control={control} name='country' allowDeselect={false} error={errors.country} required />
      <FormSelect
        name='branding'
        control={control}
        label='Branding theme'
        options={themeOptions ?? []}
        allowDeselect={false}
        error={errors.branding}
        required
      />

      <Typography variant='subtitle1'>Contact information</Typography>
      <Divider />
      <TextField
        label='Name'
        {...register('contactInformation.contactName')}
        required
        error={!!errors.contactInformation?.contactName}
        helperText={errors.contactInformation?.contactName?.message}
      />
      <TextField
        label='Email'
        {...register('contactInformation.contactEmail')}
        error={!!errors.contactInformation?.contactEmail}
        helperText={errors.contactInformation?.contactEmail?.message}
        required
      />
      <TextField
        label='Phone'
        {...register('contactInformation.contactPhoneNumber')}
        error={!!errors.contactInformation?.contactPhoneNumber}
        helperText={errors.contactInformation?.contactPhoneNumber?.message}
        required
      />
      <Divider />

      <Typography variant='subtitle1'>Sanity config</Typography>
      <Divider />
      <TextField
        label='Project ID'
        {...register('sanityConfig.projectId')}
        error={!!errors.sanityConfig?.projectId}
        helperText={errors.sanityConfig?.projectId?.message}
        required
      />
      <TextField
        label='Dataset'
        {...register('sanityConfig.dataset')}
        error={!!errors.sanityConfig?.dataset}
        helperText={errors.sanityConfig?.dataset?.message}
        required
      />
      <Divider />

      <FormFieldStack label='Status'>
        <Controller
          name='status'
          control={control}
          render={({ field }) => (
            <Switch
              value={field.value === STATUS.ACTIVE}
              onChange={(e) => setValue('status', e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE)}
              ref={null}
            />
          )}
        />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default MarketplaceForm;
