import { FieldValues } from 'react-hook-form';
import FormSelect, { FormSelectProps } from './FormSelect';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../utils/api';
import { useMemo } from 'react';
import { QUERY_KEYS } from '../../constants';

type Props<T extends FieldValues> = Omit<FormSelectProps<T>, 'options'>;

type CountryResponse = {
  name: {
    common: string;
  };
}[];

const COUNTRIES_ENDPOINT = 'https://restcountries.com/v3.1/all';

const CountrySelect = <T extends FieldValues>(props: Props<T>) => {
  const { data } = useQuery([QUERY_KEYS.countries], (): Promise<CountryResponse> => getData(COUNTRIES_ENDPOINT));

  const countryOptions = useMemo(() => {
    return data
      ?.map((country) => ({
        name: country.name.common,
        id: country.name.common,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  return <FormSelect label='Country' options={countryOptions ?? []} {...props} />;
};

export default CountrySelect;
