import { getItemFromSessionStorage } from './storageHelper';

export const DYNAMIC_URL_TYPE = {
  API: 'api',
  AUTH: 'auth',
  CASINO_BACKOFFICE: 'casino-backoffice',
} as const;

export type UrlType = (typeof DYNAMIC_URL_TYPE)[keyof typeof DYNAMIC_URL_TYPE];

const getHostNameSegments = () => {
  const hostname = window.location.hostname;
  const hostnameSegments = hostname.split('.');
  if (hostnameSegments.length > 2) {
    hostnameSegments.shift();
  }
  return hostnameSegments;
};

export const generateUrl = (type: UrlType, apiEndpoint?: string) => {
  const hostnameSegments = getHostNameSegments();
  hostnameSegments.unshift(type);
  const endPoint = apiEndpoint ? `/${apiEndpoint}` : '';
  const generatedUrl = `https://${hostnameSegments.join('.')}${endPoint}`;
  return generatedUrl;
};

export const KEYCLOAK_CLIENT_ID = getHostNameSegments().join('.');

//getSanityConfig from LS for Super Admin
//for other users there will be endpoint for getting config data
export const getSanityConfig = () => {
  const selectedTenant = getItemFromSessionStorage('marketplace');
  const sanityConfig = selectedTenant?.sanityConfig;
  return sanityConfig;
};
