import React, { createContext, useContext } from 'react';
import { Marketplace } from '../@types/api';
import { getData } from '../utils/api';
import { getItemFromSessionStorage } from '../utils/storageHelper';
import usePermissions from '../hooks/usePermissions';
import { USER_ROLES } from '../constants/keycloak';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import CenteredSpinner from '../components/atoms/CenteredSpinner';

interface MarketplaceContextProps {
  config: Marketplace | null;
}

const MarketplaceContext = createContext<MarketplaceContextProps | undefined>(undefined);

interface MarketplaceProviderProps {
  children: React.ReactNode;
}

export const MarketplaceProvider: React.FC<MarketplaceProviderProps> = ({ children }) => {
  const { role } = usePermissions();
  const { data } = useQuery<Marketplace>([QUERY_KEYS.marketplaceConfig], {
    queryFn: () => getData('/bo/marketplace/config', undefined, 'crm'),
    enabled: role !== USER_ROLES.SUPER_ADMIN,
  });

  const config = data ?? getItemFromSessionStorage('marketplace');
  const value = React.useMemo(() => ({ config }), [config]);

  if (!value?.config && role !== USER_ROLES.SUPER_ADMIN) {
    return <CenteredSpinner />;
  }
  return <MarketplaceContext.Provider value={value}>{children}</MarketplaceContext.Provider>;
};

export const useMarketplaceConfig = (): MarketplaceContextProps => {
  const context = useContext(MarketplaceContext);
  if (context === undefined) {
    throw new Error('useMarketplaceConfig must be used within a MarketplaceProvider');
  }
  return context;
};
