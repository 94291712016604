import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  Method,
  RawAxiosRequestHeaders,
} from 'axios';
import { getAccessToken } from '@neotech-solutions-org/neofusion-fe-shared';
import { UserRole } from '../@types/api';
import { MINUTE } from '../constants';
import { getItemFromSessionStorage } from './storageHelper';
import { INACTIVITY_EVENT } from '../constants/events';
import { DYNAMIC_URL_TYPE, generateUrl, UrlType } from './envDynamicValues';

const API_INACTIVITY_TIMEOUT = 30 * MINUTE;
const SELECTED_MARKETPLACE = getItemFromSessionStorage('marketplace');

export type MethodsHeaders = Partial<
  {
    [Key in Method as Lowercase<Key>]: AxiosHeaders;
  } & { common: AxiosHeaders }
>;

const handleMultiTenant = (config: InternalAxiosRequestConfig) => {
  const marketplaceInStorage = getItemFromSessionStorage('marketplace');
  if (marketplaceInStorage) {
    const lastRequestTime = getItemFromSessionStorage('lastRequestTime');
    const currentTime = new Date().getTime();
    if (lastRequestTime && currentTime - lastRequestTime > API_INACTIVITY_TIMEOUT) {
      window.dispatchEvent(INACTIVITY_EVENT);
    } else {
      sessionStorage.setItem('lastRequestTime', currentTime.toString());
    }
  }
  if (marketplaceInStorage?.id) {
    config.headers.set('marketplaceId', marketplaceInStorage.id);
  }
};

const parseToken = (token: string) => {
  const parts = token.split('.');
  return JSON.parse(atob(parts[1]));
};

const isSuperAdmin = (jwtToken: string) => {
  const parsedToken = parseToken(jwtToken);
  return parsedToken?.realm_access?.roles?.includes('default-super-admin-role');
};

const requestHandler = async (config: InternalAxiosRequestConfig) => {
  const { data, baseURL } = config;
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL ?? generateUrl('auth');
  const isImpersonationRequest = baseURL === keycloakUrl;
  const tokenData =
    isImpersonationRequest && (await postData('/customer/impersonation', { userId: data.userId }, 'crm'));
  const token = tokenData.token ?? (await getAccessToken());

  if (token) {
    if (isSuperAdmin(token)) {
      config.headers.marketplaceId = SELECTED_MARKETPLACE?.id ?? '00000001-0000-4000-8000-0000b01dface';
    }
    config.headers.Authorization = `Bearer ${token}`;
  }

  handleMultiTenant(config);

  return config;
};

const axiosConfigs = [
  {
    key: 'feed',
    envVar: process.env.REACT_APP_API_FEED_URL,
    urlType: DYNAMIC_URL_TYPE.API,
    endpoint: 'sportsbook-feed-api',
  },
  {
    key: 'crm',
    envVar: process.env.REACT_APP_API_CRM_URL,
    urlType: DYNAMIC_URL_TYPE.API,
    endpoint: 'sportsbook-crm-api',
  },
  {
    key: 'wallet',
    envVar: process.env.REACT_APP_API_WALLET_URL,
    urlType: DYNAMIC_URL_TYPE.API,
    endpoint: 'sb-wallet-api',
  },
  {
    key: 'kc',
    envVar: process.env.REACT_APP_KEYCLOAK_URL,
    urlType: DYNAMIC_URL_TYPE.AUTH,
    options: { withCredentials: true },
  },
];

const axiosInstances: Record<string, AxiosInstance> = {};

const createAxiosInstances = () => {
  axiosConfigs.forEach(({ key, envVar, urlType, endpoint, options }) => {
    const baseURL = envVar ?? generateUrl(urlType as UrlType, endpoint);
    const instance = axios.create({
      baseURL,
      ...options,
    });

    // Add request interceptor
    instance.interceptors.request.use(requestHandler);

    // Store the instance
    axiosInstances[key] = instance;
  });
};

createAxiosInstances();

type UserCredentials = {
  agentId: string;
  role: UserRole;
};

let cachedAgentCredentials: UserCredentials | null = null;
let agentCredentialsFetched = false;

export const getAgentCredentials = async () => {
  if (cachedAgentCredentials && agentCredentialsFetched) {
    return cachedAgentCredentials;
  }

  const { data } = await axiosInstances.crm.get('/agent/by-user', {
    headers: {
      marketplaceId: '00000001-0000-4000-8000-0000b01dface',
    },
  });

  if (data && data.length > 0) {
    const { agentid: agentId, role } = data[0];

    cachedAgentCredentials = { agentId, role };
    agentCredentialsFetched = true;

    return cachedAgentCredentials;
  } else {
    return null;
  }
};

export type ApiService = keyof typeof axiosInstances;

const executeRequest = async (
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  body: unknown | null,
  apiService: ApiService = 'feed',
  queryParams?: unknown,
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  let updatedHeaders = headers;

  const agentCredentials = await getAgentCredentials();

  if (apiService === 'crm') {
    if (agentCredentials) {
      updatedHeaders = {
        ...headers,
        agentId: agentCredentials.agentId,
        role: agentCredentials.role,
      };
    }
  }

  const requestOptions: AxiosRequestConfig = {
    headers: { ...updatedHeaders },
    params: queryParams,
  };

  if (method === 'get' || method === 'delete') {
    const { data } = await axiosInstances[apiService][method](path, { ...requestOptions, data: body });
    return data;
  } else {
    const { data } = await axiosInstances[apiService][method](path, body, requestOptions);
    return data;
  }
};

export const getData = async (
  path: string,
  queryParams?: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('get', path, null, apiService, queryParams, headers);
};

export const postData = async (
  path: string,
  body: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('post', path, body, apiService, null, headers);
};

export const patchData = async (
  path: string,
  body: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('patch', path, body, apiService, null, headers);
};

export const deleteData = async (
  path: string,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders,
  body?: unknown | null
) => {
  return executeRequest('delete', path, body, apiService, null, headers);
};

export const hasApiMorePages = (count: number, page: number, limit: number) => {
  const pagesCount = Math.ceil(count / limit);
  return page < pagesCount;
};
