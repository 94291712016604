import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import { useModal } from './contexts/ModalContext';
import { Typography } from '@mui/material';
import ConfirmationModal from './components/modals/ConfirmationModal';
import { getItemFromSessionStorage, setItemInSessionStorage } from './utils/storageHelper';
import { MarketplaceProvider } from './contexts/MarketplaceContext';

interface RequireTenantGuardProps {
  children: ReactNode;
}

interface Tenant {
  id: string;
  name: string;
}

const handleConfirmMessage = () => {
  const currentTime = new Date().getTime();
  setItemInSessionStorage('lastRequestTime', currentTime);
};

const RequireTenantGuard: FC<RequireTenantGuardProps> = ({ children }) => {
  const selectedTenant = useRef<Tenant | null>(getItemFromSessionStorage('marketplace'));
  const { openModal } = useModal();

  const handleInactivity = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={`Your selected tenant is ${selectedTenant?.current?.name}, do you want to proceed?`}
        handleConfirm={() => handleConfirmMessage()}
        actionButtonLabel='Ok'
      />
    );
  }, [openModal]);

  useEffect(() => {
    window.addEventListener('inactivityTimeExpired', handleInactivity);
    window.addEventListener('tenantChanged', () => {
      window.location.reload();
    });
    return () => {
      window.removeEventListener('inactivityTimeExpired', handleInactivity);
      window.removeEventListener('tenantChanged', () => {
        window.location.reload();
      });
    };
  }, [handleInactivity]);

  if (!selectedTenant.current) {
    return <Typography variant='h2'>To continue please select Tenant</Typography>;
  }

  return <MarketplaceProvider>{children}</MarketplaceProvider>;
};

export default RequireTenantGuard;
