import React from 'react';
import { getItemFromSessionStorage } from '../../utils/storageHelper';
import { Typography } from '@mui/material';

const SelectedTenantName = () => {
  const selectedTenant = getItemFromSessionStorage('marketplace');
  if (!selectedTenant) {
    return null;
  }
  return (
    <Typography component='span' color='primary' variant='h6'>
      ({selectedTenant.name})
    </Typography>
  );
};

export default SelectedTenantName;
