import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { Status } from '../../../@types/api';
import { STATUS } from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import CountrySelect from '../../molecules/CountrySelect';
import DateTimeSelect from '../../molecules/DateTimeSelect';

type FilterMarketplaceData = {
  status?: Status;
  country?: string;
  fromTimestamp?: dayjs.Dayjs;
  toTimestamp?: dayjs.Dayjs;
};

const defaultValues: FilterMarketplaceData = {
  status: undefined,
  country: undefined,
  fromTimestamp: undefined,
  toTimestamp: undefined,
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterMarketplace = ({ changeQuery, closeModal }: Props) => {
  const { control, handleSubmit, handleResetFilter, setValue } = useFilterForm({
    defaultData: defaultValues,
    changeQuery,
    prepareQueryParams: (data) => ({
      ...data,
      fromTimestamp: data.fromTimestamp && dayjs(data.fromTimestamp).valueOf(),
      toTimestamp: data.toTimestamp && dayjs(data.toTimestamp).valueOf(),
    }),
  });

  return (
    <FilterDialogLayout
      label='marketplace'
      onClose={() => closeModal?.()}
      onSave={handleSubmit}
      onReset={handleResetFilter}
    >
      <FormFieldStack label='Status'>
        <Controller
          name='status'
          control={control}
          render={({ field }) => (
            <Switch
              value={field.value === STATUS.ACTIVE}
              onChange={(e) => setValue('status', e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE)}
              ref={null}
            />
          )}
        />
      </FormFieldStack>

      <CountrySelect name='country' control={control} />

      <DateTimeSelect control={control} name='fromTimestamp' label='Created at - from' />
      <DateTimeSelect control={control} name='toTimestamp' label='Created at - to' />
    </FilterDialogLayout>
  );
};

export default FilterMarketplace;
