import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Box, Divider, Menu } from '@mui/material';
import { StyleObj } from '../../@types';
import { EventList, PublishStatus } from '../../@types/api';
import { CONTROL_OPTIONS, MATCH_STATUS_OPTIONS, PUBLISH_STATUS_BASE_OPTIONS } from '../../constants';
import { AutomaticIcon } from '../icons';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMenuSection from './ManageMenuSection';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

export type HandleMenuItemClickField = 'manualControl' | 'isActive' | 'publishStatus';

type ManageEventMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  handleItemClick: (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => void;
  selectedEvent?: EventList;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      py: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
};

const ManageEventMenu = ({ contextMenu, handleClose, handleItemClick, selectedEvent }: ManageEventMenuProps) => {
  const { marketplaceId } = useAuth();

  const hasManuallyControlledMarkets = !!selectedEvent?.marketManualControlCount;
  const isManuallyCreatedEvent = selectedEvent?.source === marketplaceId;

  const showControlOptions = hasManuallyControlledMarkets && !isManuallyCreatedEvent;

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
      sx={styles.menu}
    >
      <Box sx={styles.menuContent}>
        {showControlOptions && (
          <>
            <ManageMenuSection
              title='Control options'
              options={CONTROL_OPTIONS}
              handleItemClick={(option) => handleItemClick(option, 'manualControl')}
              iconRenderer={() => <AutomaticIcon />}
            />
            <Divider />
          </>
        )}
        <ManageMenuSection
          title='Match status'
          options={MATCH_STATUS_OPTIONS}
          handleItemClick={(option) => handleItemClick(option, 'isActive')}
          iconRenderer={(id) =>
            id === 1 ? (
              <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />
            ) : (
              <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />
            )
          }
        />
        <Divider />
        <ManageMenuSection
          title='Publish status'
          options={PUBLISH_STATUS_BASE_OPTIONS}
          handleItemClick={(option) => handleItemClick(option, 'publishStatus')}
          iconRenderer={(id) => <PublishStatusIcon status={id as PublishStatus} />}
        />
      </Box>
    </Menu>
  );
};
export default ManageEventMenu;
