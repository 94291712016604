import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { Control, Controller, FieldError, FieldValues, Merge, Path, PathValue } from 'react-hook-form';
import { SelectOption, StyleObj } from '../../@types';
import usePermissions from '../../hooks/usePermissions';

export type FormSelectProps<T extends FieldValues> = Omit<SelectProps, 'error'> & {
  label?: string;
  name: Path<T>;
  control: Control<T>;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  options: SelectOption[] | readonly SelectOption[];
  closeOnSelect?: boolean;
  allowDeselect?: boolean;
};

const makeStyles = (disabled?: boolean, error?: boolean) =>
  ({
    menu: {
      maxHeight: 360,
    },
    noDataItem: {
      px: 2,
      py: 1,
    },
  }) satisfies StyleObj;

const FormSelect = <T extends FieldValues>({
  label,
  name,
  control,
  error,
  options,
  closeOnSelect,
  allowDeselect = true,
  ...rest
}: FormSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const styles = makeStyles(rest.disabled, !!error);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { hasPermission } = usePermissions();
  const updatedOptions = options.filter(
    (option) => !option.permission || (option.permission && hasPermission(option.permission))
  );

  // Prevents out-of-range console warning when changing parent select
  const isValidOptionValue = (value: PathValue<T, (string | undefined) & Path<T>>) => {
    if (Array.isArray(value)) return true;

    return updatedOptions.some((option) => option.id === value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderOptions = (onChange: (...event: any[]) => void) => {
    if (isEmpty(updatedOptions)) {
      return <MenuItem disabled>No {label?.toLowerCase()} available</MenuItem>;
    }

    return updatedOptions.map((option) => (
      <MenuItem
        key={option.id ?? option.name}
        value={option.id ?? option.name}
        onClick={(e) => {
          // If the select is not multiple, and the selected option is the same as the current value, set value to null
          if (allowDeselect && (e.target as HTMLOptionElement).selected === true) {
            onChange(null);
          }
        }}
        disabled={option.disabled}
      >
        {option.name}
      </MenuItem>
    ));
  };

  return (
    <FormControl required={rest.required} fullWidth error={!!error}>
      {label && <InputLabel id={`select-${name}-label`}>{label}</InputLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            labelId={`select-${name}-label`}
            id={`select-${name}`}
            label={label}
            onChange={(e) => {
              onChange(e);
              if (closeOnSelect) {
                handleClose();
              }
            }}
            value={isValidOptionValue(value) ? value : ''}
            error={!!error}
            MenuProps={{
              sx: styles.menu,
            }}
            {...rest}
          >
            {renderOptions(onChange)}
          </Select>
        )}
      />
      <FormHelperText>{Array.isArray(error) ? error[0]?.message : error?.message}</FormHelperText>
    </FormControl>
  );
};

export default FormSelect;
