import { TabsProps, Tabs, TabProps, Tab, styled, Box } from '@mui/material';
import { StyleObj } from '../../@types';
import { omit } from 'lodash-es';

const styles: StyleObj = {
  container: {
    borderBottom: 1,
    borderColor: 'divider',
    marginBottom: {
      xs: 2,
      md: 3,
    },
  },
};

export const StyledTabs = styled((props: TabsProps & { indicatorWidth?: number }) => (
  <Box sx={styles.container}>
    <Tabs
      {...omit(props, 'indicatorWidth')}
      TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
    />
  </Box>
))(({ theme, indicatorWidth }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 3,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: indicatorWidth ?? '100%',
    borderRadius: '100px 100px 0 0',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)({
  fontWeight: 600,
  textTransform: 'none',
  whiteSpace: 'nowrap',
});
