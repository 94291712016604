import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type Props = {
  options: {
    name: string;
    id: number;
  }[];
  value: number | false;
  handleRefetchIntervalChange: (e: SelectChangeEvent<number | false>) => void;
};

const SelectRefreshInterval = ({ options, value, handleRefetchIntervalChange }: Props) => {
  return (
    <FormControl sx={(theme) => ({ width: 130, [theme.breakpoints.down('sm')]: { width: '100%' } })}>
      <InputLabel id='refetch-interval-select-label'>Refresh Interval</InputLabel>
      <Select
        value={value}
        label='Refresh Interval'
        onChange={handleRefetchIntervalChange}
        labelId='refetch-interval-select-label'
        id='refetch-interval-select'
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectRefreshInterval;
