export const getDirtyFieldValues = <
  TData extends Record<keyof TDirtyItems, unknown>,
  TDirtyItems extends Record<string, unknown>,
>(
  data: TData,
  dirtyItems: TDirtyItems
): Partial<TData> => {
  return Object.entries(dirtyItems).reduce((dirtyData, [fieldName, value]) => {
    if (typeof value !== 'object') {
      return { ...dirtyData, [fieldName]: data[fieldName] };
    }

    // Handle nested objects
    return {
      ...dirtyData,
      [fieldName]: getDirtyFieldValues(data[fieldName] as TData, dirtyItems[fieldName] as TDirtyItems),
    };
  }, {});
};
