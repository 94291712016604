import { Stack } from '@mui/material';
import { QUERY_KEYS } from '../../../constants';
import LiabilityAssignTable from '../../organisms/LiabilityAssignTable';
import RiskFactorDefaultsTable from '../../organisms/RiskFactorDefaultsTable';
import RiskManagementTable from '../../organisms/RiskManagementTable';
import { StyleObj } from '../../../@types';

const styles: StyleObj = {
  container: {
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
    mb: {
      xs: 4,
      md: 0,
    },
  },
  sideContainer: {
    width: {
      xs: '100%',
      md: '50%',
    },
  },
};

const RiskSettingsPage = () => {
  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.sideContainer} direction='column' spacing={2}>
        <RiskFactorDefaultsTable productType='pre-match' />
        <RiskFactorDefaultsTable productType='in-play' />
      </Stack>
      <Stack sx={styles.sideContainer} direction='column' spacing={2}>
        <RiskManagementTable
          path='risk-management/liabilities'
          queryKey={[QUERY_KEYS.liabilities]}
          tableHeader='Liability'
        />
        <LiabilityAssignTable />
        <RiskManagementTable
          path='risk-management/risk-factors'
          queryKey={[QUERY_KEYS.riskFactors]}
          tableHeader='Risk Factors'
        />
      </Stack>
    </Stack>
  );
};

export default RiskSettingsPage;
