import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { StyleObj } from '../../@types';

interface Props {
  text: string;
}

const styles: StyleObj = {
  typography: {
    fontSize: '30px',
    textAlign: 'center',
    marginTop: '40px',
  },
};
const ErrorPage: FC<Props> = ({ text }) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Typography sx={styles.typography}>{text}</Typography>
    </Box>
  );
};

export default ErrorPage;
