import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { DialogType, StyleObj } from '../../../@types';
import { PublishStatus } from '../../../@types/api';
import {
  CHANGED_STATUS_OPTIONS,
  EVENT_SOURCE_OPTIONS,
  PUBLISH_STATUS_BASE_OPTIONS,
  QUERY_KEYS,
  SWITCH_CONTROL_OPTIONS,
} from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  fromTimestamp: dayjs().valueOf(),
  toTimestamp: dayjs().add(3, 'month').valueOf(),
  sportIds: [],
  competitionIds: [],
  changed: undefined,
  publishStatuses: [],
  source: null,
  manualControl: undefined,
};

export type FilterOutrightsData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  sportIds: string[];
  competitionIds: string[];
  changed?: boolean;
  publishStatuses: PublishStatus[];
  source: 'manual' | 'odds_matrix_feed' | null;
  manualControl?: boolean;
};

const styles: StyleObj = {
  dateSelectContainer: { display: 'flex', flexDirection: 'column', gap: 2 },
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterOutrights = ({ changeQuery, closeModal }: Props) => {
  const { control, handleSubmit, handleResetFilter, watch, setValue } = useFilterForm<FilterOutrightsData>({
    defaultData: defaultValues,
    changeQuery,
    prepareQueryParams: (data) => prepareData(data),
  });

  const sportIds = watch('sportIds');
  const competitionIds = watch('competitionIds');
  const fromTimestamp = watch('fromTimestamp');
  const toTimestamp = watch('toTimestamp');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportIds') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds]);

  const prepareData = (data: Partial<FilterOutrightsData>) => {
    const preparedData = { ...data };

    if (data.fromTimestamp) {
      preparedData.fromTimestamp = dayjs(data.fromTimestamp).unix() * 1000;
    }
    if (data.toTimestamp) {
      preparedData.toTimestamp = dayjs(data.toTimestamp).endOf('day').unix() * 1000;
    }
    if (data.changed !== undefined) {
      preparedData.changed = Boolean(data.changed);
    }
    if (data.manualControl !== undefined) {
      preparedData.manualControl = Boolean(data.manualControl);
    }

    return preparedData;
  };

  return (
    <FilterDialogLayout
      label='outrights'
      onClose={() => closeModal?.()}
      onSave={handleSubmit}
      onReset={handleResetFilter}
    >
      <Box sx={styles.dateSelectContainer}>
        <DateTimeSelect control={control} name='fromTimestamp' label='From' maxDateTime={dayjs(toTimestamp)} />
        <DateTimeSelect control={control} name='toTimestamp' label='To' minDateTime={dayjs(fromTimestamp)} />
      </Box>
      <SportSelect label='Sports' control={control} name='sportIds' multiple closeMenuOnSelect />
      <FormAutocomplete
        name='competitionIds'
        control={control}
        label='Competitions'
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportIds, competitionIds]}
        queryParams={{
          sportIds,
          isActive: true,
          competitionIds,
        }}
        disabled={isEmpty(sportIds)}
        hookEnabled={!!sportIds}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
        multiple
      />
      <FormSelect
        control={control}
        name='publishStatuses'
        label='Publish status'
        options={PUBLISH_STATUS_BASE_OPTIONS}
        multiple
        closeOnSelect
      />
      <FormSelect control={control} name='changed' label='Changed' options={CHANGED_STATUS_OPTIONS} />
      <FormSelect control={control} name='source' label='Source' options={EVENT_SOURCE_OPTIONS} />
      <FormSelect control={control} name='manualControl' label='Mode' options={SWITCH_CONTROL_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterOutrights;
