import { EditOutlined, Visibility } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  MuiEvent,
  type GridColDef,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { Event, EventList } from '../../@types/api';
import { LS_KEYS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { getLatestValue } from '../../helpers';
import { defaultColumnsEvents, getDefaultColumnsLiveEvents } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import usePersist from '../../hooks/usePersist';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import Switch from '../atoms/Switch';
import TableIconContainer from '../atoms/TableIconContainer';
import { FeaturedIcon } from '../icons';
import FormModal from '../modals/FormModal';
import ManageEventMenu, { HandleMenuItemClickField, HandleMenuItemClickOption } from '../molecules/ManageEventMenu';
import EventMarkets from '../organisms/EventMarkets';
import { FilterEventsData } from '../organisms/forms/FilterEvents';
import TableTemplate from '../templates/TableTemplate';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { createCashOutColumn } from '../../helpers/columns';
import usePermissions from '../../hooks/usePermissions';
import MobileDialogWrapper from '../organisms/MobileDialogWrapper';
import EventNameCell from '../molecules/EventNameCell';

const makeStyles = (selectedRow: string | null): StyleObj => ({
  container: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    gap: '1.5rem',
    position: 'relative',
    width: '100%',
  },
  displayNone: {
    display: 'none',
  },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
});

const EventsPage = () => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    eventId: string;
  } | null>(null);

  const styles = makeStyles(selectedRow);

  const { pathname } = useLocation();
  const { getPersistData } = usePersist(false);

  const {
    data: eventsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<EventList>('events', {
    page: 1,
    limit: 25,
    fromTimestamp: dayjs().unix() * 1000,
    toTimestamp: dayjs().add(14, 'day').unix() * 1000,
    isLive: false,
  });

  const { updateData } = useMutateData('events', [QUERY_KEYS.events]);
  const { createData: createEventChanges } = useMutateData('event-changes', [QUERY_KEYS.events]);
  const { createData: createEventChangesBulk } = useMutateData('event-changes/bulk', [QUERY_KEYS.events]);

  const { handleSort } = useSort(changeQuery);
  const { openModal } = useModal();
  const invalidateData = useInvalidateQuery();
  const { hasPermission } = usePermissions();
  const showCashout = hasPermission('manageCashOut');

  // on eventsData change, check if selectedRow is still in the list
  // if not, hide the markets table
  useEffect(() => {
    if (!selectedRow) return;

    const event = eventsData?.items.find((event) => event.id === selectedRow);
    if (!event) {
      setSelectedRow(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  const openEditDialog = (row: Event) => {
    openModal(<FormModal form='editEvent' />, row);
  };

  const columns: GridColDef[] = [
    createColumn('sportIcon', 'Icon', {
      sortable: false,
      renderCell: (params) => (
        <TableIconContainer>
          <IconRenderer name={params.row.tournament.sport.icon} type='sport' />
        </TableIconContainer>
      ),
    }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<EventList>) => params.row.tournament.sport.name,
    }),
    createColumn('competitionName', 'Competition', {
      valueGetter: (params: GridValueGetterParams<EventList>) => params.row.tournament.competition.name,
    }),
    createColumn('tournamentName', 'Tournament', {
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<EventList>) => params.row.tournament.name,
    }),
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('name', 'Match', {
      align: 'left',
      flex: 2,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<EventList>) => {
        return <EventNameCell row={params.row} />;
      },
    }),
    createColumn('startDate', 'Date', {
      valueGetter: (params: GridValueGetterParams<EventList>) =>
        dayjs(getLatestValue(params.row, 'startDate')).format('ddd. DD-MM'),
    }),
    createColumn('startTime', 'Time', {
      sortable: false,
      valueGetter: (params: GridValueGetterParams<EventList>) =>
        dayjs(getLatestValue(params.row, 'startDate')).format('HH:mm'),
    }),
    createColumn('isActive', 'Status', {
      renderCell: (params) => (
        <Switch
          value={params.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            createEventChanges({
              eventId: params.row.id,
              isActive: e.target.checked,
            });
          }}
          name='status'
        />
      ),
      valueGetter: (params: GridValueGetterParams<EventList>) => {
        return getLatestValue(params.row, 'isActive');
      },
    }),
    createColumn('featured', 'Featured', {
      renderCell: (params) => (
        <TableIconContainer>{getLatestValue(params.row, 'featured') && <FeaturedIcon />}</TableIconContainer>
      ),
    }),
    createColumn('information', 'Information', {
      sortable: false,
      renderCell: (params) => {
        const info = getLatestValue(params.row, 'information');
        return (
          info && (
            <TableIconContainer>
              <Tooltip title={info}>
                <InfoIcon color='primary' />
              </Tooltip>
            </TableIconContainer>
          )
        );
      },
    }),
    createColumn('actions', 'Actions', {
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => openEditDialog(params.row)} color='primary'>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={() => handleEventDetails(params.row)} color='primary'>
            <Visibility />
          </IconButton>
        </>
      ),
    }),
  ];
  showCashout && columns.splice(-1, 0, createCashOutColumn<EventList>({ updateData, modalEntity: 'event' }));

  const filterData = getPersistData<FilterEventsData>(`${LS_KEYS.appFormFilter}-${pathname}`);
  const isLive = filterData?.isLive === 1;

  const hideMarketsTable = () => {
    setSelectedRow(null);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleEventDetails = (row: Event) => {
    setSelectedRow(row.id);
    setSelectedRows([row.id]);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (
      params.field !== 'actions' &&
      params.field !== 'isActive' &&
      params.field !== '__check__' &&
      params.field !== 'eligibleForCashout'
    ) {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, eventId: params.id.toString() }
          : null
      );
      if (!selectedRows.includes(params.id as string)) {
        setSelectedRows([params.id as string]);
      }
    }
  };

  const handlePostUpdateInvalidate = () => {
    invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets]);
  };

  const handleMenuItemClick = (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => {
    if (field === 'publishStatus') {
      updateData(
        'bulk',
        {
          eventIds: selectedRows,
          [field]: typeof option.id === 'number' ? !!option.id : option.id,
        },
        handlePostUpdateInvalidate
      );
    } else {
      createEventChangesBulk(
        {
          eventIds: selectedRows,
          [field]: typeof option.id === 'number' ? !!option.id : option.id,
        },
        handlePostUpdateInvalidate
      );
    }

    handleClose();
  };

  const onRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as string[]);
  };

  const getColumns = () => {
    return columns.filter((column) => !(isLive && column.field === 'featured'));
  };

  return (
    <>
      <Box sx={styles.container}>
        <TableTemplate
          rows={eventsData?.items || []}
          columns={getColumns()}
          rowCount={eventsData?.count ?? 0}
          loading={isFetching || isLoading}
          defaultVisibleColumns={isLive ? getDefaultColumnsLiveEvents() : defaultColumnsEvents}
          handlePaginationModelChange={updateQueryParams}
          handleSort={handleSort}
          handleSearch={handleSearch}
          checkboxSelection
          onCellClick={onCellClick}
          createItemBtnLabel='Add new event'
          createItemBtnStyle={isLive ? styles.displayNone : styles.createItemBtn}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={selectedRows}
          changeQuery={changeQuery}
          formType='createEvent'
        />
        {selectedRow && (
          <MobileDialogWrapper>
            <EventMarkets
              eventId={selectedRow}
              tournament={eventsData?.items.find((event) => event.id === selectedRow)?.tournament}
              handleClose={hideMarketsTable}
            />
          </MobileDialogWrapper>
        )}
      </Box>
      <ManageEventMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        handleItemClick={handleMenuItemClick}
        selectedEvent={eventsData?.items.find((event) => event.id === contextMenu?.eventId)}
      />
    </>
  );
};

export default EventsPage;
