import { isEmpty, isEqual } from 'lodash-es';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useCallback, useEffect, useRef, useState } from 'react';
import { WithId } from '../@types';
import { socket } from '../constants/socket';
import Log from '../utils/logger';
import { BACKOFFICE_WS_ROOM_ID } from '../constants';

export type MessageType<T> = {
  id: string;
  event: string;
  payload: WithId<T>;
};

export type WebsocketData<T> = Record<string, MessageType<T>>;
type JoinRoom = (roomId: string) => void;
type LeaveRoom = (roomId: string) => void;
type SendMessage = (event: string, data: unknown) => void;

/**
 * The useWebsocket function sets up a WebSocket connection and provides functions for sending messages, joining and leaving rooms, and resetting the WebSocket connection.
 *
 * @param {Object} options - The options for configuring the WebSocket connection.
 * @param {number} options.delay - The delay (in milliseconds) before sending cached messages.
 * @param {boolean} options.debug - Whether to enable debug logging.
 * @param {function} options.callback - The callback function to be called with the cached messages.
 * @returns {Object} An object containing the following functions:
 *   - sendMessage: A function for sending messages through the WebSocket connection.
 *   - joinRoom: A function for joining a room.
 *   - leaveRoom: A function for leaving a room.
 *   - resetWS: A function for resetting the WebSocket connection.
 */
const useWebsocket = <Raw>({
  delay = 250,
  debug = false,
  skipCache = false,
  callback,
}: {
  delay?: number;
  debug?: boolean;
  skipCache?: boolean;
  callback: (data: WebsocketData<Raw>) => void;
}): {
  sendMessage: SendMessage;
  joinRoom: JoinRoom;
  leaveRoom: LeaveRoom;
  resetWS: () => void;
  isConnected: boolean;
} => {
  const { userId } = useAuth();
  const cache = useRef<WebsocketData<Raw>>({});
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isConnected, setIsConnected] = useState(socket.connected);

  const sendMessage: SendMessage = useCallback((event, data) => socket.emit(event, data), []);
  const joinRoom: JoinRoom = useCallback((roomId) => {
    socket.emit('joinRoom', roomId);
    cache.current[roomId] = {} as MessageType<Raw>;
  }, []);
  const leaveRoom: LeaveRoom = useCallback((roomId) => {
    socket.emit('leaveRoom', roomId);

    if (cache.current[roomId]) {
      // remove stale cache
      delete cache.current?.[roomId];
    }
  }, []);

  const resetWS = useCallback(() => {
    timer.current && clearTimeout(timer.current as ReturnType<typeof setTimeout>);
    Object.keys(cache.current).forEach((roomId: string) => {
      if (roomId.includes(BACKOFFICE_WS_ROOM_ID)) return;

      leaveRoom(roomId);
    });
    cache.current = {};
  }, [leaveRoom]);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      debug && Log('[WS]: Connected');
    };

    const onDisconnect = () => {
      setIsConnected(false);
      debug && Log('[WS]: Disconnected');
    };

    const onConnectError = (err: Error) => {
      debug && Log('[WS] Connection Error: ', err, 'error');
    };

    const onMessage = (event: string, message: MessageType<Raw>) => {
      if (!event || isEmpty(message) || !message?.id || !message?.event || !message?.payload) {
        return;
      }

      if (!cache.current) {
        Log('[WS]: Cache map ref is not initialized.', 'error');
        return;
      }

      if (skipCache) {
        callback?.({ [message.payload.id]: message });
        return;
      }

      if (!isEqual(cache.current[message.id], message)) {
        cache.current[message.id] = message;
        if (!timer.current) {
          timer.current = setTimeout(() => {
            callback?.(cache.current);
            timer.current = null;
          }, delay);
        }
      }
    };

    socket.on('connect', onConnect);
    socket.on('connect_error', onConnectError);
    socket.on('disconnect', onDisconnect);
    socket.onAny(onMessage);

    return () => {
      socket.off('connect', onConnect);
      socket.off('connect_error', onConnectError);
      socket.off('disconnect', onDisconnect);
      socket.offAny(onMessage);
      resetWS();
    };
  }, [debug, delay, skipCache, callback, resetWS]);

  useEffect(() => {
    debug && Log('[WS Cache]', cache.current);
    // eslint-disable-next-line
  }, [debug, JSON.stringify(cache.current)]);

  useEffect(() => {
    if (!socket.connected) {
      socket.io.opts.query = { userId };
      socket.connect();
    }
  }, [userId]);

  return {
    sendMessage,
    joinRoom,
    leaveRoom,
    resetWS,
    isConnected,
  };
};

export default useWebsocket;
