import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../../@types';
import usePermissions from '../../hooks/usePermissions';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import RequireTenantGuard from '../../RequireTenantGuard';
import { USER_ROLES } from '../../constants/keycloak';
import { MarketplaceProvider } from '../../contexts/MarketplaceContext';

type Props = {
  permission: Permission;
  requireTenant?: boolean;
};

const ProtectedPage = ({ permission, requireTenant }: Props) => {
  const hideBonusPage = useFeatureIsOn('hide-bonus-page');
  const { userId } = useAuth();
  const { hasPermission, role } = usePermissions();

  const isHiddenPage = hideBonusPage && (permission === 'manageBonuses' || permission === 'viewBonusReports');

  if (!userId || !hasPermission(permission) || isHiddenPage) {
    return <Navigate to='/' />;
  }

  if (role === USER_ROLES.SUPER_ADMIN && requireTenant) {
    return (
      <RequireTenantGuard>
        <Outlet />
      </RequireTenantGuard>
    );
  }

  return (
    <MarketplaceProvider>
      <Outlet />
    </MarketplaceProvider>
  );
};

export default ProtectedPage;
