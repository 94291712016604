import { capitalize } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Jackpot } from '../../@types/api';
import { CURRENCY, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { createDescriptionColumn, createEditColumn, createIsActiveColumn, HasIsActive } from '../../helpers/columns';
import { defaultColumnsJackpots } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import TableIconContainer from '../atoms/TableIconContainer';
import ConfirmationModal from '../modals/ConfirmationModal';
import TableTemplate from '../templates/TableTemplate';
import { MESSAGES } from '../../constants/messages';

const JackpotPage = () => {
  const {
    data: jackpotData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Jackpot>('jackpots');

  const { handleSort } = useSort(changeQuery);
  const { updateData } = useMutateData('jackpots', [QUERY_KEYS.jackpots]);
  const { openModal, closeModal } = useModal();

  const handleIsActiveChange = (id: string, data: Partial<HasIsActive>) => {
    // `data.isActive` corresponds to `e.target.checked` from the Switch component.
    // It represents the new state the user is trying to set.
    const confirmMessage = data.isActive ? MESSAGES.ACTIVATE_JACKPOT : MESSAGES.DEACTIVATE_JACKPOT;

    openModal(
      <ConfirmationModal
        title={confirmMessage}
        actionButtonLabel='Confirm'
        handleConfirm={() => updateData(id, data)}
        closeModal={closeModal}
        actionButtonClose='Cancel'
      />
    );
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', { sortable: false }),
    createColumn('icon', 'Icon', {
      sortable: false,
      renderCell: (params: GridRenderCellParams<Jackpot>) => {
        return (
          <TableIconContainer>
            <IconRenderer name={params.row.icon} type='jackpot' />
          </TableIconContainer>
        );
      },
    }),
    createColumn('type', 'Type', {
      alwaysVisible: true,
      valueGetter: (params: GridValueGetterParams<Jackpot>) => capitalize(params.row.type),
    }),
    createColumn('name', 'Name', {
      minWidth: 150,
      alwaysVisible: true,
    }),
    createColumn('product', 'Product', {
      alwaysVisible: true,
      sortable: false,
    }),
    createIsActiveColumn({
      alwaysVisible: true,
      updateData: handleIsActiveChange,
    }),
    createColumn('winningAmount', 'Jackpot winning amount', {
      minWidth: 175,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<Jackpot>) =>
        `${CURRENCY.symbol} ${params.row.winningLow} - ${params.row.winningHigh}`,
    }),
    createColumn('stakeRanges', 'Ticket pay in amount', {
      minWidth: 175,
      sortable: false,
      flex: 2,
      valueGetter: (params: GridValueGetterParams<Jackpot>) =>
        params.row.stakeRanges.map((stakeRange) => stakeRange.name).join(', '),
    }),
    createColumn('winningTickets', 'Winning tickets', {
      minWidth: 175,
      sortable: false,
      flex: 2,
      valueGetter: (params: GridValueGetterParams<Jackpot>) => params.row.winningTickets.filter(Boolean).join(', '),
    }),
    createDescriptionColumn(),
    createEditColumn<Jackpot>(),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={jackpotData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={jackpotData?.count || 0}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      defaultVisibleColumns={defaultColumnsJackpots}
      handlePaginationModelChange={updateQueryParams}
      createItemBtnLabel='Add new jackpot pool'
      formType='jackpot'
    />
  );
};

export default JackpotPage;
