import { useEffect, useState } from 'react';
import { Studio, defineConfig } from 'sanity';
import { structureTool } from 'sanity/structure';
import Log from '../../utils/logger';
import { useSanityStructure } from '../../config/sanity/schemaTypes/useSanityStructure';
import { getSanityConfig } from '../../utils/envDynamicValues';
import { useMarketplaceConfig } from '../../contexts/MarketplaceContext';

const CMSStudioPage = () => {
  const { getStructure, schemaTypes, ready } = useSanityStructure();
  const { config: marketplaceConfig } = useMarketplaceConfig();
  const sanityConfig = marketplaceConfig?.sanityConfig ?? getSanityConfig();

  const [config, setConfig] = useState(
    defineConfig({
      name: 'cms',
      title: 'Content Management',
      projectId: process.env.REACT_APP_SANITY_PROJECT_ID ?? sanityConfig?.projectId,
      theme: {},
      dataset: process.env.REACT_APP_SANITY_DATASET ?? sanityConfig?.dataset,
      root: false,
      basePath: '/cms',
      plugins: [structureTool({ structure: getStructure })],
      schema: {
        types: schemaTypes,
      },
    })
  );

  useEffect(() => {
    const importTheme = async () => {
      try {
        const { theme } = await import(
          // The webpackIgnore tells webpack to not attempt bundling this dynamic import,
          // and instead let it run natively in the browser at runtime
          /* webpackIgnore: true */
          process.env.REACT_APP_SANITY_THEME_URL ?? sanityConfig?.themeUrl
        );
        setConfig((config) => ({ ...config, theme }));
      } catch (error) {
        Log('Failed to load theme:', error);
      }
    };
    importTheme();
  }, []);

  return <>{ready && <Studio config={config} scheme='light' />}</>;
};

export default CMSStudioPage;
