export type SessionStorageValue = string | number | boolean | object | null;

export const setItemInSessionStorage = (key: string, value: SessionStorageValue) => {
  const stringValue = JSON.stringify(value);
  sessionStorage.setItem(key, stringValue);
};

export const getItemFromSessionStorage = (key?: string) => {
  const stringValue = sessionStorage.getItem(key ?? '');
  if (stringValue) {
    return JSON.parse(stringValue);
  }
  return null;
};
