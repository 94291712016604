import { DefaultPaletteOptions, createTheme } from '@mui/material/styles';
import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const TYPOGRAPHY: TypographyOptions & { body3?: TypographyStyleOptions } = {
  fontFamily: 'Open Sans, sans-serif',
  h1: {
    fontSize: 32,
    fontWeight: 500,
    '@media (max-width: 600px)': {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  h2: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '36px',
    '@media (max-width: 600px)': {
      fontSize: 22,
      lineHeight: '28px',
    },
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    '@media (max-width: 600px)': {
      fontSize: 20,
      lineHeight: '28px',
    },
  },
  h4: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '28px',
    '@media (max-width: 600px)': {
      fontSize: 18,
      lineHeight: '24px',
    },
  },
  h5: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16.8px',
    '@media (max-width: 600px)': {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
  h6: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '@media (max-width: 600px)': {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
  subtitle1: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.05em',
    '@media (max-width: 600px)': {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  body3: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    '@media (max-width: 600px)': {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
} as const;

const PALETTE: DefaultPaletteOptions = {
  primary: {
    main: '#14805C',
    dark: '#1F4537',
    container: '#E7F0E7',
    lightBorder: '#00533733',
    contrastText: '#fff',
  },
  secondary: {
    light: '#F7F2FA',
    main: '#F3EDF7',
  },
  grey: {
    50: '#F0F0F0',
    100: '#E1E1E1',
    200: '#D7D7D7',
    300: '#888888',
    400: '#707070',
    500: '#79747E',
    600: '#4C4C4C',
    700: '#383838',
    800: '#2F2F2F',
    900: '#181818',
  },
  background: {
    lighter: '#F1FAF1',
    light: '#F7FAF7',
    lightGreen: '#D9E7D9',
    lightBlue: '#F2F7FF',
    contrastText: '#000000DE',
  },
  selected: {
    yellowLight: '#FFFCE4',
    blue: '#2B7FFE',
  },
} as const;

const theme = createTheme({
  typography: TYPOGRAPHY,
  palette: PALETTE,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            display: 'block',
            '@media (max-width: 600px)': {
              display: 'none',
            },
            backgroundColor: 'transparent',
            width: 14,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: PALETTE.grey?.[200],
            backgroundClip: 'padding-box',
            border: '4px solid transparent',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: PALETTE.grey?.[300],
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: PALETTE.grey?.[300],
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: PALETTE.grey?.[300],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          height: 40,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '20px',
          padding: '10px 24px',
          textTransform: 'none',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
          '@media (max-width: 600px)': {
            height: 32,
            fontSize: 12,
            padding: '8px 16px',
          },
        },
      },
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: PALETTE.background?.light,
          color: '#49454F',
          padding: '12px 16px',
          boxShadow: '0px 2px 6px 2px #00000026',
          ...TYPOGRAPHY.h6,
        },
        arrow: {
          color: PALETTE.background?.light,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '@media (max-width: 600px)': {
              fontSize: '0.75rem',
            },
          },
        },
        input: {
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '@media (max-width: 600px)': {
            padding: '10px 12px',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          '@media (max-width: 600px)': {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: PALETTE.primary.main,
          '& .MuiTableCell-root': {
            ...TYPOGRAPHY.h5,
            color: PALETTE.primary.contrastText,
            '@media (max-width: 600px)': {
              padding: 12,
              ...TYPOGRAPHY.h5,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          ...TYPOGRAPHY.body3,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
          '@media (max-width: 600px)': {
            fontSize: '0.75rem',
          },
          '&.MuiMenuItem-root': {
            '@media (max-width: 600px)': {
              minHeight: 36,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          '@media (max-width: 1200px)': {
            overflow: 'auto',
          },
        },
        root: {
          '& .MuiTab-root': {
            '@media (max-width: 1200px)': {
              width: 'auto',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            '@media (max-width: 600px)': {
              fontSize: '0.75rem',
            },
          },
          '& .MuiInputLabel-root': {
            '@media (max-width: 600px)': {
              top: -6,
            },
          },
          '& .MuiInputLabel-shrink': {
            '@media (max-width: 600px)': {
              top: 2,
            },
          },
          '& .MuiInputBase-inputMultiline': {
            '@media (max-width: 600px)': {
              padding: 0,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            '@media (max-width: 600px)': {
              minHeight: 36,
            },
          },
        },
      },
    },
  },
});

export default theme;
