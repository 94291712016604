import { Dialog, styled } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
    minWidth: 416,
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
      borderRadius: theme.spacing(2),
    },
  },
  '& .MuiDialogActions-root': {
    paddingRight: theme.spacing(2),
  },
  // Workaround for https://github.com/mui/material-ui/issues/29892
  '& .MuiDialogContent-root': {
    paddingTop: `${theme.spacing(1)} !important`,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

export default StyledDialog;
