import { Replay } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import type { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DateRangeOption, DialogType } from '../../@types';
import { dayjs } from '../../config/dayjs';
import { LS_KEYS, PREDEFINED_DATE_RANGE_OPTIONS, SPORTSBOOK_WALLET_TYPE } from '../../constants';
import { generateDateRangeTimestamps } from '../../helpers';
import { AdditionalQueryParams } from '../../hooks/usePagination';
import usePersist from '../../hooks/usePersist';

const defaultValues = {
  fromTimestamp: dayjs().startOf('day').valueOf(),
  toTimestamp: dayjs().endOf('day').valueOf(),
  walletType: 'main',
  timestamp: 'today',
};

type FilterDailyReportsData = {
  fromTimestamp: Dayjs | number;
  toTimestamp: Dayjs | number;
  walletType?: string;
  timestamp?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const styles = {
  datePickersWrapper: {
    display: 'flex',
    width: '320px',
    justifyContent: 'space-between',
  },
  datePicker: {
    width: '155px',
  },
};

const FilterDailyReports = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const savedData = getPersistData<FilterDailyReportsData>(formDataKey);
  const [dateRange, setDateRange] = useState({
    fromTimestamp: dayjs(savedData?.fromTimestamp) || defaultValues.fromTimestamp,
    toTimestamp: dayjs(savedData?.toTimestamp) || defaultValues.toTimestamp,
  });
  const [timestampSelect, setTimestampSelect] = useState(savedData?.timestamp || defaultValues.timestamp);
  const [walletTypeSelect, setWalletTypeSelect] = useState(savedData?.walletType || defaultValues.walletType);

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const onFormSubmit = () => {
    const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps(timestampSelect as DateRangeOption);
    const formattedTimestamp = {
      fromTimestamp:
        timestampSelect === 'custom'
          ? dayjs(dateRange.fromTimestamp).startOf('day').valueOf()
          : dayjs(fromTimestamp).startOf('day').valueOf(),
      toTimestamp:
        timestampSelect === 'custom'
          ? dayjs(dateRange.toTimestamp).endOf('day').valueOf()
          : dayjs(toTimestamp).endOf('day').valueOf(),
    };

    const data = {
      ...formattedTimestamp,
      walletType: walletTypeSelect,
    };

    setPersistData<FilterDailyReportsData>(formDataKey, { ...data, timestamp: timestampSelect });
    changeQuery(data);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant='h4' gutterBottom>
        Filter
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Select value={timestampSelect} onChange={(e) => setTimestampSelect(e.target.value)}>
            {PREDEFINED_DATE_RANGE_OPTIONS.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <Box sx={styles.datePickersWrapper}>
            <Box sx={styles.datePicker}>
              <DateTimePicker
                label='From'
                sx={styles.datePicker}
                value={dateRange.fromTimestamp}
                disabled={timestampSelect !== 'custom'}
                onChange={(e) => setDateRange((prev) => ({ ...prev, fromTimestamp: e || dayjs() }))}
                views={['year', 'month', 'day']}
              />
            </Box>
            <Box sx={styles.datePicker}>
              <DateTimePicker
                label='To'
                sx={styles.datePicker}
                value={dateRange.toTimestamp}
                disabled={timestampSelect !== 'custom'}
                onChange={(e) => setDateRange((prev) => ({ ...prev, toTimestamp: e || dayjs() }))}
                views={['year', 'month', 'day']}
              />
            </Box>
          </Box>
          <Select value={walletTypeSelect} onChange={(e) => setWalletTypeSelect(e.target.value)}>
            {SPORTSBOOK_WALLET_TYPE.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={handleFilterReset} startIcon={<Replay />} size='small'>
          Reset filters
        </Button>
        <Button variant='outlined' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='contained' onClick={onFormSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterDailyReports;
