import { useMemo } from 'react';
import { DefaultValues, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { LS_KEYS } from '../constants';
import usePersist from './usePersist';
import { useModal } from '../contexts/ModalContext';
import { AdditionalQueryParams } from './usePagination';

type UseFilterFormProps<T extends FieldValues> = {
  defaultData: DefaultValues<T>;
  changeQuery: (data: AdditionalQueryParams) => void;
  prepareQueryParams?: (data: Partial<T>) => AdditionalQueryParams;
  onSubmit?: SubmitHandler<T>;
};

const useFilterForm = <T extends FieldValues>({
  defaultData,
  changeQuery,
  prepareQueryParams,
  onSubmit,
}: UseFilterFormProps<T>) => {
  const { closeModal } = useModal();
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const persistedFormData = useMemo(() => {
    const persistedData = getPersistData<DefaultValues<T>>(formDataKey);

    return persistedData ?? defaultData;
  }, [formDataKey, getPersistData, defaultData]);

  const { control, handleSubmit, ...rest } = useForm<T>({ defaultValues: persistedFormData });

  const handleFormSubmit = (data: T) => {
    setPersistData<T>(formDataKey, data);
    changeQuery(prepareQueryParams ? prepareQueryParams(data) : data);
    onSubmit?.(data);
    closeModal?.();
  };

  const handleResetFilter = () => {
    deletePersistData(formDataKey);
    changeQuery(defaultData);
    closeModal?.();
  };

  return { control, handleSubmit: handleSubmit(handleFormSubmit), handleResetFilter, ...rest };
};

export default useFilterForm;
