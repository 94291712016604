import { Container } from '@mui/material';
import { StyleObj } from '../../@types';
import { generateUrl } from '../../utils/envDynamicValues';

export const styles: StyleObj = {
  iframeWrapper: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: {
      lg: 1199, // if width is 1200px or more, sidebar will be opened
    },
    padding: {
      lg: 0,
    },
  },
};

const keycloakIframeStyle = {
  width: '100%',
  height: 'calc(100vh - 180px)',
  marginTop: '-100px',
  border: 'none',
  '& #page-sidebar': {
    display: 'none',
  },
};

type UserManagementPageLayoutProps = {
  title: string;
  src: string;
};

const UserManagementPageLayout = ({ title, src }: UserManagementPageLayoutProps) => {
  return (
    <Container sx={styles.iframeWrapper}>
      <iframe
        src={`${process.env.REACT_APP_KEYCLOAK_URL ?? generateUrl('auth')}${src}`}
        style={keycloakIframeStyle}
        title={title}
      ></iframe>
    </Container>
  );
};

export default UserManagementPageLayout;
