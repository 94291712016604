import { SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef, GridPreProcessEditCellProps, GridValueGetterParams } from '@mui/x-data-grid';
import {
  DefaultRiskFactor,
  MarketRisk,
  RiskFactor,
  RiskManagementEntity,
  RiskManagementEntityType,
} from '../@types/api';
import IconRenderer from '../components/atoms/IconRenderer';
import TableIconContainer from '../components/atoms/TableIconContainer';
import SelectCell from '../components/molecules/SelectCell';
import { QUERY_KEYS } from '../constants';
import {
  defaultColumnsMarketsRiskManagement,
  defaultColumnsPlayersRiskManagement,
  defaultColumnsSportsRiskManagement,
  defaultColumnsTournamentsRiskManagement,
} from '../helpers/table';
import { createColumn } from '../utils';
import { getMarketTypeName } from '../helpers';

export const PATH_TO_ENTITY_TYPE_SINGULAR_MAP: Record<RiskManagementEntityType, string> = {
  sports: 'sport',
  tournaments: 'tournament',
  'market-types': 'market_type',
  punters: 'punter',
};

const preProcessEditCellProps = (params: GridPreProcessEditCellProps) => {
  const hasError = isNaN(Number(params.props.value));
  return { ...params.props, error: hasError };
};

const createCommonColumns = (
  riskFactors: RiskFactor[],
  type: string,
  updateRiskData: (id: string, data: unknown, onSuccess?: () => void) => void,
  refetch: () => void,
  defaultRiskFactor?: DefaultRiskFactor
) => {
  return {
    id: createColumn('id', 'ID', { sortable: false }),
    riskFactorId: createColumn('riskFactorId', 'RF Factor ID', {
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        const { riskFactor } = params.row;
        const factor = riskFactor || defaultRiskFactor;
        return `${factor?.name}:${factor?.value}`;
      },
      sortable: false,
      alwaysVisible: true,
      renderCell: (params) => (
        <SelectCell
          defaultSelectValue={params.row.riskFactor?.id || defaultRiskFactor?.id || ''}
          options={riskFactors.map((item) => ({
            ...item,
            name: `${item.name}:${item.value}`,
          }))}
          onChange={(value: string | number) => {
            updateRiskData(
              `${PATH_TO_ENTITY_TYPE_SINGULAR_MAP[type as RiskManagementEntityType]}/${params.row.id}`,
              {
                riskFactorId: value,
              },
              () => refetch()
            );
          }}
        />
      ),
    }),
    riskFactorName: createColumn('riskFactorName', 'RF Name', {
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.riskFactor?.name || defaultRiskFactor?.name;
      },
    }),
    firskFactorValue: createColumn('riskFactorValue', 'RF Value', {
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.riskFactor?.value || defaultRiskFactor?.value;
      },
    }),
    minTrigger: createColumn('minTrigger', 'Min. Verification trigger', {
      alwaysVisible: true,
      editable: true,
      type: 'number',
      preProcessEditCellProps,
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.minTrigger || defaultRiskFactor?.minTrigger;
      },
    }),
    maxTrigger: createColumn('maxTrigger', 'Max. Verification trigger', {
      alwaysVisible: true,
      editable: true,
      type: 'number',
      preProcessEditCellProps,
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.maxTrigger || defaultRiskFactor?.maxTrigger;
      },
    }),
    liabilityId: createColumn('liabilityLimitId', 'Liability Limit ID', {
      sortable: false,
      alwaysVisible: true,
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.liability?.id;
      },
    }),
    liabilityName: createColumn('liabilityName', 'Liability Limit Name', {
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.liability?.name;
      },
    }),
    liabilityValue: createColumn('liabilityValue', 'Liability Limit Value', {
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        return params.row.liability?.value;
      },
    }),
    liabilityCalculation: createColumn('liabilityCalculation', 'Liability Calculation', {
      sortable: false,
      valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
        const multiplier = params.row.riskFactor?.value ? params.row.riskFactor?.value : defaultRiskFactor?.value;
        return multiplier ? multiplier * params.row.liability?.value : '';
      },
      renderCell: (params) => (
        <>
          {(params.row.riskFactor?.value ? params.row.riskFactor?.value : defaultRiskFactor?.value) *
            params.row.liability?.value || ''}
        </>
      ),
    }),
    defaultRiskFactor: createColumn('defaultRiskFactor', 'Default Risk Factor', {
      sortable: false,
      valueGetter: (_params: GridValueGetterParams<RiskManagementEntity>) => {
        return `${defaultRiskFactor?.name}:${defaultRiskFactor?.value}`;
      },
    }),
    defaultMinTrigger: createColumn('defaultMinTrigger', 'Default Min. Trigger', {
      sortable: false,
      valueGetter: (_params: GridValueGetterParams<RiskManagementEntity>) => {
        return defaultRiskFactor?.minTrigger;
      },
    }),
    defaultMaxTrigger: createColumn('defaultMaxTrigger', 'Default Max. Trigger', {
      sortable: false,
      valueGetter: (_params: GridValueGetterParams<RiskManagementEntity>) => {
        return defaultRiskFactor?.maxTrigger;
      },
    }),
  };
};

export const getRiskManagementColumns = (
  type: RiskManagementEntityType,
  riskFactors: RiskFactor[],
  refetch: () => void,
  updateRiskData: (id: string, data: unknown) => void,
  openSettingsDialog: (params: RiskManagementEntity) => void,
  defaultRiskFactor?: DefaultRiskFactor
) => {
  const commonColumns = createCommonColumns(riskFactors, type, updateRiskData, refetch, defaultRiskFactor);

  if (type === 'sports') {
    const sportColumns: GridColDef[] = [
      commonColumns.id,
      createColumn('icon', 'Icon', {
        alwaysVisible: true,
        sortable: false,
        renderCell: (params) => {
          return (
            <TableIconContainer>
              <IconRenderer name={params.value} type='sport' />
            </TableIconContainer>
          );
        },
      }),
      createColumn('name', 'Sport Name', {
        alwaysVisible: true,
      }),
      commonColumns.riskFactorId,
      commonColumns.riskFactorName,
      commonColumns.firskFactorValue,
      commonColumns.minTrigger,
      commonColumns.maxTrigger,
      commonColumns.liabilityName,
      commonColumns.liabilityValue,
      commonColumns.liabilityCalculation,
      commonColumns.defaultRiskFactor,
      commonColumns.defaultMinTrigger,
      commonColumns.defaultMaxTrigger,
    ];
    return { columns: sportColumns, defaultColumns: defaultColumnsSportsRiskManagement };
  } else if (type === 'tournaments') {
    const tournamentsColumns: GridColDef[] = [
      commonColumns.id,
      createColumn('icon', 'Icon', {
        alwaysVisible: true,
        sortable: true,
        renderCell: (params) => {
          return (
            <TableIconContainer>
              <IconRenderer name={params.row.sport?.icon} type='sport' />
            </TableIconContainer>
          );
        },
      }),
      createColumn('sportName', 'Sport Name', {
        valueGetter: ({ row }: GridValueGetterParams<RiskManagementEntity>) => {
          return 'sport' in row ? row.sport?.name : '';
        },
      }),
      createColumn('competitionId', 'Competition ID', {
        sortable: false,
        valueGetter: ({ row }: GridValueGetterParams<RiskManagementEntity>) => {
          return 'competition' in row ? row.competition?.id : '';
        },
      }),
      createColumn('competitionName', 'Competition Name', {
        sortable: false,
        alwaysVisible: true,
        minWidth: 150,
        valueGetter: ({ row }: GridValueGetterParams<RiskManagementEntity>) => {
          return 'competition' in row ? row.competition?.name : '';
        },
      }),
      createColumn('tournamentId', 'Tournament ID', {
        sortable: false,
        valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
          return params.row.id;
        },
      }),
      createColumn('tournamentName', 'Tournament Name', {
        alwaysVisible: true,
        sortable: false,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
          return params.row.name;
        },
      }),
      commonColumns.riskFactorId,
      commonColumns.riskFactorName,
      commonColumns.firskFactorValue,
      commonColumns.minTrigger,
      commonColumns.maxTrigger,
      commonColumns.liabilityId,
      createColumn('liabilityName', 'Liability Limit Name', {
        valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
          return params.row.liability?.name;
        },
      }),
      createColumn('liabilityValue', 'Liability Limit Value', {
        valueGetter: (params: GridValueGetterParams<RiskManagementEntity>) => {
          return params.row.liability?.value;
        },
      }),
      commonColumns.liabilityCalculation,
      commonColumns.defaultRiskFactor,
      commonColumns.defaultMinTrigger,
      commonColumns.defaultMaxTrigger,
    ];
    return { columns: tournamentsColumns, defaultColumns: defaultColumnsTournamentsRiskManagement };
  } else if (type === 'market-types') {
    const marketsColumns: GridColDef[] = [
      createColumn('sportId', 'ID', { sortable: false }),
      createColumn('icon', 'Icon', {
        alwaysVisible: true,
        sortable: true,
        renderCell: (params) => {
          return (
            <TableIconContainer>
              <IconRenderer name={params.row.sport?.icon} type='sport' />
            </TableIconContainer>
          );
        },
      }),
      createColumn('name', 'Sport Name', {
        alwaysVisible: true,
        valueGetter: (params: GridValueGetterParams<MarketRisk>) => {
          return params.row.sport?.name;
        },
      }),
      createColumn('id', 'Market ID', { sortable: false }),
      createColumn('marketName', 'Market Name', {
        sortable: false,
        alwaysVisible: true,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams<MarketRisk>) => getMarketTypeName(params.row),
      }),
      commonColumns.riskFactorId,
      commonColumns.riskFactorName,
      commonColumns.firskFactorValue,
      commonColumns.liabilityId,
      commonColumns.liabilityName,
      commonColumns.liabilityValue,
      commonColumns.liabilityCalculation,
      commonColumns.defaultRiskFactor,
      commonColumns.minTrigger,
      commonColumns.maxTrigger,
    ];
    return { columns: marketsColumns, defaultColumns: defaultColumnsMarketsRiskManagement };
  } else {
    const playersColumns: GridColDef[] = [
      commonColumns.id,
      createColumn('fullName', 'Full Name'),
      createColumn('username', 'Username', { alwaysVisible: true, minWidth: 150 }),
      commonColumns.riskFactorId,
      commonColumns.riskFactorName,
      commonColumns.firskFactorValue,
      commonColumns.minTrigger,
      commonColumns.maxTrigger,
      commonColumns.liabilityId,
      commonColumns.liabilityName,
      commonColumns.liabilityValue,
      commonColumns.liabilityCalculation,
      commonColumns.defaultRiskFactor,
      commonColumns.defaultMinTrigger,
      commonColumns.defaultMaxTrigger,
      createColumn('settings', 'Settings', {
        alwaysVisible: true,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => openSettingsDialog(params.row)} color='primary'>
            <SettingsOutlined />
          </IconButton>
        ),
      }),
    ];
    return { columns: playersColumns, defaultColumns: defaultColumnsPlayersRiskManagement };
  }
};

export const getQueryKey = (type?: string) => {
  switch (type) {
    case 'sports':
      return QUERY_KEYS.riskSports;
    case 'tournaments':
      return QUERY_KEYS.riskTournaments;
    case 'market-types':
      return QUERY_KEYS.riskMarkets;
    default:
      return QUERY_KEYS.riskSports;
  }
};
