import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { Marketplace } from '../../@types/api';
import { ODDS_FORMAT_OPTIONS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import useMutateData from '../../hooks/useMutateData';
import { SportsbookConfigFormData, sportsbookConfigSchema } from '../../schema';
import FormFieldStack from '../atoms/FormFieldStack';
import Switch from '../atoms/Switch';
import FormSelect from '../molecules/FormSelect';

const styles: StyleObj = {
  content: {
    px: 0,
  },
  actions: {
    '&.MuiDialogActions-root.MuiDialogActions-root': {
      px: 0,
      pb: 0,
    },
  },
};

const DEFAULT_FORM_DATA: SportsbookConfigFormData = {
  oddsFormat: 'DECIMAL',
  AdditionalFeatures: {
    BonusesEngine: false,
    JackpotEngine: false,
  },
  AddOns: {},
};

const MarketplaceSportsbookConfigTab = () => {
  const { item, closeModal } = useModal<Marketplace>();
  const { updateData } = useMutateData('bo/marketplace', [QUERY_KEYS.marketplace], 'crm');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SportsbookConfigFormData>({
    resolver: zodResolver(sportsbookConfigSchema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    if (item?.sportsbookConfig) {
      reset(item.sportsbookConfig);
    }
  }, [item, reset]);

  const handleClose = () => {
    reset(item?.sportsbookConfig || DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: SportsbookConfigFormData) => {
    if (item) {
      updateData(item.id, { sportsbookConfig: data }, handleClose);
    }
  };

  return (
    <Fragment>
      <DialogContent sx={styles.content}>
        <Stack spacing={2}>
          <FormSelect
            label='Odds Format'
            name='oddsFormat'
            control={control}
            error={errors.oddsFormat}
            options={ODDS_FORMAT_OPTIONS}
            required
          />

          <FormFieldStack label='Bonuses Engine'>
            <Controller
              name='AdditionalFeatures.BonusesEngine'
              control={control}
              render={({ field }) => <Switch {...field} ref={null} />}
            />
          </FormFieldStack>
          <FormFieldStack label='Jackpot Engine'>
            <Controller
              name='AdditionalFeatures.JackpotEngine'
              control={control}
              render={({ field }) => <Switch {...field} ref={null} />}
            />
          </FormFieldStack>

          <Typography variant='subtitle1' sx={{ color: 'text.disabled' }}>
            Add-Ons
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default MarketplaceSportsbookConfigTab;
