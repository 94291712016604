import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, styled } from '@mui/material';
import { ElementType } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { CASINO_PATH, NavigationObjectType } from '../../config';

type NavigationItemProps = {
  item: NavigationObjectType;
  open: boolean;
  isSmDown: boolean;
  handleDrawerClose: () => void;
};

type WithOpenProp = {
  open: boolean;
};

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})<WithOpenProp>(({ theme, open }) => {
  return {
    marginRight: open ? '0.25rem' : '0',
    minWidth: 0,
    justifyContent: 'center',
    color: theme.palette.primary.main,
  };
});

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'open',
})<WithOpenProp>(({ open }) => {
  return {
    opacity: open ? 1 : 0,
  };
});

type StyledListItemButtonProps = {
  open: boolean;
  component: ElementType;
  to: string;
  route: string;
  pathname: string;
};

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'pathname' && prop !== 'route',
})<StyledListItemButtonProps>(({ open }) => {
  return {
    justifyContent: open ? 'initial' : 'center',
    marginLeft: '0.75rem',
    marginRight: '0.75rem',
    borderRadius: 4,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  };
});

const styles: StyleObj = {
  listItem: {
    display: 'block',
  },
  listItemBtn: { display: 'flex', justifyContent: 'flex-start' },
  listSubItemBtn: {
    py: 0.5,
    mr: 1.5,
    borderRadius: 1,
  },
};

const NavigationItem = ({ item, open, isSmDown, handleDrawerClose }: NavigationItemProps) => {
  const hasChildren = item.children && item.children.length > 0;

  const { pathname } = useLocation();

  const route = item.to.split('/').pop() as string;

  const onClick = () => {
    if (item.to === CASINO_PATH || isSmDown) {
      handleDrawerClose();
    }
  };
  return (
    <>
      <ListItem disablePadding sx={styles.listItem}>
        <StyledListItemButton
          selected={!hasChildren && pathname.includes(item.to)}
          component={Link}
          to={item.to}
          open={open}
          pathname={pathname}
          route={route}
          sx={styles.listItemBtn}
          onClick={onClick}
        >
          <StyledListItemIcon open={open}>
            {open ? (
              <item.icon />
            ) : (
              <Tooltip title={item.name} placement='right' arrow>
                <item.icon />
              </Tooltip>
            )}
          </StyledListItemIcon>
          {open && (
            <StyledListItemText primaryTypographyProps={{ variant: 'h5' }} open={open}>
              {item.name}
            </StyledListItemText>
          )}
        </StyledListItemButton>
      </ListItem>
      <List disablePadding>
        {item.children?.map((child) => {
          return (
            <ListItem disablePadding key={child.name}>
              <ListItemButton
                selected={pathname.includes(item.to + child.to)}
                sx={{ ...styles.listSubItemBtn, ml: open ? 3 : 1.5 }}
                component={Link}
                to={item.to + child.to}
                onClick={onClick}
              >
                <StyledListItemIcon open={open}>
                  {open ? (
                    <child.icon />
                  ) : (
                    <Tooltip title={`${item.name} / ${child.name}`} placement='right' arrow>
                      <child.icon />
                    </Tooltip>
                  )}
                </StyledListItemIcon>
                {open && (
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h6',
                    }}
                  >
                    {child.name}
                  </ListItemText>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default NavigationItem;
