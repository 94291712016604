import { Dialog, useMediaQuery, useTheme } from '@mui/material';

interface Props {
  children: React.ReactElement;
  minWidth?: string;
}

const MobileDialogWrapper: React.FC<Props> = ({ children, minWidth = '100%' }) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  if (isMdDown) {
    return (
      <Dialog
        open
        fullScreen
        sx={{
          '> div > div > div': {
            minWidth: `${minWidth} !important`,
            maxWidth: '900px',
            margin: 0,
          },
        }}
      >
        {children}
      </Dialog>
    );
  }
  return <>{children}</>;
};

export default MobileDialogWrapper;
