import { Add } from '@mui/icons-material';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { StyleObj } from '../../../@types';
import { CRMApiEntityType } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { useModal } from '../../../contexts/ModalContext';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useMutateData from '../../../hooks/useMutateData';
import usePermissions from '../../../hooks/usePermissions';
import { useCRMUserQuery } from '../../../queries';
import Switch from '../../atoms/Switch';
import FormModal from '../../modals/FormModal';
import AssignParent from '../../molecules/AssignParent';
import UserBalanceDisplay from '../../molecules/UserBalanceDisplay';
import CRMUserDetailCard from './CRMUserDetailCard';
import { USER_TYPE_TO_CRM_API_ENTITY_MAP } from '../../../config/crm';
import { USER_ROLES } from '../../../constants/keycloak';

const styles: StyleObj = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1,
  },
  agent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    mt: 2,
  },
};

type Props = {
  userType: CRMApiEntityType;
};

const CRMDetailsTab = ({ userType }: Props) => {
  const { selectedCRMUser } = useCRMUser();
  const { openModal } = useModal();

  const selectedCRMUserId = selectedCRMUser?.id;

  const { data: userData, isLoading } = useCRMUserQuery(userType, selectedCRMUserId);

  const apiEntity = USER_TYPE_TO_CRM_API_ENTITY_MAP[userType];

  const { updateData } = useMutateData(apiEntity, [QUERY_KEYS.crmUsers, selectedCRMUserId], 'crm');
  const invalidateData = useInvalidateQuery();
  const { hasPermission } = usePermissions();

  const isCustomerUserType = userType === 'customer';
  const isRestrictedSelectedUserRole =
    userData?.role?.name === USER_ROLES.TRADER || userData?.role?.name === USER_ROLES.TECH_SUPPORT;

  const currentAgent = userData?.agent;
  const currentManager = userData?.teamLead;

  const parentType = isCustomerUserType ? 'agent' : 'manager';
  const currentParent = isCustomerUserType ? currentAgent : currentManager;

  const userDetailCards = useMemo(() => {
    if (userData) {
      return [
        {
          Type: isCustomerUserType ? 'Player' : userData?.role?.name,
          'Creation date': dayjs(userData.createdAt).format('DD.MM.YYYY.'),
        },
        {
          Email: userData.email,
          'Phone number': userData.phone,
          Language: userData.language,
          'Date of birth': userData.dateOfBirth && dayjs(userData.dateOfBirth).format('DD.MM.YYYY.'),
        },
      ];
    }
  }, [isCustomerUserType, userData]);

  const updateCustomerStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedCRMUserId &&
      updateData(
        selectedCRMUserId,
        { isActive: e.target.checked },
        () => {
          invalidateData([`${apiEntity}/search`]);
        },
        'Status updated successfully'
      );
  };

  const hasAdminCredentials = hasPermission('defaultAdminRole');

  if (isLoading) return <LinearProgress />;

  if (!userData || !selectedCRMUserId) return null;

  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.agent}>
          <Typography variant='subtitle1'>{userData.username}</Typography>
          {hasAdminCredentials && !isRestrictedSelectedUserRole ? (
            <AssignParent userId={selectedCRMUserId} parentType={parentType} currentParent={currentParent} />
          ) : (
            <Typography variant='h6' mt={0.5}>
              {userData.agent?.fullName}
            </Typography>
          )}
        </Box>
        <Switch onChange={updateCustomerStatus} value={userData.isActive} />
      </Box>
      {!isRestrictedSelectedUserRole && (
        <>
          <Button
            startIcon={<Add />}
            onClick={() =>
              openModal(
                <FormModal
                  form='handle-credits'
                  item={{
                    userId: selectedCRMUser.userId,
                    userType,
                  }}
                />
              )
            }
            sx={(theme) => ({ mb: 2, [theme.breakpoints.down('sm')]: { width: '100%' } })}
          >
            Add/Remove Credit
          </Button>
          <UserBalanceDisplay userType={userType} userId={selectedCRMUser.userId} variant='small' />
        </>
      )}

      <Box sx={styles.cardsContainer}>
        {userDetailCards?.map((card, index) => <CRMUserDetailCard details={card} key={`${userData.id}-${index}`} />)}
      </Box>
    </>
  );
};

export default CRMDetailsTab;
