import {
  AssessmentRounded,
  AssignmentIndRounded,
  AssignmentRounded,
  CallOutlined,
  Casino,
  EmailOutlined,
  Flare,
  ManageAccountsRounded,
  MonetizationOn,
  NoteAlt,
  PersonOutlined,
  Settings,
  SportsVolleyball,
  Stars,
  Store,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

import { EditTabRecord, Permission } from '../@types';
import ConditionsTab from '../components/organisms/ConditionsTab';
import DisplayTypeTab from '../components/organisms/DisplayTypeTab';
import MatchCombiningTab from '../components/organisms/MatchCombiningTab';
import SportEventPartsTab from '../components/organisms/SportEventPartsTab';
import MarketCombiningTab from '../components/organisms/MarketCombiningTab';
import MarketplaceConfigTab from '../components/organisms/MarketplaceConfigTab';
import MarketplaceSportsbookConfigTab from '../components/organisms/MarketplaceSportsbookConfigTab';

export const CASINO_PATH = '/casino';

export type NavigationObjectType = {
  name: string;
  icon: React.ComponentType<SvgIconProps>;
  to: string;
  children?: NavigationObjectType[];
  permission?: Permission;
};

export type Config = {
  Products: NavigationObjectType[];
  Features: NavigationObjectType[];
  Systems: NavigationObjectType[];
  Reports: NavigationObjectType[];
  Settings: NavigationObjectType[];
};

export const NAVIGATION_ITEMS_LIST: Config = {
  Products: [
    {
      name: 'Sports Betting',
      icon: SportsVolleyball,
      to: '/sports-betting',
      permission: 'accessSportsBetting',
      children: [
        {
          name: 'Settings',
          icon: Settings,
          to: '/settings',
          permission: 'manageBettingSettings',
        },
        {
          name: 'Offer management',
          icon: NoteAlt,
          to: '/offer-management',
          permission: 'manageOffer',
        },
      ],
    },
    {
      name: 'Casino',
      icon: Casino,
      to: CASINO_PATH,
      permission: 'manageCasino',
    },
  ],
  Features: [
    {
      name: 'Bonus',
      icon: Stars,
      to: '/bonus',
      permission: 'manageBonuses',
    },
    {
      name: 'Jackpot',
      icon: Flare,
      to: '/jackpot',
      permission: 'manageJackpots',
    },
    {
      name: 'Cash out',
      icon: MonetizationOn,
      to: '/cash-out',
      permission: 'manageCashOut',
    },
  ],
  Systems: [
    {
      name: 'CMS',
      icon: AssignmentRounded,
      to: '/cms',
      permission: 'accessCMS',
    },
    {
      name: 'CRM',
      icon: AssignmentIndRounded,
      to: '/crm',
      permission: 'accessCRM',
    },
    {
      name: 'User Management',
      icon: ManageAccountsRounded,
      to: '/user-management',
      permission: 'accessUserManagement',
    },
  ],
  Reports: [
    {
      name: 'Product reports',
      icon: AssessmentRounded,
      to: '/product-reports',
      permission: 'accessProductReports',
    },
  ],
  Settings: [
    {
      name: 'Marketplace',
      icon: Store,
      to: '/marketplace',
      permission: 'accessMarketplace',
    },
  ],
};

const EDIT_TAB_CONFIG: Record<string, EditTabRecord> = {
  conditions: {
    label: 'Conditions',
    component: ConditionsTab,
    permission: 'accessConditions',
  },
  displayType: {
    label: 'Display type',
    component: DisplayTypeTab,
  },
  matchCombining: {
    label: 'Match combining',
    component: MatchCombiningTab,
  },
  eventParts: {
    label: 'Event parts',
    component: SportEventPartsTab,
  },
  marketCombining: {
    label: 'Combining',
    component: MarketCombiningTab,
    permission: 'accessMarketCombining',
  },
  marketplaceConfig: {
    label: 'Marketplace',
    component: MarketplaceConfigTab,
  },
  marketplaceSportsbook: {
    label: 'Sportsbook',
    component: MarketplaceSportsbookConfigTab,
  },
};

export const ENTITY_EDIT_TABS_MAP = {
  sports: [EDIT_TAB_CONFIG.conditions, EDIT_TAB_CONFIG.eventParts],
  competitions: [EDIT_TAB_CONFIG.conditions],
  tournaments: [EDIT_TAB_CONFIG.conditions],
  participants: [],
  players: [],
  markets: [EDIT_TAB_CONFIG.conditions, EDIT_TAB_CONFIG.marketCombining],
  outcomes: [],
  events: [EDIT_TAB_CONFIG.matchCombining, EDIT_TAB_CONFIG.conditions],
  outrights: [EDIT_TAB_CONFIG.matchCombining, EDIT_TAB_CONFIG.conditions],
  liveEvents: [],
  punters: [EDIT_TAB_CONFIG.conditions],
  marketplace: [EDIT_TAB_CONFIG.marketplaceConfig, EDIT_TAB_CONFIG.marketplaceSportsbook],
};

export type EntityEditTabType = keyof typeof ENTITY_EDIT_TABS_MAP;

export const CRM_TASK_TYPE_CONFIG = {
  CALL: {
    color: '#55CDA9',
    Icon: CallOutlined,
  },
  MESSAGE: {
    color: '#5BACE7',
    Icon: EmailOutlined,
  },
  APPOINTMENT: {
    color: '#F7B64E',
    Icon: PersonOutlined,
  },
} as const;
