import { useState } from 'react';
import { Menu as TenantMenu, MenuItem as TenantMenuItem, IconButton, MenuList, Skeleton } from '@mui/material';
import { AccountBalance } from '@mui/icons-material';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useModal } from '../../contexts/ModalContext';
import { getData } from '../../utils/api';
import { StyleObj } from '../../@types';
import { getItemFromSessionStorage, setItemInSessionStorage } from '../../utils/storageHelper';
import { TENANT_CHANGED_EVENT } from '../../constants/events';
import { Marketplace } from '../../@types/api';

const styles: StyleObj = {
  menuList: {
    maxHeight: 300,
    overflow: 'auto',
  },
};

const SelectTenant = () => {
  const [tenantMenuAnchorEl, setTenantMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openTenantMenu, setOpenTenantMenu] = useState(false);
  const { openModal } = useModal();

  const [availableTenants, setAvailableTenants] = useState<Marketplace[] | undefined>(undefined);

  const fetchTenants = async () => {
    const response = await getData('/bo/marketplace', { limit: 100 }, 'crm');
    setAvailableTenants(response.items);
  };

  const handleTenantMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTenantMenuAnchorEl(event.currentTarget);
    setOpenTenantMenu(true);
    fetchTenants();
  };

  const handleConfirmSelection = (tenant: Marketplace) => {
    setItemInSessionStorage('marketplace', tenant);
    window.dispatchEvent(TENANT_CHANGED_EVENT);
  };

  const handleTenantMenuClose = () => {
    setTenantMenuAnchorEl(null);
    setOpenTenantMenu(false);
  };

  const handleSelectTenant = (tenant: Marketplace) => {
    openModal(
      <ConfirmationModal
        title={`Are you sure you want to switch to Tenant ${tenant.name}?`}
        handleConfirm={() => handleConfirmSelection(tenant)}
        actionButtonLabel='Yes'
        actionButtonClose='No'
      />
    );
    handleTenantMenuClose();
  };

  const selectedTenant = getItemFromSessionStorage('marketplace');

  return (
    <>
      <IconButton
        size='large'
        aria-controls={openTenantMenu ? 'tenant-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openTenantMenu ? 'true' : undefined}
        color='inherit'
        aria-label='tenants'
        onClick={handleTenantMenuOpen}
      >
        <AccountBalance color='primary' />
      </IconButton>
      <TenantMenu
        anchorEl={tenantMenuAnchorEl}
        open={openTenantMenu}
        onClose={handleTenantMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -10, horizontal: 'center' }}
        elevation={3}
        MenuListProps={{
          disablePadding: true,
          sx: styles.menuList,
        }}
      >
        <MenuList>
          {!availableTenants
            ? Array.from(new Array(3)).map((_, index) => (
                <TenantMenuItem key={index}>
                  <Skeleton variant='rectangular' width={100} height={20} />
                </TenantMenuItem>
              ))
            : availableTenants?.map((tenant) => (
                <TenantMenuItem
                  selected={tenant.id === selectedTenant?.id}
                  key={tenant.id}
                  onClick={() => handleSelectTenant(tenant)}
                >
                  {tenant.name}
                </TenantMenuItem>
              ))}
        </MenuList>
      </TenantMenu>
    </>
  );
};

export default SelectTenant;
