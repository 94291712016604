import { Box, Checkbox, Stack, TableCell, TableRow } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { Fragment, createRef, useEffect, useMemo, useState } from 'react';
import { StyleObj } from '../../@types';
import { EventBase, Market } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getLatestValue, getOutcomeCellColor } from '../../helpers';
import { mapSpecialValuesToHomeTeam } from '../../helpers/specialValues';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import MarketTableCell from '../atoms/MarketTableCell';
import Switch from '../atoms/Switch';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageOutcomeMenu from './ManageOutcomeMenu';
import ManualIcon from '../icons/ManualIcon';

const styles: StyleObj = {
  tableCell: {
    border: '1px solid rgba(0, 83, 55, 0.20)',
    p: 0,
    minWidth: 200,
  },
  actionTableCell: {
    border: '1px solid rgba(0, 83, 55, 0.20)',
    p: 0,
    lineHeight: 0,
  },
  switchWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
};

type OutcomeTableMarketProps = {
  event: EventBase;
  market: Market;
  queryKey: QueryKey;
  isDisplayMultiple?: boolean;
  hasSpecialValueWithPlayerModel?: boolean;
  selectedRows: string[];
  handleSelectRow: (market: Market) => void;
  handleMarketContextMenu: (event: React.MouseEvent, market: Market) => void;
  handleMarketStatusUpdate: (id: string, isActive: boolean) => void;
};

const OutcomeTableMarket = ({
  event,
  market,
  queryKey,
  isDisplayMultiple,
  hasSpecialValueWithPlayerModel,
  selectedRows,
  handleSelectRow,
  handleMarketContextMenu,
  handleMarketStatusUpdate,
}: OutcomeTableMarketProps) => {
  const [selectedOutcomeData, setSelectedOutcomeData] = useState<Record<string, string> | null>(null);
  const [outcomeContextMenu, setOutcomeContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [editableCellRefsState, setEditableCellRefsState] = useState<React.RefObject<HTMLInputElement>[]>([]);

  const specialValues = useMemo(() => mapSpecialValuesToHomeTeam(market), [market]);

  useEffect(() => {
    const specialValueRefs = market.specialValues?.map(() => createRef<HTMLInputElement>());
    const outcomeRefs = market.outcomes?.map(() => createRef<HTMLInputElement>());

    setEditableCellRefsState([...specialValueRefs, ...outcomeRefs]);
  }, [market]);

  const { createDataAsync: createOutcomeChangesData } = useMutateData('outcome-changes', queryKey);
  const { createDataAsync: createSpecialValueChangesData } = useMutateData('special-value-changes', queryKey);

  const invalidateData = useInvalidateQuery();

  const handleSpecialValueEdit = async (specialValueId: string, value: string | number | null) => {
    await createSpecialValueChangesData(
      {
        specialValueId,
        value: value?.toString() || null,
      },
      undefined,
      'Item updated successfully.'
    );
    await invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets]);
  };

  const handleOutcomeEdit = async (outcomeId: string, marketId: string, value: string | number | null) => {
    await createOutcomeChangesData(
      {
        outcomeId,
        marketId,
        odds: value?.toString() || null,
      },
      undefined,
      'Item updated successfully.'
    );
    await invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets]);
  };

  const handleOutcomeContextMenu = (event: React.MouseEvent, outcomeId: string, marketId: string) => {
    event.preventDefault();
    setOutcomeContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });

    setSelectedOutcomeData({ outcomeId, marketId });
  };

  const handleCloseOutcomeMenu = () => {
    setOutcomeContextMenu(null);
  };

  return (
    <Fragment>
      <TableRow key={market.id}>
        {isDisplayMultiple && (
          <>
            <TableCell
              align='center'
              sx={{
                ...styles.actionTableCell,
                px: 0.4,
              }}
              onContextMenu={(e) => handleMarketContextMenu(e, market)}
            >
              <Checkbox onChange={() => handleSelectRow(market)} checked={selectedRows.includes(market.id)} />
            </TableCell>
            <TableCell
              align='center'
              sx={{
                ...styles.actionTableCell,
                px: 1.5,
              }}
              onContextMenu={(e) => handleMarketContextMenu(e, market)}
            >
              <Stack direction='row' alignItems='center' justifyContent='end'>
                {getLatestValue(market, 'manualControl') && <ManualIcon width='16' height='16' viewBox='4 4 16 16' />}
                <PublishStatusIcon status={market.publishStatus} changed={market.changed} />
              </Stack>
            </TableCell>
          </>
        )}
        {specialValues?.map((specialValue, index) => (
          <MarketTableCell
            editable={market.manualControl || event.manualControl}
            key={specialValue.id}
            value={getLatestValue(specialValue, 'value')}
            handleEdit={(value) => handleSpecialValueEdit(specialValue.id, value)}
            onContextMenu={(e) => handleMarketContextMenu(e, market)}
            inputRef={editableCellRefsState[index]}
            nextInputRef={editableCellRefsState[index + 1]}
            bgPrimary
            allowNegativeInput
          />
        ))}
        {isDisplayMultiple && (
          <TableCell align='center' sx={{ ...styles.actionTableCell, px: 1 }}>
            <Box sx={styles.switchWrapper}>
              <Switch
                value={getLatestValue(market, 'isActive')}
                onChange={() => handleMarketStatusUpdate(market.id, getLatestValue(market, 'isActive'))}
                name='status'
              />
            </Box>
          </TableCell>
        )}
        {hasSpecialValueWithPlayerModel && (
          <TableCell align='center' sx={styles.tableCell}>
            {specialValues?.find((specialValue) => specialValue.modelType === 'player')?.model?.name}
          </TableCell>
        )}
        {market.outcomes?.map((outcome, index) => (
          <MarketTableCell
            editable={market.manualControl || event.manualControl}
            key={outcome.id}
            value={getLatestValue(outcome, 'odds')}
            handleEdit={(value) => handleOutcomeEdit(outcome.id, outcome.marketId, value)}
            onContextMenu={(e) => handleOutcomeContextMenu(e, outcome.id, outcome.marketId)}
            color={getOutcomeCellColor(outcome)}
            inputRef={editableCellRefsState[index + specialValues?.length]}
            nextInputRef={editableCellRefsState[index + specialValues?.length + 1]}
            isActive={getLatestValue(outcome, 'isActive')}
          />
        ))}
      </TableRow>
      {selectedOutcomeData && (
        <ManageOutcomeMenu
          contextMenu={outcomeContextMenu}
          handleClose={handleCloseOutcomeMenu}
          queryKey={queryKey}
          selectedOutcomeData={selectedOutcomeData}
        />
      )}
    </Fragment>
  );
};

export default OutcomeTableMarket;
