import { TableCell, TableCellProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import '../../styles/marketTableCell.css';

const styles: StyleObj = {
  cell: {
    minWidth: 200,
    cursor: 'pointer',
    p: 0,
    height: 48,
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 0,
    '&:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
};

const getInputClassNames = (isActive: boolean, bgPrimary: boolean) => {
  const classNames = ['market-table-input'];
  if (!isActive) {
    classNames.push('inactive');
  }
  if (bgPrimary) {
    classNames.push('bg-primary');
  }
  return classNames.join(' ');
};

type MarketTableCellProps = TableCellProps & {
  value: string | null;
  handleEdit: (value: string | null) => Promise<void>;
  editable?: boolean;
  onContextMenu?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  color?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  nextInputRef?: React.RefObject<HTMLInputElement>;
  isActive?: boolean;
  bgPrimary?: boolean;
  allowNegativeInput?: boolean;
  decimalPlaces?: number;
};

const MarketTableCell = ({
  value: initialValue,
  handleEdit,
  editable,
  onContextMenu,
  color,
  inputRef,
  nextInputRef,
  isActive = true, // if not set to true, the input will be greyed out if value is null
  bgPrimary,
  allowNegativeInput = false,
}: MarketTableCellProps) => {
  const [value, setValue] = useState<string | null>(initialValue);
  const [isUpdatingOdds, setIsUpdatingOdds] = useState(false);

  // on initialValue change, update the form value
  useEffect(() => {
    if (Array.isArray(initialValue)) {
      setValue(initialValue.map((v) => v.toString()).join(' '));
    } else {
      setValue(initialValue ?? '');
    }
  }, [initialValue]);

  const onKeyDown = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === '+' || e.key === 'Enter') {
      e.preventDefault();

      setIsUpdatingOdds(true);
      await handleEdit(value);
      nextInputRef?.current?.focus();

      setIsUpdatingOdds(false);
    }
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const patern = allowNegativeInput ? '^-?\\d*(\\.\\d{0,2})?$' : '^\\d*(\\.\\d{0,2})?$';
    const regex = new RegExp(`^${patern}$`);
    if (regex.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  return (
    <TableCell align='center' sx={styles.cell} onContextMenu={onContextMenu}>
      <form onSubmit={(e) => e.preventDefault()} onKeyDown={onKeyDown}>
        <input
          value={value ?? ''}
          onChange={onValueChange}
          ref={inputRef}
          disabled={!editable || isUpdatingOdds}
          inputMode='text'
          className={getInputClassNames(isActive, bgPrimary ?? false)}
          style={{ color, opacity: isUpdatingOdds ? 0.25 : 1 }}
        />
      </form>
    </TableCell>
  );
};
export default MarketTableCell;
