import { DialogType } from '../../../@types';
import { BettingSlipFrom } from '../../../@types/api';
import { CASHOUT_TYPE_OPTIONS, SPORTSBOOK_FROM_OPTIONS, SPORTSBOOK_SOURCE_OPTIONS } from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import CustomerSelect from '../../molecules/CustomerSelect';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';

const DEFAULT_FORM_DATA: CashoutReportsFormData = {
  from: undefined,
  pendingSelections: undefined,
  playerIds: [],
  cashoutType: undefined,
  device: undefined,
};

type CashoutReportsFormData = {
  from?: BettingSlipFrom;
  pendingSelections?: number;
  playerIds?: string[];
  cashoutType?: string;
  device?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterCashoutReports = ({ closeModal, changeQuery }: Props) => {
  const { control, handleSubmit, handleResetFilter } = useFilterForm<CashoutReportsFormData>({
    defaultData: DEFAULT_FORM_DATA,
    changeQuery,
  });

  const handleClose = () => {
    closeModal?.();
  };

  return (
    <FilterDialogLayout label='cashout reports' onClose={handleClose} onSave={handleSubmit} onReset={handleResetFilter}>
      <CustomerSelect control={control} name='playerIds' />
      <FormSelect name='from' control={control} label='From' options={SPORTSBOOK_FROM_OPTIONS} />
      <FormSelect name='device' control={control} label='Source' options={SPORTSBOOK_SOURCE_OPTIONS} />
      <FormNumberInput name='pendingSelections' label='Pending selections' control={control} />
      <FormSelect name='cashoutType' label='CO Type' control={control} options={CASHOUT_TYPE_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterCashoutReports;
