import { DialogType } from '../../../@types';
import { BONUS_PRODUCT_TYPE_OPTIONS, BONUS_TIMEFRAME_OPTIONS } from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import { BonusFilterFormData } from '../../../schema';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';
import ValidityPeriodSelect from '../../molecules/ValidityPeriodSelect';

const DEFAULT_FORM_DATA = {
  product: undefined,
  validityPeriod: undefined,
  schedule: undefined,
  fromTimestamp: undefined,
  toTimestamp: undefined,
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterBonuses = ({ closeModal, changeQuery }: Props) => {
  const { control, handleSubmit, handleResetFilter } = useFilterForm<BonusFilterFormData>({
    defaultData: DEFAULT_FORM_DATA,
    changeQuery,
    prepareQueryParams: (data) => ({
      ...data,
      fromTimestamp: data.fromTimestamp?.valueOf(),
      toTimestamp: data.toTimestamp?.valueOf(),
    }),
  });

  const handleClose = () => {
    closeModal?.();
  };

  return (
    <FilterDialogLayout label='bonuses' onClose={handleClose} onSave={handleSubmit} onReset={handleResetFilter}>
      <FormSelect control={control} name='product' label='Product' options={BONUS_PRODUCT_TYPE_OPTIONS} />
      <ValidityPeriodSelect control={control} />
      <FormSelect control={control} name='schedule' label='Timeframe' options={BONUS_TIMEFRAME_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterBonuses;
