import type { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useMemo } from 'react';
import { dayjs } from '../../config/dayjs';
import { QUERY_KEYS, STATUS } from '../../constants';
import { createEditColumn } from '../../helpers/columns';
import { defaultColumnsMarketplace } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import Switch from '../atoms/Switch';
import TableTemplate from '../templates/TableTemplate';
import { Marketplace } from '../../@types/api';
import { Settings } from '@mui/icons-material';

const MarketplacePage = () => {
  const {
    data: marketplaceData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Marketplace>(
    'bo/marketplace',
    {
      page: 1,
      limit: 25,
    },
    {},
    'crm'
  );
  const { updateData } = useMutateData('bo/marketplace', [QUERY_KEYS.marketplace], 'crm');
  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      createColumn('id', 'ID', {
        sortable: false,
      }),
      createColumn('name', 'Name'),
      createColumn('country', 'Country'),
      createColumn('createdAt', 'Created at', {
        valueGetter: (params: GridValueGetterParams<Marketplace>) =>
          dayjs(params.row.createdAt).format('DD-MM-YYYY HH:mm'),
      }),
      createColumn('contactName', 'Contact name', {
        valueGetter: (params: GridValueGetterParams<Marketplace>) => params.row.contactInformation.contactName,
        sortable: false,
      }),
      createColumn('status', 'Status', {
        renderCell: (params: GridRenderCellParams<Marketplace>) => {
          const value = params.row.status === STATUS.ACTIVE;
          return (
            <Switch
              value={value}
              onChange={(e) => {
                updateData(params.row.id, { status: e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE });
              }}
            />
          );
        },
      }),
      createEditColumn<Marketplace>({ columnName: 'Configure', icon: <Settings /> }),
    ],
    [updateData]
  );

  useEffect(() => {
    window.addEventListener('tenantChanged', () => {
      window.location.reload();
    });
    return () => {
      window.removeEventListener('tenantChanged', () => {
        window.location.reload();
      });
    };
  }, []);

  return (
    <TableTemplate
      rows={marketplaceData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={marketplaceData?.count || 0}
      createItemBtnLabel='Add new marketplace'
      defaultVisibleColumns={defaultColumnsMarketplace}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType='marketplace'
      showExport
    />
  );
};

export default MarketplacePage;
