import { DialogType } from '../../@types';

// FORMS
import CompetitionForm from '../organisms/forms/CompetitionForm';
import CreateEventForm from '../organisms/forms/CreateEventForm';
import EditEventForm from '../organisms/forms/EditEventForm';
import MarketGroupForm from '../organisms/forms/MarketGroupForm';
import MarketTypeForm from '../organisms/forms/MarketTypeForm';
import OutcomeTypeForm from '../organisms/forms/OutcomeTypesForm';
import ParticipantForm from '../organisms/forms/ParticipantForm';
import PlayerForm from '../organisms/forms/PlayerForm';
import ResultsForm from '../organisms/forms/ResultsForm';
import RiskManagementPlayerSettingsDialog from '../organisms/forms/RiskManagementPlayerSettingsDialog';
import SportsForm from '../organisms/forms/SportsForm';
import TournamentForm from '../organisms/forms/TournamentForm';
import ConditionGroupForm from '../organisms/forms/conditions/ConditionGroupForm';
import EditConditionTypeForm from '../organisms/forms/conditions/EditConditionTypeForm';
import CRMTaskForm from '../organisms/forms/crm/CRMTaskForm';
import CustomerForm from '../organisms/forms/crm/CustomerForm';
import AddAdminCreditsForm from '../organisms/forms/crm/wallet/AddAdminCreditsForm';
import FilterDailyReports from '../organisms/FilterDailyReports';
import HandleCreditsForm from '../organisms/forms/crm/wallet/HandleCreditsForm';
import JackpotForm from '../organisms/forms/JackpotForm';
import CreateOutrightForm from '../organisms/forms/CreateOutrightForm';
import EditOutrightForm from '../organisms/forms/EditOutrightForm';
import BonusForm from '../organisms/forms/BonusForm';
import MarketplaceForm from '../organisms/forms/MarketplaceForm';
import PartnerForm from '../organisms/forms/crm/PartnerForm';

const forms = {
  sports: SportsForm,
  competitions: CompetitionForm,
  tournaments: TournamentForm,
  participants: ParticipantForm,
  players: PlayerForm,
  markets: MarketTypeForm,
  outcomes: OutcomeTypeForm,
  customers: CustomerForm,
  tasks: CRMTaskForm,
  editEvent: EditEventForm,
  createEvent: CreateEventForm,
  createOutright: CreateOutrightForm,
  editOutright: EditOutrightForm,
  results: ResultsForm,
  'conditions-type': EditConditionTypeForm,
  'conditions-groups': ConditionGroupForm,
  'market-groups': MarketGroupForm,
  'handle-credits': HandleCreditsForm,
  'add-admin-credits': AddAdminCreditsForm,
  daily: FilterDailyReports,
  'player-risk-management': RiskManagementPlayerSettingsDialog,
  jackpot: JackpotForm,
  bonus: BonusForm,
  marketplace: MarketplaceForm,
  partners: PartnerForm,
};

export type FormTypes = keyof typeof forms;

type Props = DialogType & {
  form: FormTypes;
  item?: unknown;
};

const FormModal = ({ form, item, closeModal = () => void 0 }: Props) => {
  const FormComponent = forms[form];
  // @ts-expect-error
  return <FormComponent item={item} closeModal={closeModal} />;
};

export default FormModal;
