import { Circle } from '@mui/icons-material';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import Root from './components/Root';
import ProtectedNavigate from './components/atoms/ProtectedNavigate';
import ProtectedPage from './components/layouts/ProtectedPage';
import UserManagementPageLayout from './components/layouts/UserManagementPageLayout';
import TabNavigation from './components/organisms/TabNavigation';
import BonusPage from './components/pages/BonusPage';
import CMSStudioPage from './components/pages/CMSStudioPage';
import CashoutPage from './components/pages/CashoutPage';
import CasinoPage from './components/pages/CasinoPage';
import CompetitionsPage from './components/pages/CompetitionsPage';
import DailyReportsPage from './components/pages/DailyReportsPage';
import DeletedMessagesPage from './components/pages/DeletedMessagesPage';
import ErrorPage from './components/pages/ErrorPage';
import EventsPage from './components/pages/EventsPage';
import EventsResultsPage from './components/pages/EventsResultsPage';
import JackpotPage from './components/pages/JackpotPage';
import LiabilityMonitoringPage from './components/pages/LiabilityMonitoringPage';
import MarketGroupsPage from './components/pages/MarketGroupsPage';
import MarketTypesPage from './components/pages/MarketTypesPage';
import MessagesInboxPage from './components/pages/MessagesInboxPage';
import MessagesSentPage from './components/pages/MessagesSentPage';
import OutcomeTypesPage from './components/pages/OutcomeTypesPage';
import OutrightsPage from './components/pages/OutrightsPage';
import ParticipantsPage from './components/pages/ParticipantsPage';
import PlayersPage from './components/pages/PlayersPage';
import PublishEventsPage from './components/pages/PublishEventsPage';
import SportsPage from './components/pages/SportsPage';
import TicketAuthorizationPage from './components/pages/TicketAuthorizationPage';
import TicketMonitoringPage from './components/pages/TicketMonitoringPage';
import TournamentsPage from './components/pages/TournamentsPage';
import ConditionEntityPage from './components/pages/conditions/ConditionEntityPage';
import ConditionGroupsPage from './components/pages/conditions/ConditionGroupsPage';
import ConditionTypesPage from './components/pages/conditions/ConditionTypesPage';
import TicketsConditionsPage from './components/pages/conditions/TicketsConditionsPage';
import CustomersPage from './components/pages/crm/CustomersPage';
import PartnersPage from './components/pages/crm/PartnersPage';
import CustomerWalletLayoutPage from './components/pages/crm/wallet/CustomerWalletLayoutPage';
import CustomerWalletTransactionPage from './components/pages/crm/wallet/CustomerWalletTransactionPage';
import MyWalletLayoutPage from './components/pages/crm/wallet/MyWalletLayoutPage';
import MyWalletTransactionPage from './components/pages/crm/wallet/MyWalletTransactionPage';
import PaymentTransactionPage from './components/pages/crm/wallet/PaymentTransactionPage';
import TotalCreditPage from './components/pages/crm/wallet/TotalCreditPage';
import AgentsReportPage from './components/pages/reports/AgentsReportsPage';
import CashoutReportsPage from './components/pages/reports/CashoutReportsPage';
import JackpotReportsPage from './components/pages/reports/JackpotReportsPage';
import ManagersReportPage from './components/pages/reports/ManagersReportsPage';
import PlayersReportPage from './components/pages/reports/PlayersReportsPage';
import PaymentsListReportsPage from './components/pages/reports/payments/PaymentsListReportsPage';
import PaymentsTotalReportsPage from './components/pages/reports/payments/PaymentsTotalReportsPage';
import SportsbookReportsEntityPage from './components/pages/reports/sportsbook/SportsbookReportsEntityPage';
import RiskManagementEntityPage from './components/pages/risk-management/RiskManagementEntityPage';
import RiskSettingsPage from './components/pages/risk-management/RiskSettingsPage';
import BonusReportsPage from './components/pages/reports/BonusReportsPage';
import {
  BETTING_OFFER_TABS,
  CONDITIONS_TABS,
  CRM_PAGE_TABS,
  MESSAGES_TABS,
  OFFER_MANAGEMENT_TABS,
  PARTNERS_REPORT_TABS,
  PAYMENTS_TABS,
  PRODUCT_REPORTS_TABS,
  RISK_MANAGEMENT_TABS,
  SPORTSBOOK_TABS,
  SPORTS_BETTING_TABS,
  USER_MANAGEMENT_TABS,
} from './constants';
import { SelectedCRMUserProvider } from './contexts/CRMUserContext';
import MarketplacePage from './components/pages/MarketplacePage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage text='Page not found' />,
    children: [
      {
        path: 'sports-betting',
        element: <ProtectedPage permission='accessSportsBetting' requireTenant />,
        children: [
          {
            index: true,
            element: <ProtectedNavigate tabs={SPORTS_BETTING_TABS} />,
          },
          {
            path: 'settings',
            element: (
              <TabNavigation
                basePath='sports-betting/settings'
                tabs={BETTING_OFFER_TABS}
                icon={<Circle fontSize='inherit' />}
              />
            ),
            children: [
              {
                index: true,
                element: <ProtectedNavigate tabs={BETTING_OFFER_TABS} />,
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'sports',
                    element: <SportsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'competitions',
                    element: <CompetitionsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'tournaments',
                    element: <TournamentsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'participants',
                    element: <ParticipantsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'players',
                    element: <PlayersPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'markets',
                    element: <MarketTypesPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'outcomes',
                    element: <OutcomeTypesPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='accessPublishEvents' />,
                children: [
                  {
                    path: 'publish-events',
                    element: <PublishEventsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='manageBettingSettings' />,
                children: [
                  {
                    path: 'market-groups',
                    element: <MarketGroupsPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='accessConditions' />,
                children: [
                  {
                    path: 'conditions',
                    element: <TabNavigation tabs={CONDITIONS_TABS} />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to='./conditions-type' />,
                      },
                      { path: 'conditions-type', element: <ConditionTypesPage /> },
                      { path: 'conditions-groups', element: <ConditionGroupsPage /> },
                      { path: 'sports', element: <ConditionEntityPage /> },
                      { path: 'competitions', element: <ConditionEntityPage /> },
                      { path: 'tournaments', element: <ConditionEntityPage /> },
                      { path: 'markets', element: <ConditionEntityPage /> },
                      { path: 'punters', element: <ConditionEntityPage /> },
                      { path: 'tickets', element: <TicketsConditionsPage /> },
                    ],
                  },
                ],
              },
              {
                element: <ProtectedPage permission='accessRiskManagement' />,
                children: [
                  {
                    path: 'risk-management',
                    element: <TabNavigation tabs={RISK_MANAGEMENT_TABS} />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to='./risk-settings' />,
                      },
                      {
                        path: 'risk-settings',
                        element: <RiskSettingsPage />,
                      },
                      { path: 'sports', element: <RiskManagementEntityPage /> },
                      { path: 'tournaments', element: <RiskManagementEntityPage /> },
                      { path: 'markets', element: <RiskManagementEntityPage /> },
                      { path: 'players', element: <RiskManagementEntityPage /> },
                    ],
                  },
                ],
              },
            ],
          },
          {
            element: <ProtectedPage permission='manageOffer' />,
            children: [
              {
                path: 'offer-management',
                element: <TabNavigation tabs={OFFER_MANAGEMENT_TABS} icon={<Circle fontSize='inherit' />} />,
                children: [
                  {
                    index: true,
                    element: <ProtectedNavigate tabs={OFFER_MANAGEMENT_TABS} />,
                  },
                  {
                    element: <ProtectedPage permission='manageEvents' />,
                    children: [
                      {
                        path: 'events',
                        element: <EventsPage />,
                      },
                    ],
                  },
                  {
                    element: <ProtectedPage permission='manageEvents' />,
                    children: [
                      {
                        path: 'outrights',
                        element: <OutrightsPage />,
                      },
                    ],
                  },
                  {
                    element: <ProtectedPage permission='manageEventsResults' />,
                    children: [
                      {
                        path: 'results',
                        element: <EventsResultsPage />,
                      },
                    ],
                  },
                  {
                    element: <ProtectedPage permission='accessTicketMonitoring' />,
                    children: [
                      {
                        path: 'ticket-monitoring',
                        element: <TicketMonitoringPage />,
                      },
                    ],
                  },
                  {
                    element: <ProtectedPage permission='accessTicketAuthorization' />,
                    children: [
                      {
                        path: 'ticket-authorization',
                        element: <TicketAuthorizationPage />,
                      },
                    ],
                  },
                  {
                    element: <ProtectedPage permission='accessLiabilityMonitoring' />,
                    children: [
                      {
                        path: 'liability-monitoring',
                        element: <LiabilityMonitoringPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedPage permission='manageCasino' requireTenant />,
        children: [
          {
            path: 'casino',
            element: <CasinoPage />,
            children: [
              {
                path: '*',
                element: <CasinoPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'messages',
        element: <TabNavigation tabs={MESSAGES_TABS} icon={<Circle fontSize='inherit' />} />,
        children: [
          {
            index: true,
            element: <Navigate to='./inbox' />,
          },
          {
            path: 'inbox',
            element: <MessagesInboxPage />,
          },
          {
            path: 'sent',
            element: <MessagesSentPage />,
          },
          {
            path: 'deleted',
            element: <DeletedMessagesPage />,
          },
        ],
      },
      {
        element: <ProtectedPage permission='manageBonuses' requireTenant />,
        children: [
          {
            path: 'bonus',
            element: <BonusPage />,
          },
        ],
      },
      {
        element: <ProtectedPage permission='manageJackpots' requireTenant />,
        children: [
          {
            path: 'jackpot',
            element: <JackpotPage />,
          },
        ],
      },
      {
        element: <ProtectedPage permission='accessCMS' requireTenant />,
        children: [
          {
            path: 'cms',
            element: <CMSStudioPage />,
            children: [
              {
                path: '*',
                element: <CMSStudioPage />,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedPage permission='manageCashOut' requireTenant />,
        children: [
          {
            path: 'cash-out',
            element: <CashoutPage />,
          },
        ],
      },
      {
        element: <ProtectedPage permission='accessCRM' requireTenant />,
        children: [
          {
            path: 'crm',
            element: (
              <SelectedCRMUserProvider>
                <TabNavigation tabs={CRM_PAGE_TABS} icon={<Circle fontSize='inherit' />} />
              </SelectedCRMUserProvider>
            ),
            children: [
              {
                index: true,
                element: <ProtectedNavigate tabs={CRM_PAGE_TABS} />,
              },
              {
                element: <ProtectedPage permission='accessPartners' />,
                children: [
                  {
                    path: 'partners',
                    element: <PartnersPage />,
                  },
                ],
              },
              {
                element: <ProtectedPage permission='accessPlayers' />,
                children: [
                  {
                    path: 'players',
                    element: <CustomersPage />,
                  },
                ],
              },
              {
                path: ':userType/:userId',
                children: [
                  {
                    path: 'wallet',
                    element: <CustomerWalletLayoutPage />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to='./total-credit' />,
                      },
                      {
                        path: 'total-credit',
                        element: <TotalCreditPage />,
                      },
                      {
                        path: 'payment-transaction',
                        element: <PaymentTransactionPage />,
                      },
                      {
                        path: 'wallet-transaction',
                        element: <CustomerWalletTransactionPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'my-wallet',
        element: <MyWalletLayoutPage />,
        children: [
          {
            index: true,
            element: <Navigate to='./wallet-transaction' />,
          },
          {
            path: 'wallet-transaction',
            element: <MyWalletTransactionPage />,
          },
        ],
      },
      {
        element: <ProtectedPage permission='accessUserManagement' />,
        children: [
          {
            path: 'user-management',
            element: <TabNavigation tabs={USER_MANAGEMENT_TABS} icon={<Circle fontSize='inherit' />} />,
            children: [
              {
                index: true,
                element: <Navigate to='./users' />,
              },
              {
                path: 'users',
                element: (
                  <UserManagementPageLayout
                    title='Users'
                    src='/admin/sportsbook-bo-users/console/#/sportsbook-bo-users/users'
                  />
                ),
              },
              {
                path: 'groups',
                element: (
                  <UserManagementPageLayout
                    title='Groups'
                    src='/admin/sportsbook-bo-users/console/#/sportsbook-bo-users/groups'
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedPage permission='accessProductReports' requireTenant />,
        children: [
          {
            path: 'product-reports',
            element: (
              <TabNavigation
                basePath='product-reports'
                tabs={PRODUCT_REPORTS_TABS}
                icon={<Circle fontSize='inherit' />}
              />
            ),
            children: [
              {
                index: true,
                element: <ProtectedNavigate tabs={PRODUCT_REPORTS_TABS} />,
              },
              {
                path: 'daily',
                element: <DailyReportsPage />,
              },
              {
                path: 'sportsbook',
                element: <TabNavigation tabs={SPORTSBOOK_TABS} />,
                children: [
                  {
                    index: true,
                    element: <Navigate to='./sports' />,
                  },
                  { path: 'sports', element: <SportsbookReportsEntityPage /> },
                  { path: 'tournaments', element: <SportsbookReportsEntityPage /> },
                  { path: 'markets', element: <SportsbookReportsEntityPage /> },
                ],
              },
              { path: 'players', element: <PlayersReportPage /> },
              {
                path: 'partners',
                element: <TabNavigation tabs={PARTNERS_REPORT_TABS} />,
                children: [
                  {
                    index: true,
                    element: <Navigate to='./agents' />,
                  },
                  { path: 'managers', element: <ManagersReportPage /> },
                  { path: 'agents', element: <AgentsReportPage /> },
                ],
              },
              {
                path: 'payments',
                element: <TabNavigation tabs={PAYMENTS_TABS} />,
                children: [
                  {
                    index: true,
                    element: <Navigate to='./total' />,
                  },
                  { path: 'total', element: <PaymentsTotalReportsPage /> },
                  { path: 'list', element: <PaymentsListReportsPage /> },
                ],
              },
              { path: 'bonus', element: <BonusReportsPage /> },
              { path: 'jackpot', element: <JackpotReportsPage /> },
              {
                element: <ProtectedPage permission='viewCashoutReports' />,
                children: [
                  {
                    path: 'cashout',
                    element: <CashoutReportsPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      //Hidden until it gets completed
      // {
      //   path: 'agent-reports',
      // },
      {
        element: <ProtectedPage permission='accessMarketplace' />,
        children: [
          {
            path: 'marketplace',
            element: <MarketplacePage />,
          },
        ],
      },
    ],
  },
]);

export default router;
