import { ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import { Event, Tournament } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { formatScore, getLatestValue } from '../../helpers';
import { getData } from '../../utils/api';
import EventTabs from '../atoms/EventTabs';
import MarketsTable from './MarketsTable';

const styles: StyleObj = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    mt: 11,
    width: '50%',
  },
  eventNameWrapper: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.light,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    px: 1.5,
    py: 1.25,
  }),
  eventInfoWrapper: (theme) => ({
    backgroundColor: theme.palette.background.lighter,
    px: 1.5,
    py: 1.25,
  }),
  chevronIconBtn: {
    p: 0,
    pr: 0.5,
  },
};

type EventMarketsProps = {
  eventId: string;
  tournament?: Tournament;
  handleClose: () => void;
};

const EventMarkets = ({ eventId, tournament, handleClose }: EventMarketsProps) => {
  const [activeTab, setActiveTab] = useState<string | false>(false);
  const queryKey = [QUERY_KEYS.events, eventId, activeTab];

  const { data: event } = useQuery(queryKey, {
    queryFn: (): Promise<{ item: Event }> => getData(`events/${eventId}`),
    select: (data) => data.item,
  });

  const startDate = event && getLatestValue(event, 'startDate');

  useEffect(() => {
    setActiveTab(false);
  }, [eventId, setActiveTab]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.eventNameWrapper}>
        <IconButton onClick={handleClose} sx={styles.chevronIconBtn} color='inherit'>
          <ChevronRight />
        </IconButton>
        {event && (
          <Typography variant='h5'>
            {getLatestValue(event, 'name')} | {dayjs(startDate).format('DD.MM.YYYY')} |{' '}
            {dayjs(startDate).format('HH:mm')}
            {event?.matchTime ? `| ${event.matchTime}` : ''}
            {event?.mainEventPartScore ? ` | ${formatScore(event.mainEventPartScore)}` : ''}
          </Typography>
        )}
      </Box>
      <Box sx={styles.eventInfoWrapper}>
        <Typography variant='h6' fontWeight={600}>
          {tournament?.sport.name} | {tournament?.competition.name} | {tournament?.name}
        </Typography>
      </Box>
      {event?.tournament?.sport?.id && (
        <EventTabs
          sportId={event?.tournament?.sport?.id}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isLive={event.isLive}
        />
      )}
      {!!event && <MarketsTable event={event} queryKey={queryKey} activeTab={activeTab} />}
    </Box>
  );
};

export default EventMarkets;
