import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { Partner } from '../../../../@types/api';
import { QUERY_KEYS } from '../../../../constants';
import { USER_ROLES } from '../../../../constants/keycloak';
import { useModal } from '../../../../contexts/ModalContext';
import { useInvalidateQuery } from '../../../../hooks/useInvalidateQuery';
import useMutateData from '../../../../hooks/useMutateData';
import usePermissions from '../../../../hooks/usePermissions';
import { createPartnerSchema, editPartnerSchema, PartnerFormData } from '../../../../schema';
import FormModalLayout from '../../../layouts/FormModalLayout';
import FormSelect from '../../../molecules/FormSelect';
import PartnerSelect from '../../../molecules/PartnerSelect';
import PasswordFields from '../../../molecules/PasswordFields';

export const DEFAULT_PARTNER_DATA: PartnerFormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  groupName: '',
  managerId: undefined,
};

const ADMIN_ROLE_OPTIONS = [
  { id: USER_ROLES.MANAGER, name: USER_ROLES.MANAGER },
  { id: USER_ROLES.AGENT, name: USER_ROLES.AGENT },
  { id: USER_ROLES.TECH_SUPPORT, name: USER_ROLES.TECH_SUPPORT },
  { id: USER_ROLES.TRADER, name: USER_ROLES.TRADER },
];

const MANAGER_ROLE_OPTIONS = [{ id: USER_ROLES.AGENT, name: USER_ROLES.AGENT }];

const PartnerForm = ({ closeModal }: DialogType) => {
  const { item: partner } = useModal<Partner>();
  const { role } = usePermissions();
  const { userId: loggedInUserId } = useAuth();
  const invalidateData = useInvalidateQuery();

  const isEdit = !!partner;
  const isUserManager = role === USER_ROLES.MANAGER;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<PartnerFormData>({
    defaultValues: DEFAULT_PARTNER_DATA,
    resolver: zodResolver(isEdit ? editPartnerSchema : createPartnerSchema(isUserManager)),
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        ...partner,
        groupName: partner.role.name,
        managerId: partner.teamLead?.userId,
      });
    }
  }, [partner, isEdit, reset]);

  const { createData, updateData } = useMutateData('agent', [QUERY_KEYS.partners], 'crm');

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_PARTNER_DATA);
  };

  const onFormSubmitSuccess = () => {
    handleClose();
    invalidateData([QUERY_KEYS.agentSearch]);
  };

  const onFormSubmit = (newPartner: PartnerFormData) => {
    if (isEdit) {
      updateData(partner.id, { ...newPartner, managerId: undefined, groupName: undefined }, onFormSubmitSuccess);
    } else {
      // A manager can only create their own agents
      const managerId = isUserManager ? loggedInUserId : watch('managerId');
      createData({ ...newPartner, managerId, confirmPassword: undefined }, onFormSubmitSuccess);
    }
  };

  const isCreatingAgent = watch('groupName') === USER_ROLES.AGENT;
  const showManagerSelect = isCreatingAgent && !isUserManager;

  const ROLE_OPTIONS = isUserManager ? MANAGER_ROLE_OPTIONS : ADMIN_ROLE_OPTIONS;

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEdit ? 'Edit partner' : 'Add new partner'}
      onClose={handleClose}
      isEdit={isEdit}
    >
      <TextField
        label='First Name'
        {...register('firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        required
      />
      <TextField
        label='Last Name'
        {...register('lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        required
      />
      <PasswordFields resetPassword={!!partner} errors={errors} register={register} />
      <TextField
        label='Email'
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        required
      />
      <FormSelect
        control={control}
        label='Role'
        name='groupName'
        options={ROLE_OPTIONS}
        required
        error={errors.groupName}
        disabled={isEdit}
      />
      {showManagerSelect && (
        <PartnerSelect<PartnerFormData>
          control={control}
          label='Manager'
          name='managerId'
          queryKey={[QUERY_KEYS.partners]}
          queryParams={{
            roleTypes: ['Manager'],
          }}
          required
          error={errors.managerId}
          disabled={isEdit}
          multipleSelect={false}
        />
      )}
    </FormModalLayout>
  );
};

export default PartnerForm;
