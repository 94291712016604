import { Box, BoxProps } from '@mui/material';

type Props = BoxProps & {
  children: React.ReactNode;
  size?: number;
  bgColor?: string;
};

const Circle = ({ children, size = 20, bgColor = '#2B7FFE', sx, ...rest }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: 'min-content',
        minWidth: size,
        borderRadius: 4,
        backgroundColor: bgColor,
        color: 'white',
        fontSize: {
          xs: 10,
          md: 12,
        },
        fontWeight: 'bold',
        px: 0.5,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Circle;
