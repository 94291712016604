import { CRMApiEntityType, UserRole } from '../@types/api';
import CRMCommissionTab from '../components/organisms/crm/CRMCommissionTab';
import CRMDetailsTab from '../components/organisms/crm/CRMDetailsTab';
import CRMNotesTab from '../components/organisms/crm/CRMNotesTab';
import CRMTasksTab from '../components/organisms/crm/CRMTasksTab';
import { FilterOption } from '../components/organisms/forms/FilterCRMUsers';
import { USER_ROLES } from '../constants/keycloak';

export type CRMTabConfig = {
  label: string;
  Component: JSX.Element;
  visibleFor?: UserRole[];
}[];

export const CUSTOMER_TAB_CONFIG: CRMTabConfig = [
  {
    label: 'Details',
    Component: <CRMDetailsTab userType='customer' />,
  },
  {
    label: 'Notes',
    Component: <CRMNotesTab userType='customer' />,
  },
  {
    label: 'Tasks',
    Component: <CRMTasksTab userType='customer' />,
  },
];

export const PARTNER_TAB_CONFIG: CRMTabConfig = [
  {
    label: 'Details',
    Component: <CRMDetailsTab userType='partner' />,
  },
  {
    label: 'Notes',
    Component: <CRMNotesTab userType='partner' />,
  },
  {
    label: 'Tasks',
    Component: <CRMTasksTab userType='partner' />,
  },
  {
    label: 'Commission',
    Component: <CRMCommissionTab />,
    visibleFor: ['Agent'],
  },
];

export const CUSTOMER_FILTER_OPTIONS: Record<string, FilterOption> = {
  isActive: { id: 'isActive', label: 'Status', type: 'switch' },
  country: { id: 'country', label: 'Country', type: 'autocomplete', freeSolo: false },
  createdAt: { id: 'createdAt', label: 'Date Created', type: 'date-range' },
  firstName: { id: 'firstName', label: 'First Name', type: 'autocomplete', freeSolo: true },
  lastName: { id: 'lastName', label: 'Last Name', type: 'autocomplete', freeSolo: true },
  phoneNumber: { id: 'phone', label: 'Phone Number', type: 'autocomplete', freeSolo: true },
  email: { id: 'email', label: 'Email', type: 'autocomplete', freeSolo: true },
  zip: { id: 'zip', label: 'Zip Code', type: 'autocomplete', freeSolo: true },
  city: { id: 'city', label: 'City', type: 'autocomplete', freeSolo: false },
  region: { id: 'region', label: 'Region', type: 'autocomplete', freeSolo: false },
  language: { id: 'language', label: 'Language', type: 'autocomplete', freeSolo: true },
  age: { id: 'age', label: 'Age', type: 'number-range' },
};

export const PARTNER_FILTER_OPTIONS: Record<string, FilterOption> = {
  isActive: { id: 'isActive', label: 'Status', type: 'switch' },
  fullName: { id: 'fullName', label: 'Full Name', type: 'autocomplete', freeSolo: true },
  email: { id: 'email', label: 'Email', type: 'autocomplete', freeSolo: true },
  createdAt: { id: 'createdAt', label: 'Date Created', type: 'date-range' },
  roleTypes: { id: 'roleTypes', label: 'Role', type: 'autocomplete' },
};

export const PARTNER_ROLE_TYPES = [USER_ROLES.MANAGER, USER_ROLES.AGENT, USER_ROLES.TECH_SUPPORT, USER_ROLES.TRADER];

export const PATH_TO_USER_TYPE_MAP: Record<string, CRMApiEntityType> = {
  players: 'customer',
  partners: 'partner',
};

export const USER_TYPE_TO_CRM_API_ENTITY_MAP = {
  customer: 'customer',
  partner: 'agent',
};
