import { Control, FieldValues, Path } from 'react-hook-form';
import { QUERY_KEYS } from '../../constants';
import { Partner } from '../../@types/api';
import FormAutocomplete, { AutocompleteItem, FormAutocompleteProps } from '../organisms/FormAutocomplete';

const defaultGetOptionLabel = (options: Partner[], value: string) => {
  return options.find((option) => option.userId === value)?.fullName || '';
};

type Props<O extends AutocompleteItem, T extends FieldValues> = Partial<FormAutocompleteProps<O, T>> & {
  control: Control<T>;
  name: Path<T>;
  multipleSelect?: boolean;
};

const PartnerSelect = <T extends FieldValues>({
  control,
  name,
  label = 'Partners',
  queryKey = [QUERY_KEYS.partners],
  url = 'agent/search',
  getOptionLabel = defaultGetOptionLabel,
  multipleSelect = true,
  ...rest
}: Props<Partner, T>) => {
  return (
    <FormAutocomplete<Partner, T>
      name={name}
      control={control}
      label={label}
      getOptionLabel={getOptionLabel}
      url={url}
      queryKey={queryKey}
      apiService='crm'
      optionsFilter='userId'
      multiple={multipleSelect}
      {...rest}
    />
  );
};

export default PartnerSelect;
