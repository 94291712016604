import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { useModal } from '../../contexts/ModalContext';
import ConfirmationModal from '../modals/ConfirmationModal';
import FormModal, { FormTypes } from '../modals/FormModal';

type EditTableItemProps<T> = {
  handleDelete?: () => void;
  item?: T;
  icon?: React.ReactNode;
};

const styles: StyleObj = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const EditTableItem = <T,>({ handleDelete, item, icon = <EditOutlined /> }: EditTableItemProps<T>) => {
  const { openModal } = useModal();
  const { pathname } = useLocation();

  const openEditDialog = () => {
    if (item) {
      openModal(<FormModal form={pathname.split('/').pop() as FormTypes} />, item);
    }
  };

  const openDeleteDialog = () => {
    handleDelete && openModal(<ConfirmationModal handleConfirm={handleDelete} actionButtonClose='Cancel' />);
  };

  return (
    <Box sx={styles.container}>
      <IconButton onClick={openEditDialog} color='primary'>
        {icon}
      </IconButton>
      {handleDelete && (
        <IconButton onClick={openDeleteDialog} color='primary'>
          <DeleteOutlined />
        </IconButton>
      )}
    </Box>
  );
};

export default EditTableItem;
