import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { FieldError, Path, UseFormRegister } from 'react-hook-form';
import { PasswordFormFields } from './PasswordFields';

type PasswordFieldProps<T extends PasswordFormFields> = Omit<TextFieldProps, 'error'> & {
  name: keyof PasswordFormFields;
  label: string;
  register: UseFormRegister<T>;
  error?: FieldError;
};

const PasswordField = <T extends PasswordFormFields>({
  name,
  label,
  register,
  error,
  ...rest
}: PasswordFieldProps<T>) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      label={label}
      {...register(name as Path<T>)}
      error={!!error}
      helperText={error?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ cursor: 'pointer' }} onClick={toggleVisibility} position='end'>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default PasswordField;
