import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType, StyleObj } from '../../../../../@types';
import { CRMApiEntityType, TransactionType, WalletType } from '../../../../../@types/api';
import { QUERY_KEYS, TRANSACTION_TYPE_OPTIONS, WALLET_TYPE_OPTIONS } from '../../../../../constants';
import { useInvalidateQuery } from '../../../../../hooks/useInvalidateQuery';
import useMutateData from '../../../../../hooks/useMutateData';
import usePermissions from '../../../../../hooks/usePermissions';
import { handleCreditsSchema } from '../../../../../schema';
import FormNumberInput from '../../../../molecules/FormNumberInput';
import FormSelect from '../../../../molecules/FormSelect';

const styles: StyleObj = {
  actions: { pl: 2 },
};

const DEFAULT_FORM_DATA: CreditFormData = {
  walletType: 'main',
  amount: '',
  transactionType: 'deposit',
  description: '',
};

type CreditRequestData = Omit<CreditFormData, 'amount'> & {
  userId: string;
  isForAgentPayment: boolean;
  source: string;
  amount: number;
  otherTransactionType: TransactionType; // Used to differentiate between Other deposits and withdrawals
};

type CreditFormData = {
  walletType: WalletType;
  amount: string;
  transactionType: TransactionType;
  description?: string;
};

type Props = DialogType & {
  item: {
    userId: string;
    userType: CRMApiEntityType;
  };
};

const HandleCreditsForm = ({ closeModal, item }: Props) => {
  const { userId, userType } = item;

  const invalidateData = useInvalidateQuery();
  const { hasPermission } = usePermissions();

  const { createData } = useMutateData('payment/credits', [QUERY_KEYS.credits, userId], 'wallet');
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreditFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(handleCreditsSchema),
  });

  const transactionType = watch('transactionType');
  const walletType = watch('walletType');

  const isAddButtonDisabled = !transactionType || transactionType === 'withdrawal';
  const isRemoveButtonDisabled = !transactionType || transactionType === 'deposit' || transactionType === 'bonus';

  useEffect(() => {
    setValue('transactionType', TRANSACTION_TYPE_OPTIONS[walletType]?.[0].id);
  }, [setValue, walletType]);

  const handleClose = () => {
    closeModal?.();
  };

  const isForAgentPayment = userType === 'partner';

  const onFormSubmit = (creditsData: CreditFormData, otherTransactionType: TransactionType) => {
    if (!userId) return;

    const preparedData: CreditRequestData = {
      ...creditsData,
      userId,
      amount: Number(creditsData.amount),
      isForAgentPayment,
      source: 'sportsbook',
      otherTransactionType,
    };

    createData(
      preparedData,
      () => {
        invalidateData([QUERY_KEYS.userBalance, userId]);
        invalidateData([QUERY_KEYS.paymentTransactions]);
        invalidateData([QUERY_KEYS.walletTransactions]);
        handleClose();
      },
      'Balance updated successfully'
    );
  };

  const hasAdminCredentials = hasPermission('defaultAdminRole');
  const isBonusWalletDisabled = !hasAdminCredentials || isForAgentPayment;

  return (
    <>
      <DialogTitle variant='h4' gutterBottom>
        Add/Remove Credits
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormSelect
            name='walletType'
            control={control}
            label='Wallet'
            options={WALLET_TYPE_OPTIONS(isBonusWalletDisabled)}
            error={errors.walletType}
            required
          />
          <FormNumberInput name='amount' control={control} label='Amount' allowDecimals valueAsString required />
          <FormSelect
            name='transactionType'
            control={control}
            label='Type'
            options={TRANSACTION_TYPE_OPTIONS[walletType] || []}
            error={errors.transactionType}
            required
          />
          <TextField {...register('description')} label='Description' multiline />
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit((data) => onFormSubmit(data, 'deposit'))} disabled={isAddButtonDisabled}>
          Add Credit
        </Button>
        <Button
          color='error'
          onClick={handleSubmit((data) => onFormSubmit(data, 'withdrawal'))}
          disabled={isRemoveButtonDisabled}
        >
          Remove Credit
        </Button>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default HandleCreditsForm;
