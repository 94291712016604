import { DialogType } from '../../../@types';
import { BettingSlipFrom, Customer, Device } from '../../../@types/api';
import { BETTING_SLIP_FROM_OPTIONS, DEVICE_OPTIONS, QUERY_KEYS } from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import { getFullName } from '../../../utils';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  userId: undefined,
  from: undefined,
  device: undefined,
};

export type FilterTicketAuthorizationData = {
  userId?: string;
  from?: BettingSlipFrom;
  device?: Device;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterTicketAuthorization = ({ changeQuery, closeModal }: Props) => {
  const { control, handleSubmit, handleResetFilter } = useFilterForm<FilterTicketAuthorizationData>({
    defaultData: defaultValues,
    changeQuery,
  });

  return (
    <FilterDialogLayout
      label='tickets'
      onClose={() => closeModal?.()}
      onSave={handleSubmit}
      onReset={handleResetFilter}
    >
      <FormSelect control={control} name='from' label='From' options={BETTING_SLIP_FROM_OPTIONS} />
      <FormSelect control={control} name='device' label='Source' options={DEVICE_OPTIONS} />
      <FormAutocomplete<Customer, FilterTicketAuthorizationData>
        name='userId'
        control={control}
        queryKey={[QUERY_KEYS.customers]}
        apiService='crm'
        url='customer/search'
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.userId === value);
          return option
            ? `${getFullName(option.firstName, option.lastName, option.username)} (${option.username})`
            : '';
        }}
        label='Punter'
        optionsFilter='userId'
      />
    </FilterDialogLayout>
  );
};

export default FilterTicketAuthorization;
