import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircle, Info, SettingsOutlined, ChevronRight } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { Bet, BettingSlipWithMonitoringInfo } from '../../@types/api';
import { BETTING_SLIP_FROM, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import {
  initializeTicketAuthorizationFormData,
  formatScore,
  getNameToDisplay,
  getTicketBettingType,
} from '../../helpers';
import { defaultColumnsTicketAuthorizationDetails } from '../../helpers/table';
import useTicketAuthorization from '../../hooks/useTicketAuthorization';
import useVisibleColumns from '../../hooks/useVisibleColumns';
import { ticketReofferSchema } from '../../schema';
import { createColumn } from '../../utils';
import { getData } from '../../utils/api';
import IconRenderer from '../atoms/IconRenderer';
import { BettingSlipFromIcon } from '../icons';
import CustomizeTable from '../molecules/CustomizeTable';
import FormNumberInput from '../molecules/FormNumberInput';
import TicketAuthCountdown from '../molecules/TicketAuthCountdown';
import TicketAuthSpecialValueSelect from '../molecules/TicketAuthSpecialValueSelect';
import TicketAuthDetailsDataGrid from './TicketAuthDetailsDataGrid';
import { calculateTotalOddsForNormalBettingSlip, calculateMaxPayout } from 'sportsbook-shared-module';
import theme from '../../styles/theme';

const styles: StyleObj = {
  container: {
    mt: 11,
    width: '50%',
    borderRadius: 2,
    height: {
      xs: 'calc(100% - 100px)',
      md: 'calc(100% - 180px)',
    },
    justifyContent: 'space-between',
    gap: {
      xs: 0.25,
      md: 3,
    },
  },
  generalInfoRow: {
    backgroundColor: 'primary.main',
    color: 'white',
    display: 'flex',
  },
  generalInfoWrapper: {
    height: 44,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    pr: 1.75,
    pl: {
      xs: 0,
      md: 1.5,
    },
    gap: {
      xs: 1.5,
      md: 3,
    },
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'background.lighter',
    px: 1.75,
    py: 1.25,
  },
  customizeTableIcon: {
    color: 'white',
    mr: -1,
    ':hover': {
      color: 'background.lightGreen',
      background: 'transparent',
    },
  },
  numberInput: {
    '& .MuiOutlinedInput-input': {
      padding: '2px 6px',
      backgroundColor: 'white',
      width: 64,
      fontWeight: 600,
      fontSize: 14,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },

  actionsContainer: (theme) => ({
    position: 'absolute',
    bottom: 40,
    right: 40,
    '> button': {
      height: 32,
      width: 90,
    },
    mt: 1,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
    },
  }),
  chevronIconBtn: (theme) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }),
  desktopTimerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    position: 'absolute',
    top: 12,
    right: 24,
  },
};

type Props = {
  ticketId: string;
  handleClose: () => void;
};

const TicketAuthDetailsTable = ({ ticketId, handleClose }: Props) => {
  const { data: ticketDetails, isLoading } = useQuery([QUERY_KEYS.bettingSlips, ticketId], {
    queryFn: (): Promise<BettingSlipWithMonitoringInfo> => getData(`/betting-slips/${ticketId}`),
    enabled: !!ticketId,
  });

  const [winnings, setWinnings] = useState(0);
  const [totalOdds, setTotalOdds] = useState<number | null>(null);
  const [specialValuesChanged, setSpecialValuesChanged] = useState(false);

  const { openModal } = useModal();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const columns: GridColDef[] = useMemo(
    () => [
      createColumn('startDate', 'Date and Time', {
        valueGetter: (params: GridValueGetterParams<Bet>) => {
          return dayjs(params.row.event.startDate).format('DD-MM-YYYY HH:mm');
        },
        sortable: false,
        alwaysVisible: true,
        flex: 1.5,
      }),
      createColumn('icon', 'Icon', {
        renderCell: (params: GridRenderCellParams<Bet>) => {
          return <IconRenderer name={params.row.icon} type='sport' />;
        },
        flex: 0.8,
        sortable: false,
        alwaysVisible: true,
      }),
      createColumn('from', 'From', {
        renderCell: (params: GridRenderCellParams<Bet>) => {
          const from = params.row.isLive ? 'In-play' : 'Pre-match';
          return <BettingSlipFromIcon from={from} />;
        },
        sortable: false,
        flex: 0.8,
      }),
      createColumn('event', 'Event', {
        valueGetter: (params: GridValueGetterParams<Bet>) => {
          return params.row.event.name;
        },
        sortable: false,
        flex: 2.5,
        minWidth: 200,
        alwaysVisible: true,
      }),
      createColumn('banker', 'Banker', {
        renderCell: (params: GridRenderCellParams<Bet>) => {
          return params.row.banker ? <CheckCircle color='success' /> : '';
        },
        sortable: false,
        flex: 0.8,
      }),
      createColumn('isWays', 'Ways', {
        renderCell: (params: GridRenderCellParams<Bet>) => {
          return params.row.ways ? <CheckCircle color='success' /> : '';
        },
        sortable: false,
        flex: 0.8,
      }),
      createColumn('market', 'Market', {
        valueGetter: (params: GridValueGetterParams<Bet>) => {
          return getNameToDisplay(params.row.market.marketType);
        },
        sortable: false,
        alwaysVisible: true,
        flex: 1.5,
        minWidth: 150,
      }),
      createColumn('specialValue', 'SV', {
        renderCell: (params: GridRenderCellParams<Bet>) => {
          if (!!params.row.specialValues.length) {
            return (
              <TicketAuthSpecialValueSelect
                control={control}
                bet={params.row}
                name={`specialValues.${params.row.id}.0.id`}
                onSpecialValueChange={handleSpecialValueChange}
              />
            );
          }
        },
        sortable: false,
        alwaysVisible: true,
        flex: 1.5,
      }),
      createColumn('outcome', 'Outcome', {
        valueGetter: (params: GridValueGetterParams<Bet>) => {
          return getNameToDisplay(params.row.outcome);
        },
        sortable: false,
        alwaysVisible: true,
        minWidth: 150,
      }),
      createColumn('odds', 'Odds', {
        sortable: false,
        alwaysVisible: true,
        renderCell: (params: GridRenderCellParams<Bet>) => {
          return (
            <FormNumberInput
              control={control}
              allowDecimals
              valueAsString
              onKeyDown={handleBetChange}
              name={
                !!params.row.specialValues.length
                  ? `specialValues.${params.row.id}.0.odds`
                  : `odds.${params.row.outcome.id}`
              }
              showHelperText={false}
              sx={styles.numberInput}
            />
          );
        },
      }),
      createColumn('result', 'Result', {
        valueGetter: (params: GridValueGetterParams<Bet>) => formatScore(params.row.mainEventPartScore),
        sortable: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticketDetails]
  );

  const { visibleColumns, setVisibleColumns, visibleColumnsLSKey } = useVisibleColumns({
    manualLSKey: 'ticket-authorization-details-table', // Used to differentiate tables on same path
    columns,
    defaultVisibleColumns: defaultColumnsTicketAuthorizationDetails,
  });

  const openCustomizationDialog = () => {
    openModal(
      <CustomizeTable
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultColumnsTicketAuthorizationDetails}
        visibleColumnsLSKey={visibleColumnsLSKey}
      />
    );
  };

  const initialFormValues = useMemo(() => initializeTicketAuthorizationFormData(ticketDetails), [ticketDetails]);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: initialFormValues,
    resolver: zodResolver(ticketReofferSchema(ticketDetails?.totalStakeAmount || 0)),
  });

  const { acceptTicket, rejectTicket, reofferTicket, isSubmitting } = useTicketAuthorization(ticketDetails);

  useEffect(() => {
    if (ticketDetails) {
      reset(initialFormValues);
      setWinnings(ticketDetails.possibleWinnings);
      setTotalOdds(ticketDetails.totalOdds);
    }
  }, [initialFormValues, reset, ticketDetails]);

  const recalculateTicketInfo = useCallback(() => {
    if (!ticketDetails) return;

    const currentOdds = getValues('odds');
    const totalStakeAmount = getValues('totalStakeAmount');
    const specialValues = getValues('specialValues');

    const preparedBets = ticketDetails.bets.map((bet) => ({
      ...bet,
      eventId: bet.event.id,
      marketTypeId: bet.market.marketType.id,
      marketTypeCombiningIds: bet.market.marketType.marketTypeCombiningIds,
      odds: currentOdds?.[bet.outcome.id]?.toString() || specialValues?.[bet.id][0].odds.toString(),
    }));

    const recalculatedOdds =
      ticketDetails.bettingSlipType !== 'System' ? calculateTotalOddsForNormalBettingSlip(preparedBets) : null;
    const { maxPayout } = calculateMaxPayout(
      {
        bets: preparedBets,
        betTypes: [
          {
            requiredHitCount: ticketDetails.requiredHitCount,
            stakeAmountPerCombination: Number(totalStakeAmount) / ticketDetails.combinationsCount,
          },
        ],
      },
      Infinity,
      Infinity
    );

    setWinnings(maxPayout);
    setTotalOdds(recalculatedOdds);
  }, [ticketDetails, getValues, setWinnings, setTotalOdds]);

  const handleSpecialValueChange = useCallback(
    (selectedOutcomeId: string, selectedMarketId: string, betId: string, selectedOdds: string, name: string) => {
      setValue(`specialValues.${betId}.0`, {
        id: selectedOutcomeId,
        marketId: selectedMarketId,
        odds: selectedOdds,
        name,
      });
      recalculateTicketInfo();
      setSpecialValuesChanged(true);
    },
    [setValue, recalculateTicketInfo, setSpecialValuesChanged]
  );

  const handleBetChange = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        recalculateTicketInfo();
      }
    },
    [recalculateTicketInfo]
  );

  return (
    <Stack sx={styles.container}>
      <Box sx={{ maxHeight: '75%', height: '-webkit-fill-available' }}>
        <Box sx={styles.desktopTimerWrapper}>
          {ticketDetails && !isMobile && (
            <TicketAuthCountdown ticketId={ticketId} createdAt={ticketDetails.createdAt} />
          )}
        </Box>
        <Box sx={styles.generalInfoRow}>
          <IconButton onClick={handleClose} sx={styles.chevronIconBtn} color='inherit'>
            <ChevronRight />
          </IconButton>
          <Stack sx={styles.generalInfoWrapper}>
            {ticketDetails && isMobile && (
              <TicketAuthCountdown ticketId={ticketId} createdAt={ticketDetails.createdAt} />
            )}
            <Typography variant='h5'>{ticketDetails?.ticketId}</Typography>
            <Typography variant='h5' sx={{ textTransform: 'capitalize' }}>
              {ticketDetails?.device}
            </Typography>
            <Typography variant='h5'>{ticketDetails?.user.fullName}</Typography>
            <Typography variant='h5' sx={{ textTransform: 'capitalize' }}>
              {ticketDetails?.walletType}
            </Typography>
            <Typography variant='h5'>
              {ticketDetails?.from === BETTING_SLIP_FROM.PRE_MATCH
                ? ticketDetails?.user.preMatchRiskFactor
                : ticketDetails?.user.inPlayRiskFactor}
            </Typography>
            <Typography variant='h5'>{getTicketBettingType(ticketDetails)}</Typography>
            <IconButton onClick={openCustomizationDialog} sx={styles.customizeTableIcon}>
              <SettingsOutlined />
            </IconButton>
          </Stack>
        </Box>
        <Box sx={styles.infoRow}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant='body3' fontWeight={600}>
              Stake:
            </Typography>
            <FormNumberInput
              control={control}
              allowDecimals
              valueAsString
              name='totalStakeAmount'
              onKeyDown={handleBetChange}
              error={errors?.totalStakeAmount}
              showHelperText={false}
              sx={styles.numberInput}
            />
          </Stack>
          <Typography variant='body3' fontWeight={600}>
            Odds: {!ticketDetails?.isWays && totalOdds?.toFixed(2)}
          </Typography>
          <Typography variant='body3' fontWeight={600}>
            Possible Winnings: {winnings?.toFixed(2)}
          </Typography>
          <Typography variant='body3' fontWeight={600}>
            Num. of Bets: {ticketDetails?.betsCount}
          </Typography>
          <Typography variant='body3' fontWeight={600}>
            Combinations: {ticketDetails?.combinationsCount}
          </Typography>
        </Box>
        <Box sx={{ height: 'calc(100% - 89px)' }}>
          <TicketAuthDetailsDataGrid
            columns={columns}
            bets={ticketDetails?.bets || []}
            visibleColumns={visibleColumns}
            loading={isLoading}
          />
        </Box>
      </Box>
      <Box sx={{ height: { md: '25%', sm: '30%' }, maxHeight: '200px', overflow: 'scroll' }}>
        <Box>
          {ticketDetails?.authResults.map((condition) => (
            <Stack
              key={condition.conditionTypeId}
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{ backgroundColor: 'background.lighter', borderRadius: 2, mb: 1, p: 1.25 }}
            >
              <Info color='primary' />
              <Typography variant='body3'>{condition.adminMessage}</Typography>
            </Stack>
          ))}
        </Box>
        <Stack direction='row' justifyContent='end' spacing={1} sx={styles.actionsContainer}>
          <Button onClick={rejectTicket} color='error' disabled={isSubmitting}>
            Reject
          </Button>
          <Button
            onClick={handleSubmit((data) => reofferTicket(data, ticketDetails?.bets || []))}
            variant='outlined'
            disabled={isSubmitting || !isValid || (!isDirty && !specialValuesChanged)}
          >
            Reoffer
          </Button>
          <Button onClick={acceptTicket} disabled={isSubmitting}>
            Accept
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TicketAuthDetailsTable;
