import { Box, Typography, TypographyVariant } from '@mui/material';
import { StyleObj } from '../../@types';
import { CURRENCY } from '../../constants';
import { formatNumberToStandard } from '../../utils';

const styles: StyleObj = {
  container: {
    width: '50%',
    maxWidth: 260,
    p: 1.5,
    borderRadius: 2,
    border: '1px solid',
  },
};

type BettingInfoDisplayProps = {
  label: string;
  value?: string;
  backgroundColor: string;
  borderColor: string;
  variant?: TypographyVariant;
};

const BettingInfoDisplay = ({
  label,
  value,
  backgroundColor,
  borderColor,
  variant = 'subtitle1',
}: BettingInfoDisplayProps) => {
  return (
    <Box
      sx={{
        ...styles.container,
        backgroundColor,
        borderColor,
      }}
    >
      <Typography variant={variant} fontWeight={500}>
        {label}: {CURRENCY.symbol}
        {formatNumberToStandard(Number(value)) || 0}
      </Typography>
    </Box>
  );
};

export default BettingInfoDisplay;
