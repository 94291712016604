import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Box, Divider, Menu } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { StyleObj } from '../../@types';
import { PublishStatus } from '../../@types/api';
import {
  MARKET_ALL_MATCHES_STATUS_OPTIONS,
  MARKET_STATUS_OPTIONS,
  PUBLISH_STATUS_BASE_OPTIONS,
  QUERY_KEYS,
  SWITCH_CONTROL_OPTIONS,
} from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import StyledMenuItem from '../atoms/StyledMenuItem';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMenuSection from './ManageMenuSection';
import { ManualIcon, AutomaticIcon } from '../icons';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

type ManageMarketMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  queryKey: QueryKey;
  selectedRows: string[];
  isLiveEvent: boolean;
  isOutrightEvent: boolean;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      py: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
  menuSectionHeader: (theme) => ({ fontWeight: 700, px: 2, py: 0.5, color: theme.palette.primary.dark }),
  menuMuiIcon: { mr: 0.5 },
};

const ManageMarketMenu = ({
  contextMenu,
  handleClose,
  queryKey,
  selectedRows,
  isLiveEvent,
  isOutrightEvent,
}: ManageMarketMenuProps) => {
  const invalidateData = useInvalidateQuery();
  const { updateData } = useMutateData('markets', queryKey);
  const { createData: createMarketChangesDataBulk } = useMutateData('market-changes/bulk', queryKey);
  const { createData: createMarketChangesDataBulkTournament } = useMutateData(
    'market-changes/bulk/tournament',
    queryKey
  );

  const handleUpdate = (
    action: 'publishStatus' | 'isActive' | 'manualControl' | 'isActiveTournament',
    option: HandleMenuItemClickOption
  ) => {
    const updateFunctions = {
      publishStatus: () => updateData('bulk', { marketIds: selectedRows, publishStatus: option.id }, handlePostUpdate),
      isActive: () => createMarketChangesDataBulk({ marketIds: selectedRows, isActive: !!option.id }, handlePostUpdate),
      isActiveTournament: () =>
        createMarketChangesDataBulkTournament({ marketIds: selectedRows, isActive: !!option.id }, handlePostUpdate),
      manualControl: () =>
        createMarketChangesDataBulk({ marketIds: selectedRows, manualControl: !!option.id }, handlePostUpdate),
    };

    updateFunctions[action]();
  };

  const handlePostUpdate = () => {
    invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets, QUERY_KEYS.outrights]);
    handleClose();
  };

  const showControlOptions = !isLiveEvent && !isOutrightEvent;

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
      sx={styles.menu}
    >
      <Box sx={styles.menuContent}>
        {showControlOptions && (
          <>
            <ManageMenuSection
              title='Switch control'
              options={SWITCH_CONTROL_OPTIONS}
              handleItemClick={(option) => handleUpdate('manualControl', option)}
              iconRenderer={(id) => (id === 1 ? <ManualIcon /> : <AutomaticIcon />)}
            />
            <Divider />
          </>
        )}
        <ManageMenuSection
          title='Publish status'
          options={PUBLISH_STATUS_BASE_OPTIONS}
          handleItemClick={(option) => handleUpdate('publishStatus', option)}
          iconRenderer={(id) => <PublishStatusIcon status={id as PublishStatus} />}
        />
        <Divider />
        <ManageMenuSection
          title='Market status'
          options={MARKET_STATUS_OPTIONS}
          handleItemClick={(option) => handleUpdate('isActive', option)}
          iconRenderer={(id) =>
            id === 1 ? (
              <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />
            ) : (
              <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />
            )
          }
        />
        {MARKET_ALL_MATCHES_STATUS_OPTIONS.map((option) => {
          return (
            <StyledMenuItem key={option.id} onClick={() => handleUpdate('isActiveTournament', option)}>
              {option.id === 1 && <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />}
              {option.id === 0 && <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />}
              {option.name}
            </StyledMenuItem>
          );
        })}
      </Box>
    </Menu>
  );
};
export default ManageMarketMenu;
