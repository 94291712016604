import { Stack } from '@mui/material';
import CashoutSettingsTable from '../molecules/CashoutSettingsTable';
import MarketMarginTable from '../molecules/MarketMarginTable';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  container: {
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
    mb: {
      xs: 4,
      md: 0,
    },
    width: '100%',
  },
};

const CashoutPage = () => {
  return (
    <Stack sx={styles.container}>
      <MarketMarginTable />
      <CashoutSettingsTable product='pre-match' />
      <CashoutSettingsTable product='in-play' />
    </Stack>
  );
};

export default CashoutPage;
