import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import PasswordField from './PasswordField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UseFormRegister, FieldErrors, FieldError } from 'react-hook-form';

export type PasswordFormFields = {
  password: string;
  confirmPassword: string;
};

type PasswordFieldsProps<T extends PasswordFormFields> = {
  resetPassword: boolean;
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
};

const PasswordFields = <T extends PasswordFormFields>({ resetPassword, errors, register }: PasswordFieldsProps<T>) => {
  const getPasswordFieldProps = (fieldType: keyof PasswordFormFields) => ({
    register,
    error: errors[fieldType] as FieldError,
    required: !resetPassword,
  });

  return resetPassword ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <Typography>Reset Password</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        <PasswordField name='password' label='Password' {...getPasswordFieldProps('password')} sx={{ mb: '1rem' }} />
        <PasswordField name='confirmPassword' label='Confirm password' {...getPasswordFieldProps('confirmPassword')} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <>
      <PasswordField name='password' label='Password' {...getPasswordFieldProps('password')} />
      <PasswordField name='confirmPassword' label='Confirm password' {...getPasswordFieldProps('confirmPassword')} />
    </>
  );
};

export default PasswordFields;
