import { Box, capitalize } from '@mui/material';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyleObj } from '../../../../@types';
import { PaymentTransaction } from '../../../../@types/api';
import { createDescriptionColumn } from '../../../../helpers/columns';
import { usePagination } from '../../../../hooks/usePagination';
import useSort from '../../../../hooks/useSort';
import { createColumn } from '../../../../utils';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

const columns: GridColDef[] = [
  createColumn('id', 'ID', { sortable: false }),
  createColumn('updatedBy', 'Updated by', {
    valueGetter: (params: GridValueGetterParams<PaymentTransaction>) => params.row.metadata?.operatorFullName,
  }),
  createColumn('executionTime', 'Date and Time', {
    valueGetter: (params: GridValueGetterParams<PaymentTransaction>) =>
      dayjs(params.row.executionTime).format('DD/MM/YYYY HH:mm:ss'),
  }),
  createColumn('type', 'Type', {
    valueGetter: (params: GridValueGetterParams<PaymentTransaction>) => capitalize(params.row.type.replace('_', ' ')),
  }),
  createColumn('solution', 'Solution'),
  createColumn('paymentMethod', 'Pay Method'),
  createColumn('amount', 'Amount', {
    valueFormatter: (params: GridValueFormatterParams<PaymentTransaction['amount']>) => params.value.toFixed(2),
  }),
  createColumn('status', 'Status', {
    valueGetter: (params: GridValueGetterParams<PaymentTransaction>) => capitalize(params.row.status),
  }),
  createDescriptionColumn(),
];

const PaymentTransactionPage = () => {
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { userId } = useParams();
  const { data, changeQuery } = usePagination<PaymentTransaction>(
    'transactions/payments',
    {
      page: 1,
      limit: 25,
      userId,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    },
    undefined,
    'wallet'
  );

  const { handleSort } = useSort(changeQuery);

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <TableTemplate
        columns={columns}
        rows={data?.items || []}
        rowCount={data?.count || 0}
        handleSort={handleSort}
        changeQuery={changeQuery}
        customTableHeaderContent={<DateRangeSelect date={date} onDateChange={handleDateChange} />}
      />
    </Box>
  );
};

export default PaymentTransactionPage;
