import { Add, Circle } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NavigationTabOption } from '../../../../@types';
import { CRMApiEntityType, CRMUserData, PaginatedData } from '../../../../@types/api';
import { QUERY_KEYS } from '../../../../constants';
import { useModal } from '../../../../contexts/ModalContext';
import usePermissions from '../../../../hooks/usePermissions';
import { getData } from '../../../../utils/api';
import FormModal, { FormTypes } from '../../../modals/FormModal';
import UserBalanceDisplay from '../../../molecules/UserBalanceDisplay';
import TabNavigation from '../../../organisms/TabNavigation';
import { getFullName } from '../../../../utils';

type Props = {
  userId?: string;
  userType: CRMApiEntityType;
  tabs: NavigationTabOption[];
  buttonLabel: string;
  form: FormTypes;
  myWallet?: boolean;
};

const config = {
  customer: {
    endpoint: 'customer/search',
  },
  partner: {
    endpoint: 'agent/search',
  },
};

const WalletLayoutPage = ({ userId, userType, tabs, buttonLabel, form, myWallet = false }: Props) => {
  const { openModal } = useModal();
  const { hasPermission } = usePermissions();

  // On My Wallet page, only Admin roles have permission to add credits to master wallet
  const showCreditFormButton = !myWallet || hasPermission('addAdminCredits');

  const { data } = useQuery<PaginatedData<CRMUserData>>([QUERY_KEYS.crmUsers, userId], {
    queryFn: () =>
      getData(
        config[userType].endpoint,
        {
          filters: {
            userId: {
              values: [userId],
            },
          },
        },
        'crm'
      ),
    enabled: !myWallet,
  });

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        sx={(theme) => ({
          columnGap: 2,
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            px: 1,
            rowGap: 2,
          },
        })}
      >
        {!myWallet && (
          <Typography variant='h4' fontWeight={700}>
            {getFullName(data?.items[0].firstName, data?.items[0].lastName, data?.items[0].username)}
          </Typography>
        )}
        <UserBalanceDisplay userId={userId} userType={userType} />
        {showCreditFormButton && (
          <Button
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}
            variant='outlined'
            startIcon={<Add />}
            onClick={() =>
              openModal(
                <FormModal
                  form={form}
                  item={{
                    userId,
                    userType,
                  }}
                />
              )
            }
          >
            {buttonLabel}
          </Button>
        )}
      </Stack>
      <TabNavigation tabs={tabs} icon={<Circle fontSize='inherit' />} />
    </>
  );
};

export default WalletLayoutPage;
