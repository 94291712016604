import { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { generateUrl, KEYCLOAK_CLIENT_ID } from '../utils/envDynamicValues';

export const KEYCLOAK_CONFIG: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL ?? generateUrl('auth'),
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? KEYCLOAK_CLIENT_ID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
};

export const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = {
  onLoad: 'login-required',
};

export const PERMISSIONS = {
  accessCMS: 'access_cms',
  accessConditions: 'access_conditions',
  accessCRM: 'access_crm',
  accessLiabilityMonitoring: 'access_liability_monitoring',
  accessMarketplace: 'access_marketplace',
  accessMarketCombining: 'access_market_combining',
  accessPartners: 'access_partners',
  accessPlayers: 'access_players',
  accessPublishEvents: 'access_publish_events',
  accessRiskManagement: 'access_risk_management',
  accessSportsBetting: 'access_sports_betting',
  accessTicketAuthorization: 'access_ticket_authorization',
  accessTicketMonitoring: 'access_ticket_monitoring',
  accessUserManagement: 'access_user_management',
  addAdminCredits: 'add_admin_credits',
  assignAgentToManager: 'assign_agent_to_manager',
  assignPlayerToAgent: 'assign_player_to_agent',
  assignRiskFactors: 'assign_risk_factors',
  createAgent: 'create_agent',
  createAgentNote: 'create_agent_note',
  createAgentTask: 'create_agent_task',
  createManager: 'create_manager',
  createPlayer: 'create_player',
  createPlayerNote: 'create_player_note',
  createPlayerTask: 'create_player_task',
  defaultSuperAdminRole: 'default-super-admin-role',
  defaultAdminRole: 'default-administrator-role',
  defaultAgentRole: 'default-agent-role',
  defaultManagerRole: 'default-manager-role',
  defaultTraderRole: 'default-trader-role',
  defaultTechSupportRole: 'default-tech-support-role',
  editAgentDetails: 'edit_agent_details',
  editAssignedCustomers: 'edit_assigned_customers',
  editPartnerCommission: 'edit_partner_commission',
  editPlayerDetails: 'edit_player_details',
  manageAgentPassword: 'manage_agent_password',
  manageBettingSettings: 'manage_betting_settings',
  manageBonuses: 'manage_bonuses',
  manageEvents: 'manage_events',
  manageEventsResults: 'manage_events_results',
  manageJackpots: 'manage_jackpots',
  manageOffer: 'manage_offer',
  managePlayerPassword: 'manage_player_password',
  messageAgent: 'message_agent',
  messagePlayer: 'message_player',
  placeBetForPlayer: 'place_bet_for_player',
  setGlobalLiability: 'set_global_liability',
  setPlayerStatus: 'set_player_status',
  viewAgentTransactions: 'view_agent_transactions',
  viewAssignedCustomers: 'view_assigned_customers',
  viewCasinoReports: 'view_casino_reports',
  viewCashoutReports: 'view_cashout_reports',
  viewManagerTransactions: 'view_manager_transactions',
  viewPaymentReports: 'view_payment_reports',
  viewSportsbookReports: 'view_sportsbook_reports',
  accessReportsJackpot: 'access_reports_jackpot',
  accessProductReports: 'access_product_reports',
  manageCasino: 'manage_casino',
  manageCashOut: 'manage_cash_out',
  viewPlayersReports: 'view_players_reports',
  viewPartnersReports: 'view_partners_reports',
  viewAgentsReports: 'view_agents_reports',
  viewManagersReports: 'view_managers_reports',
  viewDailyReports: 'view_daily_reports',
  viewBonusReports: 'view_bonus_reports',
  sportsbookCMS: 'access_sb_CMS',
  casinoCMS: 'access_casino_CMS',
  impersonatePlayer: 'impersonate_player',
};

export const USER_ROLES = {
  SUPER_ADMIN: 'Super Administrator',
  ADMIN: 'Administrator',
  MANAGER: 'Manager',
  AGENT: 'Agent',
  TRADER: 'Trader',
  TECH_SUPPORT: 'Technical Support',
} as const;

export const ROLE_TO_ROUTE_MAP = {
  [USER_ROLES.ADMIN]: '/sports-betting/settings/sports',
  [USER_ROLES.TECH_SUPPORT]: '/sports-betting/settings/sports',
  [USER_ROLES.MANAGER]: '/crm/partners',
  [USER_ROLES.AGENT]: '/crm/players',
  [USER_ROLES.TRADER]: '/sports-betting/offer-management/events',
  [USER_ROLES.SUPER_ADMIN]: '/marketplace',
};
