import { Save } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  Table as MuiTable,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { capitalize, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import { ProductType, RiskFactor, RiskFactorDefaults, RiskFactorEntity } from '../../@types/api';
import { QUERY_KEYS, RISK_FACTOR_ENTITY_OPTIONS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import { getData } from '../../utils/api';
import NumberInput from '../atoms/NumberInput';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import RiskDefaultSettings from '../molecules/RiskDefaultSettings';
import theme from '../../styles/theme';

const styles: StyleObj = {
  table: {
    '& .MuiTableRow-head': {
      backgroundColor: 'primary.main',
      '& th': {
        color: 'white',
      },
    },
    '& .MuiTableRow-root': {
      '& td': {
        p: 1,
        borderBottom: 'none',
      },
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'background.light',
    },
  },
  itemNameCell: {
    backgroundColor: 'background.lightGreen',
  },
  select: {
    width: '100%',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  valuesRow: {
    p: 0,
    '& td': {
      p: '0px !important',
    },
  },
  selectPlaceholder: {
    fontStyle: 'italic',
    color: 'text.disabled',
  },
};

const inputProps = {
  disableUnderline: true,
  inputProps: {
    sx: {
      textAlign: 'center',
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
  },
};

const riskFactorDefaultValue = {
  riskFactor: '',
  minTrigger: 0,
  maxTrigger: 0,
};

const RISK_FACTOR_DEFAULT_VALUES = {
  sport: riskFactorDefaultValue,
  tournament: riskFactorDefaultValue,
  market_type: {
    riskFactor: riskFactorDefaultValue.riskFactor,
  },
  punter: riskFactorDefaultValue,
  global: riskFactorDefaultValue,
};

type RiskFactorDefault = {
  riskFactor: string | null;
  minTrigger?: number | null;
  maxTrigger?: number | null;
  name?: string | null;
};

type RiskFactorDefaultsTableProps = {
  productType: ProductType;
};

const RiskFactorDefaultsTable = ({ productType }: RiskFactorDefaultsTableProps) => {
  const [values, setValues] = useState<Record<RiskFactorEntity, RiskFactorDefault>>(RISK_FACTOR_DEFAULT_VALUES);
  const invalidateData = useInvalidateQuery();

  const { data: riskFactorsData } = usePagination<RiskFactor>('risk-management/risk-factors', {
    page: 1,
    limit: 100,
  });

  useQuery([QUERY_KEYS.riskFactorDefaults], {
    queryFn: (): Promise<RiskFactorDefaults> => getData('risk-management/entity-risk-factors/defaults'),
    onSuccess: (data) => {
      const newValues = data[productType];
      setValues((prev) => {
        const newValuesMap = Object.entries(newValues).reduce(
          (acc, [key, value]) => {
            acc[key as RiskFactorEntity] = {
              riskFactor: value?.id || null,
              minTrigger: value?.minTrigger,
              maxTrigger: value?.maxTrigger,
            };
            return acc;
          },
          {} as Record<RiskFactorEntity, RiskFactorDefault>
        );

        return {
          ...prev,
          ...newValuesMap,
        };
      });
    },
    refetchOnMount: 'always',
    enabled: !isEmpty(riskFactorsData?.items),
  });

  const { updateData: updateLiabilities } = useMutateData(`risk-management/entity-risk-factors/${productType}`, [
    QUERY_KEYS.riskFactorDefaults,
  ]);

  const handleSave = (entity: RiskFactorEntity) => {
    const payload = values[entity];
    updateLiabilities(
      entity,
      {
        riskFactorId: payload.riskFactor || undefined,
        minTrigger: payload.minTrigger,
        maxTrigger: payload.maxTrigger,
      },
      () => invalidateData([`risk-management/${entity}s`])
    );
  };

  const handleValueChange = (value: string | null, entity: RiskFactorEntity, field: keyof RiskFactorDefault) => {
    setValues((prev) => ({
      ...prev,
      [entity]: {
        ...prev[entity],
        [field]: value,
      },
    }));
  };

  return (
    <Stack>
      <TableContainer>
        <MuiTable aria-label='risk factors table' sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center' width='50%'>
                <Typography variant='h5'>Defaults {capitalize(productType)}</Typography>
              </TableCell>
              <TableCell align='center' />
              <TableCell align='center' />
            </TableRow>
          </TableHead>
          <TableBody>
            {RISK_FACTOR_ENTITY_OPTIONS.map((item) => {
              return (
                <TableRow key={item.id} sx={styles.valuesRow}>
                  <TableCell align='center' sx={styles.itemNameCell}>
                    <Typography variant='h5'>{item.name}</Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Select
                      variant='standard'
                      value={values[item.id].riskFactor}
                      onChange={(e) => handleValueChange(e.target.value, item.id, 'riskFactor')}
                      disableUnderline
                      sx={styles.select}
                      displayEmpty
                      renderValue={(value) => {
                        const riskFactor = riskFactorsData?.items.find((item) => item.id === value);
                        if (!riskFactor) {
                          return (
                            <Typography variant='body2' sx={styles.selectPlaceholder}>
                              Select Risk Factor
                            </Typography>
                          );
                        }
                        return riskFactor.name;
                      }}
                    >
                      {riskFactorsData?.items.map((liability) => {
                        return (
                          <MenuItem key={liability.id} value={liability.id} sx={styles.select}>
                            {liability.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                      <NumberInput
                        value={values[item.id].minTrigger || ''}
                        onChange={(value) => handleValueChange(value, item.id, 'minTrigger')}
                        placeholder='Min Trigger'
                        variant='standard'
                        InputProps={inputProps}
                      />
                      <NumberInput
                        value={values[item.id].maxTrigger || ''}
                        onChange={(value) => handleValueChange(value, item.id, 'maxTrigger')}
                        placeholder='Max Trigger'
                        variant='standard'
                        InputProps={inputProps}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton onClick={() => handleSave(item.id)}>
                      <Tooltip title='Save'>
                        <Save color='primary' />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <RiskDefaultSettings productType={productType} />
    </Stack>
  );
};

export default RiskFactorDefaultsTable;
